import { apiSlice } from "store/api/apiSlice";

export const footerAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    callWaiterByOrderId: builder.mutation({
      query: ({ orderId, reason }) => ({
        url: `/order/callWaiter?orderId=${orderId}&callWaiter=true&callWaiterReasons=${reason}`,
        method: "PUT",
      }),
    }),
  }),
});
export const { useCallWaiterByOrderIdMutation } = footerAPISlice;
