import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

interface Props {
  isLoading: boolean; // Assuming globalLoader is a boolean prop
  iconStyles?: React.CSSProperties;
}

const CustomLoader: React.FC<Props> = ({ isLoading, iconStyles }) => {
  return (
    <div
      style={{ zIndex: 999999 }}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 42, color: "white", ...iconStyles }}
            spin
          />
        }
        spinning={isLoading}
      />
    </div>
  );
};

export default CustomLoader;
