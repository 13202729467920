import { IObject } from "types";

export const handleConfirmOrderGroup = (
  pusherCartData: IObject[] | null,
  pusherConfirmOrderGroupData: IObject
): IObject[] => {
  // Early return if data is invalid
  if (!pusherCartData?.length || !pusherConfirmOrderGroupData?.orderGroupId) {
    return [];
  }

  const updatedOrderGroupData = pusherCartData.map((order) => {
    if (order.id === pusherConfirmOrderGroupData.orderGroupId) {
      const updatedOrderItemStatus = {
        ...order,
        status: "IN_PROGRESS",
        orderItems: order.orderItems.map((item: any) =>
          item.quantity > 0 ? { ...item, status: "IN_PROGRESS" } : item
        ),
      };

      return updatedOrderItemStatus;
    } else {
      return order;
    }
  });
  return updatedOrderGroupData;
};
