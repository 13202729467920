import { usePusher } from 'context/pusher';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IObject, PusherConnectionState } from 'types';

interface Props {
    children: ReactNode;
}

const CheckoutContext = createContext<IObject | null>(null);

const CheckoutChannelProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const ids = sessionStorage.getItem("Ids");
    const parsedIds: { brandId: string, branchId: string, tableId: string } | null = ids ? JSON.parse(ids) : null;
    const customerDetails = JSON.parse(sessionStorage.getItem("CustomerDetails") || "{}");

    const pusher = usePusher();
    const [checkoutData, setCheckoutData] = useState<IObject | null>(null);

    useEffect(() => {
        if (pusher && parsedIds) {
            const channelName = `tenant-${parsedIds.brandId}-branch-${parsedIds.branchId}-table-${parsedIds.tableId}-customerPusherChannel`;
            const channel = pusher.subscribe(channelName);

            console.log('[checkout] pusher - customerCheckoutPusherEvent is the event - channelName :>> ', channelName);

            // Check if Pusher is connected
            const logConnectionState = () => {
                console.log(`[checkout] Pusher connection state: ${pusher.connection.state}`);
            };

            // Log initial connection state
            logConnectionState();

            // Bind connection state change events to log with proper typing
            pusher.connection.bind('state_change', ({ previous, current }: { previous: PusherConnectionState; current: PusherConnectionState }) => {
                console.log(`[checkout] Pusher connection state changed from ${previous} to ${current}`);
            });

            // Bind to the 'connected' event for successful connection
            pusher.connection.bind('connected', () => {
                console.log('[checkout] Pusher connected successfully');
            });

            // Bind to the 'disconnected' event for connection lost
            pusher.connection.bind('disconnected', () => {
                console.log('[checkout] Pusher disconnected');
            });

            channel.bind('customerCheckoutPusherEvent', (data: any) => {
                try {
                    const parsedCheckoutData = JSON.parse(data);
                    console.log(`${new Date()} - [checkout] parsedCheckoutData pusher data :>> `, parsedCheckoutData);
                    if (parsedCheckoutData && Object.keys(parsedCheckoutData)?.length > 0 && parsedCheckoutData?.orderStatus === "CHECKOUT") {
                        setCheckoutData(parsedCheckoutData);
                    }
                } catch (error) {
                    console.error('[checkout] Error parsing checkout data:', error, 'Raw data:', data);
                }
            });

            channel.bind('pusher:subscription_error', (status: any) => {
                console.error(`[checkout] Failed to subscribe to channel ${channelName}:`, status);
            });

            return () => {
                // console.log(`[checkout] Unsubscribing from Pusher channel: ${channelName}`);
                // channel.unbind_all();
                // pusher.unsubscribe(channelName);
            };
        }
    }, [pusher, parsedIds, customerDetails, navigate]);

    useEffect(() => {
        if (checkoutData && Object.hasOwn(checkoutData, "customerId") && String(checkoutData?.customerId) !== String(customerDetails?.id)) {
            navigate("/payment-in-progress-block");
        }
    }, [checkoutData, customerDetails, navigate]);

    useEffect(() => {
        setTimeout(() => {
            setCheckoutData(null);
        }, 2000);
    }, [location]);

    return (
        <CheckoutContext.Provider value={checkoutData}>
            {children}
        </CheckoutContext.Provider>
    );
};

const useCheckoutPusherChannel = (): IObject | null => {
    const context = useContext(CheckoutContext);
    // if (context === null) {
    //     throw new Error('useCheckoutPusherChannel must be used within a CheckoutChannelProvider');
    // }
    return context;
};

export default CheckoutChannelProvider;
export { useCheckoutPusherChannel };
