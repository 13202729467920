import React, { useEffect, useState } from 'react';
import { ReactComponent as Error } from "assets/images/field-error.svg";
import { useLocation } from 'react-router-dom';
import { useOrder } from 'context/order/OrderContext';
import { useAppSelector } from 'store/customHooks';

type ToastProps = {
    message?: string;
    type?: 'success' | 'error' | 'warning' | 'info';
    duration?: number;
    handleOnToastDismiss?: () => void;
};

const CustomToast: React.FC<ToastProps> = ({
    message = "Oops! There was an error. Please try again.",
    type = "error",
    duration = 3000,
    handleOnToastDismiss
}) => {
    const { pathname } = useLocation();
    const { orderData, orderIsUninitialized } = useOrder();

    const footerHeightStore = useAppSelector((state) => state.footer.footerHeight);

    const getFooterHeight = () => {
        if (pathname === "/order" && !orderIsUninitialized && orderData && !orderData?.isVerified) {
            return "bottom-[9rem]";
        } else if (pathname === "/re-order") {
            return footerHeightStore === 70 ? "bottom-[5rem]" : "bottom-[9rem]";
        } else {
            return "bottom-[5rem]";
        }
    };

    const [bottomCSS, setBottomCSS] = useState(getFooterHeight());
    useEffect(() => {
        // Update footer height if pathname changes
        setBottomCSS(getFooterHeight());
    }, [pathname, footerHeightStore]);

    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setVisible(true);
        const timer = setTimeout(() => {
            handleOnToastDismiss?.();
            setVisible(false);
        }, duration);
        return () => clearTimeout(timer);
    }, [duration, handleOnToastDismiss]);

    if (!visible) return null;

    const typeStyles: Record<string, {
        mainClassName: string;
        icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
            title?: string;
        }>;
        toastClassName: string;
    }> = {
        success: { mainClassName: 'success', icon: Error, toastClassName: "toastIcon successToast" },
        error: { mainClassName: 'error', icon: Error, toastClassName: "toastIcon errorToast" },
        warning: { mainClassName: 'warning', icon: Error, toastClassName: "toastIcon warningToast" },
        info: { mainClassName: 'info', icon: Error, toastClassName: "toastIcon infoToast" },
    };

    const { mainClassName, icon: ToastIcon, toastClassName } = typeStyles[type];

    return (
        <div
            onClick={() => {
                setVisible(false);
                handleOnToastDismiss?.();
            }}
            className={`fixed ${bottomCSS} left-0 right-0 z-[9999] flex justify-center customToast`}
        >
            <div
                className={`${mainClassName} px-4 py-3 rounded-lg shadow-md flex items-center space-x-2 transition-all duration-500 ease-out`}
            >
                <span><ToastIcon className={toastClassName} /></span>
                <span className="toastMessage break-words">{message}</span>
            </div>
        </div>
    );
};

export default CustomToast;