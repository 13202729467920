import { ReactComponent as DrinkIcon } from "assets/images/drink.svg";
import { ReactComponent as EatIcon } from "assets/images/eat.svg";
import { ReactComponent as ForYouIcon } from "assets/images/for-you.svg";
import { ReactComponent as SmokeIcon } from "assets/images/smoke.svg";
import { ReactComponent as PendingOrders } from "assets/images/pending-orders.svg";
import { ReactComponent as CompletedOrders } from "assets/images/completed-orders.svg";
import { TabItem } from "types";

export const homePageTabItems: TabItem[] = [
  {
    id: 100,
    label: "For You",
    activeLabelClassName: "customHeaderTabLabel home active",
    inactiveLabelClassName: "customHeaderTabLabel home inactive",
    icon: ForYouIcon,
    iconWidth: 12.44,
    iconHeight: 16,
    type: "TAB",
    disabled: false,
  },
  {
    id: 102,
    label: "Drink",
    activeLabelClassName: "customHeaderTabLabel home active",
    inactiveLabelClassName: "customHeaderTabLabel home inactive",
    icon: DrinkIcon,
    iconWidth: 14.84,
    iconHeight: 16,
    type: "TAB",
    disabled: false,
  },
  {
    id: 103,
    label: "Eat",
    activeLabelClassName: "customHeaderTabLabel home active",
    inactiveLabelClassName: "customHeaderTabLabel home inactive",
    icon: EatIcon,
    iconWidth: 16.2,
    iconHeight: 16,
    type: "TAB",
    disabled: false,
  },
  {
    id: 101,
    label: "Smoke",
    activeLabelClassName: "customHeaderTabLabel home active",
    inactiveLabelClassName: "customHeaderTabLabel home inactive",
    icon: SmokeIcon,
    iconHeight: 16,
    iconWidth: 19.37,
    type: "TAB",
    disabled: false,
  },
];

export const orderTabItems: TabItem[] = [
  {
    id: 99,
    label: "Pending",
    activeLabelClassName: "customHeaderTabLabel order active",
    inactiveLabelClassName: "customHeaderTabLabel order inactive",
    icon: PendingOrders,
    iconWidth: 16,
    iconHeight: 16,
    type: "TAB",
    disabled: false,
  },
  {
    id: 97,
    label: "Completed",
    activeLabelClassName: "customHeaderTabLabel order active",
    inactiveLabelClassName: "customHeaderTabLabel order inactive",
    icon: CompletedOrders,
    iconWidth: 16,
    iconHeight: 16,
    type: "TAB",
    disabled: true,
  },
];
