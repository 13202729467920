/**
 * Transfers all data from sessionStorage to localStorage, excluding the "refreshToken"
 * and "accessToken" keys, which are not copied.
 */

export const transferSessionToLocalExceptTokens = () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key && key !== "refreshToken" && key !== "accessToken") {
      const value = sessionStorage.getItem(key);
      if (value) {
        localStorage.setItem(key, value);
      }
    }
  }
};

/**
 * Transfers all data from localStorage to sessionStorage except for the device ID.
 * This ensures that the data is moved from localStorage to sessionStorage, but only for the
 * duration of the session.
 */

export const transferLocalToSessionAndClear = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key !== "deviceId") {
      const value = localStorage.getItem(key);
      if (value) {
        sessionStorage.setItem(key, value);
      }
    }
  }
};
