import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface FooterState {
  disableCheckoutButton: boolean;
  footerHeight: number;
  unconfirmedOrderItems: number;
  isOrderGroupNewStatusAvailable: boolean;
}

// Define the initial state using that type
const initialState: FooterState = {
  disableCheckoutButton: true,
  footerHeight: 70,
  unconfirmedOrderItems: 0,
  isOrderGroupNewStatusAvailable: false,
};

// Create the slice
const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    handleCheckoutButtonDisable: (state, action: PayloadAction<boolean>) => {
      state.disableCheckoutButton = action.payload;
    },
    handleFooterHeight: (state, action: PayloadAction<number>) => {
      state.footerHeight = action.payload;
    },
    handleNumberOfUnconfirmedOrderItems: (
      state,
      action: PayloadAction<number>
    ) => {
      state.unconfirmedOrderItems = action.payload;
    },
    handleCheckoutModalClearingOfPendingTab: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOrderGroupNewStatusAvailable = action.payload;
    },
  },
});

// Export the actions
export const {
  handleCheckoutButtonDisable,
  handleFooterHeight,
  handleNumberOfUnconfirmedOrderItems,
  handleCheckoutModalClearingOfPendingTab,
} = footerSlice.actions;

// Export the reducer
export default footerSlice.reducer;
