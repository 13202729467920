import nameIcon from "assets/images/name-icon.svg";
import FieldErrorMessage from "components/common/FieldErrorMessage";
import CustomToast from "components/CustomToast";
import usePreventBackNavigation from "hooks/usePreventBackNavigation";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { useCreateNewCustomerOrderMutation } from "store/orders/ordersAPISlice";
import {
  handleCustomerDetails,
  handleOrderDetails,
} from "store/tableStatusAndOrder/tableStatusAndOrderSlice";
interface FormValues {
  nameInput: string;
}

const EnterNamePage = () => {
  usePreventBackNavigation("/enter-name");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCustomer] = useCreateNewCustomerOrderMutation();
  const [customerName, setCustomerName] = useState("");
  const { mobile, guests } = useAppSelector(
    (state) => state.tableStatusAndOrder.customerDetails
  );

  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const ids = sessionStorage.getItem("Ids");
  const parsedIds = ids && JSON.parse(ids);
  const branchDetails = sessionStorage.getItem("Branch");
  const parsedBranchDetails = branchDetails && JSON.parse(branchDetails);

  const [failedAPIError, setFailedAPIError] = useState({
    isActive: false,
    errMessage: "",
  });

  // Reset error toast after 3 seconds
  useEffect(() => {
    if (failedAPIError.isActive) {
      const timer = setTimeout(() => {
        setFailedAPIError({ isActive: false, errMessage: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [failedAPIError.isActive]);

  const renderError = () => {
    if (failedAPIError?.isActive) {
      return <CustomToast message={failedAPIError?.errMessage} />;
    }
    return null;
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<FormValues> = {};

    // Check if customerName is empty
    if (!customerName || customerName.trim().length === 0) {
      newErrors.nameInput = "Name is required";
    }
    // Check if customerName contains only letters and spaces
    else if (!/^[A-Za-z\s]*$/.test(customerName)) {
      newErrors.nameInput = "Kindly enter a valid name";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      dispatch(handleGlobalLoader(true));
      let payload: any = {
        phoneNo: mobile,
        noOfGuest: guests || 0,
        tableId: parsedIds?.tableId,
        branchId: parsedIds?.branchId ?? parsedBranchDetails?.branchId,
        // otpPin: otpPin,
        name: customerName,
      };

      if (
        localStorage?.getItem("deviceId") !== undefined &&
        localStorage?.getItem("deviceId") !== null &&
        localStorage?.getItem("deviceId") !== "undefined" &&
        localStorage?.getItem("deviceId") !== "null"
      ) {
        payload = {
          ...payload,
          deviceId: localStorage?.getItem("deviceId"),
        };
      }

      createCustomer(payload).then((res: any) => {
        if (res?.data) {
          sessionStorage.setItem(
            "TableVerification",
            res?.data?.isVerified ? "VERIFIED" : "NOT_VERIFIED"
          );
          if (res?.data?.customerDetails) {
            dispatch(
              handleCustomerDetails({
                // ...payload,
                tableCode: payload?.tableId,
                guests: payload?.noOfGuest,
                mobile: payload?.phoneNo,
                primaryName: res?.data?.primaryCustomerName || "",
                name: res?.data?.customerDetails?.name || "",
              })
            );
            const customerDetailsPayload = {
              id: res?.data?.customerDetails?.id,
              name: res?.data?.customerDetails?.name,
              mobile: res?.data?.customerDetails?.phoneNo,
              primaryName: res?.data?.primaryCustomerName || "",
            };
            sessionStorage.setItem(
              "CustomerDetails",
              JSON.stringify(customerDetailsPayload)
            );
            localStorage.setItem(
              "deviceId",
              res?.data?.customerDetails?.deviceId
            );
          } else {
            dispatch(
              handleCustomerDetails({
                tableCode: payload?.tableId,
                guests: payload?.noOfGuest,
                mobile: payload?.phoneNo,
              })
            );
          }
          if (res?.data?.orderId) {
            dispatch(handleOrderDetails({ id: res?.data?.orderId }));
            sessionStorage.setItem("OrderId", res?.data?.orderId);
          }

          res?.data?.isTablePinNeeded
            ? navigate("/verify-pin", {
                state: { preventBackwardNavigation: true },
              })
            : res?.data?.orderId
            ? navigate("/home", { state: { preventBackwardNavigation: true } })
            : setFailedAPIError({
                isActive: true,
                errMessage:
                  "Oops, We were not able to associate the number with the table. Please scan again.",
              });
          dispatch(handleGlobalLoader(false));
        } else {
          const errMessage =
            res?.error?.data?.message ??
            res?.error?.error ??
            res?.error?.data?.error ??
            "Something went wrong. Please try again!";
          setFailedAPIError({ isActive: true, errMessage: errMessage });
          dispatch(handleGlobalLoader(false));
        }
      });
    }
  };

  const handleChange = (event: any, fieldName: string) => {
    let regex;
    let maxLength;

    if (fieldName === "name") {
      regex = /^[A-Za-z\s]*$/;
    }

    if (regex) {
      const value = event.value;
      console.log("value", value);
      if (regex.test(value) && (!maxLength || value.length <= maxLength)) {
        setCustomerName(value);
      } else if (value === "" || (maxLength && value.length > maxLength)) {
        setCustomerName("");
      }
    } else {
      setCustomerName(event.value);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    let regex;
    let maxLength;

    if (fieldName === "name") {
      regex = /^[A-Za-z\s]*$/;
    }

    const isSpecialKey = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ].includes(event.key);

    const isValidKey = regex ? regex.test(event.key) || isSpecialKey : true;
    const isLengthValid =
      !maxLength ||
      event.currentTarget.value.length < maxLength ||
      isSpecialKey;

    if (!isValidKey || !isLengthValid) {
      event.preventDefault();
    }
  };

  const renderErrorMessage = (field: keyof FormValues) => {
    return errors[field] ? (
      <FieldErrorMessage message={errors[field] as string} />
    ) : null;
  };

  return (
    <div className="h-full initialLoginPage">
      <div className="flex flex-col py-6 px-4 h-1/2 justify-around">
        <div>
          <div className="flex flex-row items-center gap-2 enterNameText">
            <img src={nameIcon} alt="nameIcon" />
            Please Enter Your Full Name
          </div>
          <div className="enterNameSubText font-[500] my-1">
            So we don’t have to call you{" "}
            <span className="italic" style={{ fontStyle: "italic" }}>
              'Hey, you!'
            </span>{" "}
          </div>
          <input
            value={customerName}
            type="text"
            name="name"
            pattern="[A-Za-z\s]*"
            title="Name can only contain letters and spaces"
            onChange={(e: any) => handleChange(e.target, "name")}
            // onKeyDown={(e:any) => handleKeyDown(event, "name")}
            className={`${
              errors.nameInput ? "errorInputBorder" : "normalInputBorder"
            } box-border px-2 inputField mt-2 w-full`}
          />
          {renderErrorMessage("nameInput")}

          <div className="enterNameSubText font-[400] my-2">
            We promise we wont ask again.
          </div>
        </div>
        <button onClick={handleSubmit} className="customerFormSubmitButton">
          Continue
        </button>
      </div>
      {renderError()}
    </div>
  );
};

export default EnterNamePage;
