import { ReactComponent as Chair } from "assets/images/chair.svg";
import CustomToast from "components/CustomToast";
import FieldErrorMessage from "components/common/FieldErrorMessage";
import usePreventBackNavigation from "hooks/usePreventBackNavigation";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { useVerifyTableMutation } from "store/orders/ordersAPISlice";
interface FormValues {
  otpPin: string;
}

const TablePinVerificationPage = () => {
  usePreventBackNavigation("/verify-pin");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [verifyTable] = useVerifyTableMutation();
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const name =
    useAppSelector(
      (state) => state.tableStatusAndOrder.customerDetails?.name
    ) || JSON.parse(sessionStorage.getItem("CustomerDetails") || "{}").name;
  const primaryName =
    useAppSelector(
      (state) => state.tableStatusAndOrder.customerDetails?.primaryName
    ) ||
    JSON.parse(sessionStorage.getItem("CustomerDetails") || "{}").primaryName;
  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [invalidOtpError, setInvalidOtpError] = useState({
    isActive: false,
    errMessage: "",
  });
  const orderId = sessionStorage.getItem("OrderId");
  const orderDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.orderDetails
  );

  const validateForm = (): boolean => {
    const newErrors: Partial<FormValues> = {};
    console.log("otpPin", otp?.join(""));
    if (!/^[0-9]/.test(otp?.join(""))) {
      newErrors.otpPin = "The OTP entered is invalid. Please try again";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // Reset error toast after 3 seconds
  useEffect(() => {
    if (invalidOtpError.isActive) {
      const timer = setTimeout(() => {
        setInvalidOtpError({ isActive: false, errMessage: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [invalidOtpError.isActive]);

  // const handleChange = (element: HTMLInputElement, index: number) => {
  //   if (isNaN(Number(element.value))) return false;

  //   setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

  //   if (element.nextSibling && element.value !== "") {
  //     (element.nextSibling as HTMLInputElement).focus();
  //   }
  // };
  const handleChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move to next input if value is entered
    if (element.value !== "" && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    // Handle backspace
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent default backspace behavior
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Move to previous input if current input is empty and we're not at the first input
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const renderError = () => {
    if (invalidOtpError?.isActive) {
      return <CustomToast message={invalidOtpError?.errMessage} />;
    }
    return null;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(handleGlobalLoader(true));
      let otpPin = otp.join("");
      // console.log("Submitting OTP:", otpPin, tableCode);
      let tableCode: any = {
        tableCode: otpPin,
      };

      verifyTable({ orderId, tableCode }).then((res: any) => {
        if (res?.data) {
          sessionStorage.setItem(
            "TableVerification",
            res?.data?.isVerified ? "VERIFIED" : "NOT_VERIFIED"
          );
          res?.data?.isVerified
            ? res?.data?.orderId
              ? navigate("/home", {
                  state: { preventBackwardNavigation: true },
                })
              : setInvalidOtpError({
                  isActive: true,
                  errMessage:
                    "Oops, We were not able to associate the number with the table. Please scan again.",
                })
            : setInvalidOtpError({
                isActive: true,
                errMessage: "Incorrect Pin, kindly enter the valid table pin",
              });
          if (res?.data?.customerDetails?.deviceId) {
            localStorage.setItem(
              "deviceId",
              res?.data?.customerDetails?.deviceId
            );
          }
          dispatch(handleGlobalLoader(false));
        } else {
          const errMessage =
            res?.error?.data?.message ??
            res?.error?.error ??
            res?.error?.data?.error ??
            "Something went wrong. Please try again!";
          setInvalidOtpError({ isActive: true, errMessage: errMessage });
          dispatch(handleGlobalLoader(false));
        }
      });
    }
  };

  const renderErrorMessage = (field: keyof FormValues) => {
    return errors[field] ? (
      <FieldErrorMessage message={errors[field] as string} />
    ) : null;
  };

  return (
    <div className="h-full initialLoginPage">
      <div className="flex flex-col items-center my-6 px-4 h-1/2 justify-around gap-4">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col text-2xl defaultTextColor font-light">
            <Chair className="w-6 h-6 iconColor" />
            <span>Hey {name}!</span>
            <span>
              <span className="font-bold">{primaryName}’s</span> already at the
              table.
            </span>
          </div>
          <div className="flex flex-col">
            <span className="pt-4 pb-2">
              Grab the <span className="font-bold">Table PIN</span> from him to
              join and start ordering
            </span>
            <div className="flex flex-row gap-2 mb-4">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  maxLength={1}
                  name="otpPin"
                  value={data}
                  placeholder="0"
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => {
                    // Only allow numeric values
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    const newEvent = {
                      target: {
                        ...e.target,
                        value: numericValue,
                      },
                    };
                    handleChange(newEvent.target, index);
                  }}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onKeyPress={(event) => {
                    // Prevent non-numeric key presses
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  className="w-full h-12 border-2 rounded text-center text-md otpInput"
                />
              ))}
            </div>
          </div>
          {renderErrorMessage("otpPin")}
        </div>
        <button
          onClick={handleSubmit}
          disabled={otp?.join("")?.length < 4}
          className="customerFormSubmitButton py-[10px]"
        >
          Join Table
        </button>
      </div>
      {renderError()}
    </div>
  );
};

export default TablePinVerificationPage;
