import { UnorderedListOutlined } from "@ant-design/icons";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import React, { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "store/customHooks";
import { handleMenuItemClicked } from "store/floatingButton/floatingButtonSlice";
import { ReactComponent as FloatingMenuClose } from "../assets/images/floating-menu-close.svg";

interface Props {
  menuListData: {
    name: string;
    helperData?: any;
  }[];
}

const FloatingMenuListButton: React.FC<Props> = ({ menuListData }) => {
  const dispatch = useAppDispatch();
  const [showPopover, setShowPopover] = useState(false);
  const [windowHeight] = useState<number>(window.innerHeight);
  const popoverRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  let touchStartY = useRef(0);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowPopover((prev) => !prev);
  };

  const handleItemClick = (menuItem: any) => {
    dispatch(handleMenuItemClicked(menuItem));
    setShowPopover(false);
  };

  const renderSubCategories = (subCategories: any[]) => {
    return subCategories.map((subCategory) => (
      <div key={subCategory.id}>
        <div
          className="floatingMenuPopoverContent py-2 pl-4"
          onClick={() => handleItemClick(subCategory)}
        >
          {convertToCapitalizedString(subCategory.categoryName)}
        </div>
      </div>
    ));
  };

  // Handle clicks outside the popover
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setShowPopover(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle touch events
  useEffect(() => {
    if (!showPopover) return;

    const handleTouchStart = (e: TouchEvent) => {
      const menu = menuRef.current;
      if (!menu) return;

      const touch = e.touches[0];
      const menuRect = menu.getBoundingClientRect();
      const isInsideMenu =
        touch.clientX >= menuRect.left &&
        touch.clientX <= menuRect.right &&
        touch.clientY >= menuRect.top &&
        touch.clientY <= menuRect.bottom;

      if (!isInsideMenu) {
        const handleClickOutside = (event: MouseEvent) => {
          if (
            popoverRef.current &&
            !popoverRef.current.contains(event.target as Node)
          ) {
            setShowPopover(false);
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }

      touchStartY.current = touch.clientY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      const menu = menuRef.current;
      if (!menu) return;

      const touch = e.touches[0];
      const menuRect = menu.getBoundingClientRect();
      const isInsideMenu =
        touch.clientX >= menuRect.left &&
        touch.clientX <= menuRect.right &&
        touch.clientY >= menuRect.top &&
        touch.clientY <= menuRect.bottom;

      if (!isInsideMenu) {
        e.preventDefault();
        return;
      }

      const touchY = touch.clientY;
      const scrollTop = menu.scrollTop;
      const scrollHeight = menu.scrollHeight;
      const clientHeight = menu.clientHeight;

      // Check if we're at the top or bottom of the menu
      const isAtTop = scrollTop <= 0;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;

      // Determine scroll direction
      const isScrollingUp = touchY > touchStartY.current;
      const isScrollingDown = touchY < touchStartY.current;

      // Prevent body scroll when at boundaries
      if ((isAtTop && isScrollingUp) || (isAtBottom && isScrollingDown)) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [showPopover]);

  return (
    <div className="relative">
      {/* Overlay */}
      {showPopover && (
        <div
          className="fixed inset-0 touch-none pointer-events-auto z-[998]"
          onClick={() => setShowPopover(false)}
          style={{
            WebkitTapHighlightColor: "transparent",
            touchAction: "none",
            userSelect: "none",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          aria-hidden="true"
        />
      )}

      <div
        className={`fixed right-[3vw] ${
          windowHeight <= 750
            ? windowHeight <= 730
              ? "bottom-[10vh]"
              : "bottom-[9.5vh]"
            : "bottom-[8vh]"
        } z-[999]`}
      >
        <div
          className="flex flex-col justify-end items-end mb-[20px]"
          ref={popoverRef}
        >
          {showPopover && (
            <div
              ref={menuRef}
              className="z-[999] flex flex-col floatingMenuPopover overflow-y-auto py-[10px] px-[15px] rounded my-2 relative pointer-events-auto max-h-[60vh]"
              style={{
                WebkitOverflowScrolling: "touch",
                overscrollBehavior: "contain",
                touchAction: "pan-y",
                maxHeight: windowHeight <= 700 ? "500px" : "580px",
              }}
            >
              {menuListData?.map((menuItem, index: number) => (
                <div key={`${index}+${menuItem.name}`} className="py-2">
                  <div
                    className="floatingMenuPopoverContent py-2 first:pt-0 last:pb-0 font-normal floatingMenuPopoverCategories"
                    onClick={() => {
                      handleItemClick(menuItem);
                    }}
                  >
                    {convertToCapitalizedString(menuItem.name)}
                  </div>
                  {menuItem?.helperData?.subCategories &&
                    renderSubCategories(menuItem.helperData.subCategories)}
                </div>
              ))}
            </div>
          )}
          <button
            className="floatingMenuButton text-center font-bold rounded-md flex items-center justify-center shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] z-[9999] relative pointer-events-auto"
            onClick={handleButtonClick}
          >
            {showPopover ? (
              <FloatingMenuClose className="floatingMenuClose rounded-md h-full w-full" />
            ) : (
              <UnorderedListOutlined className="floatingMenuIcon" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FloatingMenuListButton;
