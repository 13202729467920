import { ConfigProvider } from "antd";
import "App.css";
import CustomLoader from "components/common/CustomLoader";
import React, { useEffect, useState } from "react";
import RouteCenter from "routes";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import "styles/index.scss";
import tenantConfig from "config/tenantConfig.json";
import { handleHeaderLogo } from "store/header/headerSlice";

interface TenantConfigType {
  cssFile: string;
  defaultHeaderLogo: string;
  headerLogo: string;
  fontFamily: string;
}

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const globalLoader = useAppSelector((state) => state.auth.globalLoader);
  const tenant = JSON.parse(
    sessionStorage.getItem("CustomerApp-Tenant") || "{}"
  );

  const [tenantStyles, setTenantStyles] = useState({
    cssFile: tenantConfig.default.cssFile,
    defaultHeaderLogo: tenantConfig.default.defaultHeaderLogo,
    headerLogo: tenantConfig.default.headerLogo,
    fontFamily: tenantConfig.default.fontFamily,
  });

  useEffect(() => {
    const addStylesheet = (href: string) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      // Add a unique query parameter for the default stylesheet
      link.href = href.includes("default.css")
        ? `${href}?t=${new Date().getTime()}`
        : href;
      document.head.appendChild(link);
      return link;
    };

    const normalizedTenantName = tenant?.tenantName?.toLowerCase();
    const matchedConfig = Object.keys(tenantConfig).find((key) =>
      normalizedTenantName?.includes(key)
    );

    const selectedConfig =
      matchedConfig && matchedConfig in tenantConfig
        ? tenantConfig[matchedConfig as keyof typeof tenantConfig]
        : tenantConfig.default;

    // Dynamically add CSS to <head>
    const link = addStylesheet(selectedConfig.cssFile);

    // Update state and dispatch logo details
    setTenantStyles(selectedConfig as TenantConfigType);
    dispatch(
      handleHeaderLogo({
        defaultHeaderLogo: selectedConfig.defaultHeaderLogo,
        headerLogo: selectedConfig.headerLogo,
        altName: normalizedTenantName || "default",
      })
    );

    return () => {
      // Clean up old link tag
      if (link) document.head.removeChild(link);
    };
  }, [tenant]);

  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: tenantStyles.fontFamily,
          },
        }}
      >
        {globalLoader && <CustomLoader isLoading={globalLoader} />}
        <div className="typography">
          <RouteCenter />
        </div>
      </ConfigProvider>
    </React.Fragment>
  );
};

export default App;
