import { IObject } from "types";

interface ICartPusherData {
  orderItemId: number;
  orderGroupId: number;
  menuItemMaster: IObject;
  modifiers: IObject[];
  quantity: number;
  instructions: string[];
  status: string;
  actionType: string;
  price: number;
  productMasterId: number;
  topMostCategory: string;
  orderTimeStamp: Date;
}

interface GroupedOrder {
  id: number;
  orderItems: any[];
  status: string;
}

export const transformOrderItems = (
  orderItems: ICartPusherData[]
): GroupedOrder[] => {
  const groupedOrders: Record<number, GroupedOrder> = {};

  orderItems.forEach((item) => {
    const {
      orderGroupId,
      orderItemId,
      menuItemMaster,
      modifiers,
      quantity,
      instructions,
      status,
      actionType,
      orderTimeStamp,
      price,
      topMostCategory,
      productMasterId,
    } = item;

    if (!groupedOrders[orderGroupId]) {
      groupedOrders[orderGroupId] = {
        id: orderGroupId,
        orderItems: [],
        status: "NEW",
      };
    }

    const modWithQuantity =
      modifiers
        ?.filter((modifier) => {
          if (modifier?.qty && modifier?.qty > 0) {
            return modifier;
          }
        }) // Only include modifiers with qty > 1
        ?.map((modifier) => ({
          modifierName: modifier?.modifier?.itemName,
          orderItemCustomerModifierMappingId: modifier?.id,
          modifierQuantity: modifier?.qty,
        })) || [];

    groupedOrders[orderGroupId].orderItems.push({
      id: orderItemId,
      menuItemMaster: {
        ...menuItemMaster,
        productMaster: {
          id: productMasterId,
        },
      },
      modifiers,
      orderItemModifierQuantityMappings: modWithQuantity,
      quantity,
      instructions,
      status,
      actionType,
      orderTimeStamp,
      price,
      topMostCategory,
    });
  });

  return Object.values(groupedOrders);
};

export const handleUnconfirmedOrder = (
  orderData: IObject,
  pusherCartData: IObject[] | null
) => {
  console.log(
    "[orderGroupData] orderData.orderGroups :>> ",
    orderData,
    orderData?.orderGroups
  );

  let orderGroupData = [...(orderData?.orderGroups ?? [])];

  console.log(
    "[unconfirmed order] inside func",
    pusherCartData,
    "orderGroupData",
    orderGroupData
  );

  if (pusherCartData === null) {
    return orderData?.orderGroups ?? [];
  }

  pusherCartData.forEach((pusherItem) => {
    const existingGroupIndex = orderGroupData.findIndex(
      (group: any) => group.id === pusherItem.id
    );

    if (existingGroupIndex !== -1) {
      // Order group exists
      const existingGroup = orderGroupData[existingGroupIndex];
      const updatedOrderItems = [...existingGroup.orderItems];

      // First, update existing items and mark them for tracking
      pusherItem.orderItems.forEach((pusherOrderItem: any) => {
        const existingItemIndex = updatedOrderItems.findIndex(
          (item: any) => item.id === pusherOrderItem.id
        );

        if (existingItemIndex !== -1) {
          // Update existing item
          if (pusherOrderItem.quantity > 0) {
            updatedOrderItems[existingItemIndex] = pusherOrderItem;
          } else {
            // Remove item if quantity is 0
            updatedOrderItems.splice(existingItemIndex, 1);
          }
        } else {
          // Add new item if it has a positive quantity
          if (pusherOrderItem.quantity > 0) {
            updatedOrderItems.push(pusherOrderItem);
          }
        }
      });

      if (updatedOrderItems.length > 0) {
        orderGroupData[existingGroupIndex] = {
          ...existingGroup,
          orderItems: updatedOrderItems,
        };
      } else {
        // Remove group if no items remain
        orderGroupData = orderGroupData.filter(
          (_, idx) => idx !== existingGroupIndex
        );
      }
      return;
    } else {
      console.log("ORDER NOT MATCHED");

      // New order group
      const validOrderItems = pusherItem.orderItems.filter(
        (orderItem: any) => orderItem.quantity > 0
      );

      if (validOrderItems.length > 0) {
        orderGroupData.push({
          ...pusherItem,
          orderItems: validOrderItems,
        });
      }
      return;
    }
  });

  console.log("[orderGroupData] after processing :>> ", orderGroupData);

  // Final cleanup to ensure no empty groups
  const filteredFinalData = orderGroupData.filter(
    (orderGroup) => orderGroup.orderItems.length > 0
  );

  console.log(
    "[unconfirmed order] final [orderGroupData] :>> ",
    filteredFinalData
  );

  return filteredFinalData;
};
