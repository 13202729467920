import LastCall from "components/LastCall";
import TableVerification from "components/TableVerification";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/customHooks";
import { IObject } from "types";

interface Props {
  promotionData: IObject[];
}

const ForYouTabContent: React.FC<Props> = ({ promotionData }) => {
  const navigate = useNavigate();

  const tableInfo = useAppSelector(
    (state) => state.tableStatusAndOrder.tableInfo
  );
  const customerDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.customerDetails
  );

  // If the Image is attached to external link or menu items
  const handleImageClick = (imgInfo: any, promoItemId: number) => {
    if (imgInfo?.link?.trim()?.length) {
      // Open the link in a new tab
      window.open(imgInfo.link, "_blank", "noopener noreferrer");
    } else if (imgInfo?.productMapped) {
      // Navigate to the promotion details page
      sessionStorage.setItem("SelectedPromoId", JSON.stringify(promoItemId));
      navigate("/promotion-details", {
        state: {
          promoItemId: promoItemId,
          url: imgInfo?.mainBanner?.url,
          title: imgInfo?.name,
          description: imgInfo?.description,
        },
      });
    }
  };

  return (
    <div className="mb-[20px] forYouTab">
      <div className="mt-[20px] pinContainer p-2 flex items-center justify-between">
        <div>
          Share this PIN with family/friends who wish to join your table and
          order
        </div>
        <button className="pinInfo ml-[5px] flex items-center whitespace-nowrap p-1">
          <span>PIN </span>
          <span className="ml-[5px]">
            {tableInfo?.tableCode ?? customerDetails?.tableCode}
          </span>
        </button>
      </div>
      <TableVerification />
      <LastCall />
      {promotionData && promotionData.length
        ? promotionData.map((promoItem: any) => {
          if (
            promoItem &&
            promoItem?.promotions &&
            promoItem?.promotions?.length
          ) {
            return (
              <div key={promoItem.id} className="mt-[20px] promoContainer">
                <div className="mainHeader">
                  {promoItem?.mainHeader}
                </div>
                <div className="subHeader mt-[5px]">
                  {promoItem?.subHeader}
                </div>

                {/* Scrollable image container */}
                <div className="flex overflow-x-auto space-x-4 hide-scrollbar -mx-[15px]">
                  {promoItem?.promotions.map(
                    (imgInfo: any, index: number) => (
                      <div
                        onClick={() => handleImageClick(imgInfo, imgInfo?.id)}
                        key={imgInfo.mainBanner.id}
                        className={`
                        ${promoItem?.containerSize === "TWO_BY_TWO"
                            ? "imageItem twoByTwo"
                            : "imageItem twoByOne"
                          }
                      flex-shrink-0 relative
        ${index === 0 ? "pl-4" : ""}
        ${index === promoItem?.promotions.length - 1 ? "mr-[20px]" : ""}
                      `}
                      >
                        <img
                          src={imgInfo.mainBanner.url}
                          alt={`${promoItem?.mainHeader} - ${imgInfo?.name}`}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          }
        })
        : null}
    </div>
  );
};

export default ForYouTabContent;
