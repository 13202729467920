export const handleModifierDescription = (orderItem: any) => {
  const {
    orderItemModifierQuantityMappings = [],
    modifiers = [],
    id,
  } = orderItem || {};

  if (!orderItemModifierQuantityMappings.length && !modifiers.length) {
    console.error("Insufficient data for order ID:", id);
    return "";
  }

  const mergedDescription = modifiers
    .map((modifier: any) => {
      const mapping = orderItemModifierQuantityMappings?.find(
        (m: any) => m?.orderItemCustomerModifierMappingId === modifier?.id
      );

      const name = modifier?.modifier?.itemName;
      if (!name) {
        console.error(`Modifier with ID ${modifier?.id} has no name`);
        return null;
      }

      if (mapping?.modifierQuantity) {
        return mapping?.modifierQuantity > 1
          ? `${mapping?.modifierQuantity} ${name}`
          : name;
      }

      return name;
    })
    .filter(Boolean) // Remove null or undefined entries
    .join(", ");

  return mergedDescription ? `Served with ${mergedDescription}` : "";
};
