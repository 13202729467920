/**
 * Loops through an array of image objects and returns the first valid image URL found.
 * An image URL is considered valid if the `url` property exists on the image object
 */

export const handleValidImageUrl = (imageObj: any) => {
  let validImageUrl = null;
  for (let i = 0; i < imageObj?.length; i++) {
    const imgURL = imageObj?.[i]?.url;
    if (imgURL) {
      validImageUrl = imgURL;
      break;
    }
  }

  return validImageUrl;
};
