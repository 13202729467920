import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TabLevelFilter {
  activeEatFilter: "VEGETARIAN" | "NON_VEGETARIAN" | "EGGETARIAN" | null;
  activeDrinkFilter: "ALCOHOLIC" | "NON_ALCOHOLIC" | null;
}

interface GlobalSearchAndFiltersState {
  searchQuery: string;
  vegetarianFilter: boolean;
  nonVegetarianFilter: boolean;
  eggetarianFilter: boolean;
  alcoholicFilter: boolean;
  nonAlcoholicFilter: boolean;
  clearLocalSearchQuery: boolean;
  tabLevelActiveFilter: TabLevelFilter;
  eatTabAccordionState: boolean[];
  drinkTabAccordionState: boolean[];
  smokeTabAccordionState: boolean[];
  tabAccordionCollapseState: {
    isEatTabCollapsed: boolean;
    isDrinkTabCollapsed: boolean;
    isSmokeTabCollapsed: boolean;
  };
}

const initialState: GlobalSearchAndFiltersState = {
  searchQuery: "",
  vegetarianFilter: false,
  nonVegetarianFilter: false,
  eggetarianFilter: false,
  alcoholicFilter: false,
  nonAlcoholicFilter: false,
  clearLocalSearchQuery: false,
  tabLevelActiveFilter: {
    activeEatFilter: null,
    activeDrinkFilter: null,
  },
  drinkTabAccordionState: [],
  eatTabAccordionState: [],
  smokeTabAccordionState: [],
  tabAccordionCollapseState: {
    isDrinkTabCollapsed: false,
    isEatTabCollapsed: false,
    isSmokeTabCollapsed: false,
  },
};

const globalSearchAndFiltersSlice = createSlice({
  name: "globalSearchAndFilters",
  initialState,
  reducers: {
    handleSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    handleFoodTypeFilters: {
      reducer(
        state,
        action: PayloadAction<{
          foodType:
            | "VEGETARIAN"
            | "NON_VEGETARIAN"
            | "EGGETARIAN"
            | "ALCOHOLIC"
            | "NON_ALCOHOLIC";
          toggleState: boolean;
        }>
      ) {
        const { foodType, toggleState } = action.payload;
        switch (foodType) {
          case "VEGETARIAN":
            state.vegetarianFilter = toggleState;
            state.nonVegetarianFilter = false;
            state.eggetarianFilter = false;
            break;
          case "NON_VEGETARIAN":
            state.nonVegetarianFilter = toggleState;
            state.vegetarianFilter = false;
            state.eggetarianFilter = false;
            break;
          case "EGGETARIAN":
            state.vegetarianFilter = false;
            state.nonVegetarianFilter = false;
            state.eggetarianFilter = toggleState;
            break;
          case "ALCOHOLIC":
            state.vegetarianFilter = false;
            state.nonVegetarianFilter = false;
            state.eggetarianFilter = false;
            state.nonAlcoholicFilter = false;
            state.alcoholicFilter = toggleState;
            break;
          case "NON_ALCOHOLIC":
            state.vegetarianFilter = false;
            state.nonVegetarianFilter = false;
            state.eggetarianFilter = false;
            state.alcoholicFilter = false;
            state.nonAlcoholicFilter = toggleState;
            break;
          default:
            state.vegetarianFilter = false;
            state.nonVegetarianFilter = false;
            state.eggetarianFilter = false;
            state.alcoholicFilter = false;
            state.nonAlcoholicFilter = false;

            break;
        }
      },
      prepare(
        foodType:
          | "VEGETARIAN"
          | "NON_VEGETARIAN"
          | "EGGETARIAN"
          | "ALCOHOLIC"
          | "NON_ALCOHOLIC",
        toggleState: boolean
      ) {
        return {
          payload: {
            foodType,
            toggleState,
          },
        };
      },
    },
    handleFoodTypeFiltersReset: (state) => {
      state.vegetarianFilter = false;
      state.nonVegetarianFilter = false;
      state.eggetarianFilter = false;
      state.alcoholicFilter = false;
      state.nonAlcoholicFilter = false;
    },
    handleSearchQueryReset: (state) => {
      state.searchQuery = "";
    },
    handleClearLocalSearchQuery: (state, action: PayloadAction<boolean>) => {
      state.clearLocalSearchQuery = action.payload;
    },
    handleTabLevelActiveFilter: (
      state,
      action: PayloadAction<TabLevelFilter>
    ) => {
      state.tabLevelActiveFilter = action.payload;
    },
    handleTabTypeInitialAccordionState: (
      state,
      action: PayloadAction<{
        tabType: "EAT" | "DRINK" | "SMOKE";
        accordionStateArr: boolean[];
      }>
    ) => {
      const { tabType, accordionStateArr } = action.payload;
      tabType === "DRINK"
        ? (state.drinkTabAccordionState = accordionStateArr)
        : tabType === "EAT"
        ? (state.eatTabAccordionState = accordionStateArr)
        : (state.smokeTabAccordionState = accordionStateArr);
    },
    handleTabToggleAccordionStates: (
      state,
      action: PayloadAction<{
        value: boolean;
        tabType: "EAT" | "DRINK" | "SMOKE";
        index: number;
      }>
    ) => {
      const { tabType, index, value } = action.payload;

      // Toggle the accordion state only for the targeted tab
      if (tabType === "DRINK") {
        state.drinkTabAccordionState[index] = value;
      } else if (tabType === "EAT") {
        state.eatTabAccordionState[index] = value;
      } else if (tabType === "SMOKE") {
        state.smokeTabAccordionState[index] = value;
      }

      // Update the collapse state for the current tab based on open accordions
      const isAnyOpen =
        tabType === "DRINK"
          ? state.drinkTabAccordionState.some((value) => value)
          : tabType === "EAT"
          ? state.eatTabAccordionState.some((value) => value)
          : state.smokeTabAccordionState.some((value) => value);

      state.tabAccordionCollapseState = {
        ...state.tabAccordionCollapseState,
        ...(tabType === "DRINK"
          ? { isDrinkTabCollapsed: !isAnyOpen }
          : tabType === "EAT"
          ? { isEatTabCollapsed: !isAnyOpen }
          : { isSmokeTabCollapsed: !isAnyOpen }),
      };
    },

    // Collapse All Accordion for a specific tab
    handleTabCollapseState: (
      state,
      action: PayloadAction<{
        tabType: "EAT" | "DRINK" | "SMOKE";
        collapseState: boolean;
      }>
    ) => {
      const { tabType, collapseState } = action.payload;

      if (tabType === "DRINK") {
        state.tabAccordionCollapseState.isDrinkTabCollapsed = collapseState;
        state.drinkTabAccordionState = state.drinkTabAccordionState.map(
          () => false
        );
      } else if (tabType === "EAT") {
        state.tabAccordionCollapseState.isEatTabCollapsed = collapseState;
        state.eatTabAccordionState = state.eatTabAccordionState.map(
          () => false
        );
      } else if (tabType === "SMOKE") {
        state.tabAccordionCollapseState.isSmokeTabCollapsed = collapseState;
        state.smokeTabAccordionState = state.smokeTabAccordionState.map(
          () => false
        );
      }
    },

    // Reset Accordion states for a specific tab
    handleResetTabAccordionState: (
      state,
      action: PayloadAction<"EAT" | "DRINK" | "SMOKE">
    ) => {
      const tabType = action.payload;

      if (tabType === "DRINK") {
        state.tabAccordionCollapseState = {
          ...state.tabAccordionCollapseState,
          isDrinkTabCollapsed: false,
        };
        state.drinkTabAccordionState = [];
      } else if (tabType === "EAT") {
        state.tabAccordionCollapseState = {
          ...state.tabAccordionCollapseState,
          isEatTabCollapsed: false,
        };
        state.eatTabAccordionState = [];
      } else if (tabType === "SMOKE") {
        state.tabAccordionCollapseState = {
          ...state.tabAccordionCollapseState,
          isSmokeTabCollapsed: false,
        };
        state.smokeTabAccordionState = [];
      }
    },
  },
});

export const {
  handleSearchQuery,
  handleFoodTypeFilters,
  handleFoodTypeFiltersReset,
  handleSearchQueryReset,
  handleClearLocalSearchQuery,
  handleTabLevelActiveFilter,
  handleTabCollapseState,
  handleTabToggleAccordionStates,
  handleTabTypeInitialAccordionState,
  handleResetTabAccordionState,
} = globalSearchAndFiltersSlice.actions;

export default globalSearchAndFiltersSlice.reducer;
