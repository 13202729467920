import { CheckOutlined, ExclamationOutlined, LoadingOutlined } from '@ant-design/icons';
import { rupeeFormatter } from 'central-utils/currencyUtils';
import { transferLocalToSessionAndClear } from 'central-utils/storageUtils';
import { useOrder } from 'context/order/OrderContext';
import { useCustomerLogoutPusherChannel } from 'context/pusher/customer-logout';
import usePreventBackNavigation from 'hooks/usePreventBackNavigation';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetPaymentStatusByOrderIdQuery, useSaveBillingResponseMutation } from 'store/checkout/checkoutAPISlice';
import { useAppDispatch, useAppSelector } from 'store/customHooks';
import iconInfo from "styles/variableJSON/fontAndColor.json";

// NOTE: HDFC GATEWAY

const PaymentConfirmationPage: React.FC = () => {
    usePreventBackNavigation('/payment-confirmation');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { orderData, orderIsUninitialized, refetchOrderData } = useOrder();
    const pusherCustomerLogoutData = useCustomerLogoutPusherChannel();

    const initialize = useAppSelector((state) => state.auth.initialize);

    const [isTransferComplete, setIsTransferComplete] = useState<boolean>(false);
    const [status, setStatus] = useState<string | null>(null);
    const [countdown, setCountdown] = useState<number>(5);
    const [refetchPaymentStatusAPICall, setRefetchPaymentStatusAPICall] = useState<boolean>(false);
    const [errorRetryCount, setErrorRetryCount] = useState<number>(0);
    const [isMaxRetriesReached, setIsMaxRetriesReached] = useState<boolean>(false);
    const [showChargedStatusCountdown, setShowChargedStatusCountdown] = useState<boolean>(false);

    useEffect(() => {
        const performTransfer = async () => {
            try {
                await transferLocalToSessionAndClear();
                setIsTransferComplete(true);
            } catch (error) {
                console.error('Error during transfer:', error);
            }
        };

        performTransfer();
    }, []);

    const orderId = isTransferComplete ? sessionStorage.getItem("OrderId") : null;
    const ids = isTransferComplete ? JSON.parse(sessionStorage.getItem("Ids") ?? "{}") : null;

    useEffect(() => {
        if (
            pusherCustomerLogoutData &&
            Object.keys(pusherCustomerLogoutData ?? {})?.length > 0 &&
            pusherCustomerLogoutData?.currentOrderId === orderId
        ) {
            setShowChargedStatusCountdown(true);
        }
    }, [orderId, pusherCustomerLogoutData])


    const {
        data: paymentStatusData,
        isLoading: paymentStatusIsLoading,
        isError: paymentStatusIsError,
        isSuccess: paymentStatusIsSuccess,
        isFetching: paymentStatusIsFetching,
        refetch: refetchPaymentStatusData
    } = useGetPaymentStatusByOrderIdQuery(
        { orderId: orderId },
        { skip: !initialize || !orderId }
    );
    const [saveBill] = useSaveBillingResponseMutation();

    useEffect(() => {
        if (paymentStatusData && paymentStatusIsSuccess) {
            sessionStorage.setItem("PaymentStatusAPIData", JSON.stringify(paymentStatusData));
            setStatus(paymentStatusData.status);
            setRefetchPaymentStatusAPICall(paymentStatusData?.shouldPoll);
        }
        if (paymentStatusData && paymentStatusIsSuccess && !paymentStatusData?.shouldPoll) {
            setStatus("AUTHORIZATION_FAILED");
        }
    }, [paymentStatusData, paymentStatusIsSuccess]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if ((status === "PENDING_VBV" || status === "AUTHORIZING") && !paymentStatusIsError) {
            intervalId = setInterval(() => {
                refetchPaymentStatusData();
            }, 5000);

            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [status, refetchPaymentStatusData, paymentStatusIsError]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (paymentStatusIsError && refetchPaymentStatusAPICall) {
            intervalId = setInterval(() => {
                if (errorRetryCount < 4) {
                    refetchPaymentStatusData();
                } else {
                    setIsMaxRetriesReached(true);
                }
            }, 5000);

            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [refetchPaymentStatusData, refetchPaymentStatusAPICall, errorRetryCount, paymentStatusIsError]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if (status === "AUTHORIZATION_FAILED" || status === "AUTHENTICATION_FAILED") {
            timeoutId = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        navigate('/check-out');
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => {
                if (timeoutId) {
                    clearInterval(timeoutId);
                }
            };
        }

        if (status === "CHARGED") {
            setShowChargedStatusCountdown(true);
            timeoutId = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => {
                if (timeoutId) {
                    clearInterval(timeoutId);
                }
            };
        }
    }, [status, navigate]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!orderIsUninitialized && orderData?.status !== "COMPLETED") {
                refetchOrderData();
            }
        }, 180000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const processBillingAndLogout = async () => {
            if (status === "CHARGED") {
                const storeBillInfo = JSON.parse(sessionStorage.getItem("billInfo") ?? "{}");
                const billDetailsBody = (storeBillInfo && Object.keys(storeBillInfo)?.length) ?
                    {
                        ...storeBillInfo,
                        orderId: storeBillInfo?.billNo || orderId,
                        payableAmount: paymentStatusData?.paymentAmount
                    } :
                    {
                        orderId: orderId,
                        // tables: [{
                        //     id: ids?.tableId
                        // }],
                        payableAmount: null
                    };
                try {
                    await saveBill({ billDetailsBody }).unwrap();
                } catch (err) {
                    console.error('Error saving bill:', err);
                }
            }
        };

        orderId && ids?.tableId && processBillingAndLogout();
    }, [dispatch, ids?.tableId, orderId, paymentStatusData, saveBill, status]);

    // Handle when retries exceed the limit
    useEffect(() => {
        if (paymentStatusIsError) {
            if (errorRetryCount < 4) {
                setErrorRetryCount(prev => prev + 1);
            }
        }
    }, [paymentStatusIsError]);

    const handleStatusContents = () => {
        if (paymentStatusData?.status === "CHARGED") {
            return (
                <div className='flex flex-col justify-center items-center h-full paymentConfirmationContentWrapper paymentSuccess'>
                    <div className='text-center'>{`Payment Successful for Order ID ${paymentStatusData?.orderId}!`}</div>
                    <div className='paymentSuccessIconWrapper p-5 my-5'>
                        <CheckOutlined className='paymentSuccessIcon' />
                    </div>
                    <div className='text-center'>
                        {(paymentStatusData?.paymentAmount && paymentStatusData?.paymentAmount !== 0) ?
                            rupeeFormatter(paymentStatusData?.paymentAmount) : ""
                        }
                    </div>
                    {
                        showChargedStatusCountdown ?
                            (<div className='paymentSuccessCountdown text-center mt-2'>
                                <span>You will be redirected in </span>
                                <span>{countdown} {countdown <= 1 ? " second" : " seconds"}</span>.
                            </div>) : ""
                    }
                </div>
            );
        } else if (isMaxRetriesReached) {
            return (
                <div className='flex flex-col justify-center items-center h-full paymentConfirmationContentWrapper paymentFailed'>
                    <div className='text-center'>{`We were not able to confirm the payment status for order id - ${paymentStatusData?.orderId}, please check with the waiter`}</div>
                    <div className='paymentFailedIconWrapper p-5 my-5'>
                        <ExclamationOutlined className='paymentFailedIcon' />
                    </div>
                </div>
            );
        } else if (paymentStatusData?.status === "PENDING_VBV" || paymentStatusData?.status === "AUTHORIZING" || paymentStatusIsLoading || paymentStatusIsFetching || (paymentStatusIsError && errorRetryCount < 4)) {
            return (
                <div className='flex flex-col justify-center items-center h-full paymentConfirmationContentWrapper paymentProcessing'>
                    <div className='text-center'>Please don't close the window, we are confirming your payment!</div>
                    <LoadingOutlined className='my-5 paymentProcessingIcon' />
                </div>
            );
        } else if (paymentStatusData?.status === "AUTHORIZATION_FAILED" || paymentStatusData?.status === "AUTHENTICATION_FAILED") {
            return (
                <div className='flex flex-col justify-center items-center h-full paymentConfirmationContentWrapper paymentFailed'>
                    <div className='text-center'>{`Payment Failed for Order ID ${paymentStatusData?.orderId}!`}</div>
                    <div className='paymentFailedIconWrapper p-5 my-5'>
                        <ExclamationOutlined className='paymentFailedIcon' />
                    </div>
                    <div className='paymentFailedCountdown'>
                        <span>You will be redirected to our checkout page in </span>
                        <span>{countdown} {countdown <= 1 ? " second" : " seconds"}</span>.
                    </div>
                </div>
            );
        }
    };

    return (
        <div className='h-[calc(100vh-64px)] paymentConfirmationPage'>
            {handleStatusContents()}
        </div>
    );
};

export default PaymentConfirmationPage;