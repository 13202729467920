import Modal from "antd/es/modal/Modal";
import callWaiterClose from "assets/images/call-waiter-close.svg";
import cleanUp from "assets/images/clean-up.svg";
import cutlery from "assets/images/cutlery.svg";
import handGesture from "assets/images/hand-gesture.svg";
import others from "assets/images/others.svg";
import water from "assets/images/water.svg";
import CustomToast from "components/CustomToast";
import { useOrder } from "context/order/OrderContext";
import { useWaiterPusherChannel } from "context/pusher/waiter";
import { useCallback, useEffect, useState } from "react";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch } from "store/customHooks";
import { useCallWaiterByOrderIdMutation } from "store/footer/footerAPISlice";

type WaiterReason = "WATER" | "CUTLERY" | "CLEAN_UP" | "OTHERS";

interface WaiterItem {
  title: string;
  enum: WaiterReason;
  src: string;
}

interface CallWaiterModalProps {
  handleCloseModal: () => void;
}

const CallWaiterModal = ({ handleCloseModal }: CallWaiterModalProps) => {
  const callWaiterModalItems: WaiterItem[] = [
    {
      title: "Water",
      enum: "WATER",
      src: water,
    },
    {
      title: "Cutlery",
      enum: "CUTLERY",
      src: cutlery,
    },
    {
      title: "Clean Up",
      enum: "CLEAN_UP",
      src: cleanUp,
    },
    {
      title: "Others",
      enum: "OTHERS",
      src: others,
    },
  ];
  const dispatch = useAppDispatch();
  const [callWaiterReasonList, setCallWaiterReasonList] = useState<
    WaiterReason[]
  >([]);
  const [error, setError] = useState({
    isActive: false,
    errMessage: "",
  });
  const waiterPusherData = useWaiterPusherChannel();
  const { orderData } = useOrder();
  const orderId = sessionStorage.getItem("OrderId");
  const [callWaiter] = useCallWaiterByOrderIdMutation();

  // Reset error toast after 3 seconds
  useEffect(() => {
    if (error.isActive) {
      const timer = setTimeout(() => {
        setError({ isActive: false, errMessage: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error.isActive]);

  const renderError = () => {
    if (error?.isActive) {
      return <CustomToast message={error?.errMessage} />;
    }
    return null;
  };

  // Handle Call Waiter API
  const handleClick = (waiterReason: WaiterReason) => {
    const reason = [...callWaiterReasonList, waiterReason];

    if (orderId) {
      dispatch(handleGlobalLoader(true));
      callWaiter({ orderId, reason }).then((res: any) => {
        if (res?.data) {
          console.log("[call waiter api res] res?.data :>> ", res?.data);
        } else {
          const errMessage =
            res?.error?.data?.message ??
            res?.error?.error ??
            res?.error?.data?.error ??
            "Something went wrong. Please try again!";
          console.error("errMessage", errMessage);
          setError({ isActive: true, errMessage: errMessage });
        }
        dispatch(handleGlobalLoader(false));
      });
    }
  };

  // Updating the Reasons Card
  const updateCallWaiterReason = useCallback((pusherData: any) => {
    if (!pusherData || !Array.isArray(pusherData?.callWaiterReasons)) return;
    setCallWaiterReasonList([...pusherData?.callWaiterReasons]);
  }, []);

  useEffect(() => {
    updateCallWaiterReason(waiterPusherData || orderData);
  }, [waiterPusherData, orderData, updateCallWaiterReason]);

  return (
    <>
      <Modal
        open={true}
        onCancel={handleCloseModal}
        footer={null}
        mask={false}
        closable={false}
        style={{
          position: "fixed",
          bottom: "68px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          maxWidth: "100%",
          top: "auto",
          margin: 0,
          height: "100%",
          paddingBottom: 0,
          background: "transparent",
        }}
        bodyStyle={{
          padding: 0,
          animation: "slide-up 0.3s ease-out",
        }}
      >
        <div
          className="customBackdrop"
          style={{
            touchAction: "none",
          }}
        />

        <div className="callWaiterContainer p-4 rounded-t-lg relative z-10">
          <div className="flex flex-row items-center justify-between py-2">
            <span className="callWaiterTitle">What do you need help with?</span>
            <img
              src={callWaiterClose}
              alt="callWaiterClose"
              onClick={handleCloseModal}
            />
          </div>
          <div className="flex flex-row items-center gap-3 py-2">
            {callWaiterModalItems.map((item: WaiterItem) => (
              <div
                key={item.title}
                className={` relative flex flex-col items-center callWaiterItem gap-2 w-full rounded-lg py-3 ${
                  callWaiterReasonList.includes(item.enum)
                    ? "  border-2 selectedCallWaiterItem"
                    : ""
                }`}
                onClick={() => {
                  if (!callWaiterReasonList.includes(item.enum))
                    handleClick(item.enum);
                }}
              >
                {callWaiterReasonList.includes(item.enum) && (
                  <div className="absolute handGestureIcon p-2 -right-2 -top-2 rounded-full flex flex-row items-center justify-center">
                    <img src={handGesture} alt="handGesture" />
                  </div>
                )}
                <img
                  src={item.src}
                  alt={item.title}
                  className={`callWaiterItemImg ${
                    callWaiterReasonList.includes(item.enum) ? "opacity-65" : ""
                  }`}
                />
                <span
                  className={`${
                    callWaiterReasonList.includes(item.enum) ? "opacity-65" : ""
                  }`}
                >
                  {item.title}
                </span>
              </div>
            ))}
          </div>
        </div>
        {renderError()}
      </Modal>
    </>
  );
};

export default CallWaiterModal;
