import { Modal } from "antd";
import { ReactComponent as ModalClose } from "assets/images/modal-close-icon.svg";
import { rupeeFormatter } from "central-utils/currencyUtils";
import { handleFoodTypeImage } from "central-utils/foodTypeImageUtils";
import { handleModifierDescription } from "central-utils/modifierStringUtils";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import CustomLoader from "components/common/CustomLoader";
import CounterButton from "components/CounterButton";
import MenuItemCustomizationModal from "components/menu-item-customization-modal";
import { useOrder } from "context/order/OrderContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  useGetPreviousCustomizationsByProductMasterIdAndOrderIdQuery,
  useUpdateOrderItemQuantityByProductIdMutation,
} from "store/orders/ordersAPISlice";
import { handleCounterLoader } from "store/tab/tabSlice";
import { IObject } from "types";
import CustomToast from "./CustomToast";

interface ModalProps {
  productMasterId: number;
  isOpen: boolean;
  onClose: () => void;
  actionType: "ADD" | "REMOVE";
}

interface RepeatedMenuData {
  foodType: "NON_VEGETARIAN" | "VEGETARIAN" | "EGGETARIAN";
  name: string;
  description: string;
  quantity: number;
  unitPrice: number;
  helperData?: IObject;
}

// Define a type for updatedQuantities
type UpdatedQuantities = Record<string, number>;

const RepeatCustomizationModal: React.FC<ModalProps> = ({
  productMasterId,
  isOpen,
  onClose,
  actionType = "ADD",
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { orderIsUninitialized, refetchOrderData } = useOrder();

  const initialAPICallSuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );

  const orderId = JSON.parse(sessionStorage.getItem("OrderId") || "{}");

  const {
    data: repeatedMenuData,
    error: repeatedMenuErrorData,
    isLoading: repeatedMenuDataIsLoading,
    isError: repeatedMenuDataIsError,
    isSuccess: repeatedMenuDataIsSuccess,
    isFetching: repeatedMenuDataIsFetching,
    refetch: refetchRepeatedMenuData,
  } = useGetPreviousCustomizationsByProductMasterIdAndOrderIdQuery(
    { productMasterId, orderId },
    { skip: !initialAPICallSuccess }
  );

  const [updateQuantity] = useUpdateOrderItemQuantityByProductIdMutation();

  const handleFormatAPIData = (apiData: any): RepeatedMenuData[] => {
    const data = apiData?.map((obj: any) => {
      let description = obj?.menuItemMaster?.productVariant?.size?.size ?? "";

      if (obj?.instructions?.length) {
        const instructionString = obj?.instructions?.join(", ");
        description +=
          description !== "" ? `, ${instructionString}` : instructionString;
      }

      description += (description !== "" ? ", " : "") + handleModifierDescription(obj);

      return {
        foodType: obj?.menuItemMaster?.foodType ?? "",
        name: obj?.menuItemMaster?.displayName,
        description: convertToCapitalizedString(description),
        quantity: obj?.quantity,
        unitPrice:
          obj?.quantity !== 0
            ? Number(obj?.price / obj?.quantity)
            : obj?.quantity,
        helperData: obj,
      };
    });
    return data?.filter((obj: any) => obj?.quantity !== 0);
  };

  const [formattedAPIData, setFormattedAPIData] = useState<RepeatedMenuData[]>(
    []
  );
  const [updatedQuantities, setUpdatedQuantities] = useState<UpdatedQuantities>(
    {}
  );
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [customizationModal, setCustomizationModal] = useState(false);
  const [customizationModalType, setCustomizationModalType] = useState<
    "ADD" | "EDIT"
  >("ADD");
  const [editData, setEditData] = useState<IObject>({});
  const [toastMessageOnZeroQuantity, setToastMessageOnZeroQuantity] =
    useState(false);

  useEffect(() => {
    if (
      repeatedMenuDataIsSuccess &&
      repeatedMenuData &&
      repeatedMenuData?.length > 0
    ) {
      const processedData = handleFormatAPIData(repeatedMenuData);
      setFormattedAPIData(processedData);
    }
  }, [repeatedMenuDataIsSuccess, repeatedMenuData]);

  useEffect(() => {
    if (Array.isArray(formattedAPIData)) {
      const initialQuantities: UpdatedQuantities =
        formattedAPIData.reduce<UpdatedQuantities>((acc, item) => {
          const itemName =
            (item?.name?.toLowerCase() ?? "").replace(/\s+/g, "") +
            item?.quantity;
          acc[itemName] = item?.quantity ?? 0;
          return acc;
        }, {} as UpdatedQuantities);

      setUpdatedQuantities(initialQuantities);
    }
  }, [formattedAPIData]);

  useEffect(() => {
    if (
      repeatedMenuDataIsSuccess &&
      repeatedMenuData &&
      repeatedMenuData.every((item: any) => item.quantity === 0)
    ) {
      // toastMessageOnZeroQuantity
      //   ? toast.info("Updated your order!")
      //   : toast.info("Please place your order!");
      setToastMessageOnZeroQuantity(false);
      onClose();
    }
  }, [
    repeatedMenuDataIsSuccess,
    repeatedMenuData,
    onClose,
    dispatch,
    toastMessageOnZeroQuantity,
  ]);

  useEffect(() => {
    if (repeatedMenuDataIsError) {
      setToastMessage(
        repeatedMenuErrorData?.data?.message ??
        repeatedMenuErrorData?.error?.data?.message ??
        repeatedMenuErrorData?.error?.error ??
        repeatedMenuErrorData?.error?.data?.error ??
        "Something went wrong while fetching the data. Please try again!"
      );
    }
  }, [repeatedMenuDataIsError]);

  useEffect(() => {
    if (repeatedMenuDataIsSuccess && !repeatedMenuData?.length) {
      setToastMessage("Please place your order!");
      handleCloseModal();
    }
  }, [repeatedMenuData, repeatedMenuDataIsSuccess]);

  if (!isOpen) return null;

  const handleQuantityChange = (
    name: string,
    quantity: number,
    helperData: IObject
  ) => {
    setUpdatedQuantities((prevState) => ({
      ...prevState,
      [name]: quantity,
    }));

    dispatch(
      handleCounterLoader({
        itemId: helperData?.helperData?.menuItemMaster?.id,
        isLoading: true,
      })
    );

    updateQuantity({
      orderItemId: helperData?.helperData?.id,
      price: helperData?.unitPrice * quantity || 0,
      quantity: quantity,
    }).then((res: any) => {
      if (res?.data || (res?.data === null && quantity === 0)) {
        res?.data === null &&
          quantity === 0 &&
          setToastMessageOnZeroQuantity(true);
        console.log("[update quantity] res?.data :>> ", res?.data);
        refetchRepeatedMenuData({ productMasterId, orderId });
      } else {
        const errMessage =
          res?.error?.data?.message ??
          res?.error?.error ??
          res?.error?.data?.error ??
          "Something went wrong while updating the quantity. Please try again!";
        setToastMessage(errMessage);
        errMessage ===
          "Order is not in NEW status. Cannot add or update items." &&
          navigate("/payment-in-progress-block");
        [
          "Order Item already confirmed",
          "Order group is currently in progress and cannot be modified.",
        ].includes(errMessage.trim()) && window.location.reload();
      }
      dispatch(
        handleCounterLoader({
          itemId: helperData?.helperData?.menuItemMaster?.id,
          isLoading: false,
        })
      );
    });
  };

  const handleCloseModal = () => {
    !orderIsUninitialized && refetchOrderData();
    onClose();
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  const handleModifierEditData = (editData: IObject): IObject => {
    if (!editData?.helperData?.orderItemModifierQuantityMappings?.length) {
      return editData; // No mappings, return original data
    }

    const updatedModifiers = editData?.helperData?.modifiers?.map(
      (oldModObj: any) => {
        const matchingItem =
          editData?.helperData?.orderItemModifierQuantityMappings?.find(
            (newModObj: any) =>
              newModObj?.orderItemCustomerModifierMappingId === oldModObj?.id
          );
        return {
          ...oldModObj,
          ...matchingItem, // Merge matching fields
        };
      }
    );

    console.log("[updated edit data]  updatedModifiers", updatedModifiers);

    return {
      ...editData,
      helperData: {
        ...editData?.helperData,
        modifiers: updatedModifiers,
      },
    };
  };

  return (
    <Modal
      width={"100vw"}
      closable={false}
      open={true}
      onCancel={handleCloseModal}
      footer={null}
      className="repeatCustomizationModal"
      style={{
        padding: 0,
        margin: 0,
        borderRadius: "999px",
        maxWidth: "100vw",
      }}
    >
      <div className="repeatCustomizationModal">
        {(repeatedMenuDataIsFetching || repeatedMenuDataIsLoading) && (
          <CustomLoader
            isLoading={repeatedMenuDataIsFetching || repeatedMenuDataIsLoading}
          />
        )}
        <div className="repeatCustomizationModalContent">
          <div className="p-4 ">
            <div className="flex items-center justify-between relative pb-3">
              <h2 className="repeatCustomizationModalTitle">
                Repeat Customization
              </h2>

              <button onClick={handleCloseModal}>
                <ModalClose className="iconColor" />
              </button>
            </div>
            {formattedAPIData && repeatedMenuDataIsSuccess && (
              <>
                <div className="repeatCustomizationModalBody flex flex-col  mb-3">
                  {formattedAPIData?.length ? (
                    <div>
                      {formattedAPIData?.map((item, index) => {
                        const foodTypeImage = handleFoodTypeImage(
                          item?.foodType
                        );
                        const itemName =
                          item?.name?.toLowerCase().replace(/\s+/g, "") + index;
                        return (
                          <>
                            <div key={index} className="py-2 flex-1">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center ">
                                  <div className="">
                                    {foodTypeImage && (
                                      <img
                                        src={foodTypeImage}
                                        alt={item?.foodType}
                                        className="repeatCustomizationModalFoodImage"
                                      />
                                    )}
                                  </div>
                                  <div className="ml-3">
                                    <h3 className="repeatCustomizationModalItemName">
                                      {convertToCapitalizedString(item?.name)}
                                    </h3>
                                    <p className="repeatCustomizationModalItemDescription mt-1">
                                      {item?.description}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-row gap-4 items-center">
                                  <CounterButton
                                    buttonType="SMALL"
                                    buttonName={itemName}
                                    initialQuantity={item?.quantity}
                                    onQuantityChange={(quantity) =>
                                      handleQuantityChange(
                                        itemName,
                                        quantity,
                                        item
                                      )
                                    }
                                    itemId={
                                      item?.helperData?.menuItemMaster?.id
                                    }
                                  />
                                  <div className="repeatCustomizationModalEditPrice text-center flex flex-col">
                                    {item?.unitPrice !== 0
                                      ? rupeeFormatter(
                                        item?.unitPrice *
                                        (updatedQuantities[itemName] ??
                                          item?.quantity)
                                      )
                                      : ""}
                                    {formattedAPIData?.length > 1 && (
                                      <button
                                        className="repeatCustomizationModalEditButton rounded-sm font-light"
                                        onClick={() => {
                                          setCustomizationModal(true);
                                          setCustomizationModalType("EDIT");
                                          setEditData(
                                            handleModifierEditData(item)
                                          );
                                        }}
                                      >
                                        Edit
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {formattedAPIData?.length > 1 && (
                              <div className="repeatCustomizationModalItemSeparator mx-1 py-2"></div>
                            )}
                          </>
                        );
                      })}
                      {actionType === "ADD" ? (
                        formattedAPIData?.length === 1 ? (
                          <div className="pt-4 flex flex-row gap-2 items-center">
                            <button
                              onClick={() => {
                                setCustomizationModal(true);
                                setCustomizationModalType("EDIT");
                                setEditData(
                                  handleModifierEditData(formattedAPIData[0])
                                );
                              }}
                              className="w-full text-center repeatCustomizationModalEditCustomizationButton  p-4 rounded-md"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                setCustomizationModal(true);
                                setCustomizationModalType("ADD");
                              }}
                              className="w-full text-center repeatCustomizationModalAddCustomizationButton p-4 rounded-md"
                            >
                              Add Item
                            </button>
                          </div>
                        ) : (
                          <div className="pt-4">
                            <button
                              onClick={() => {
                                setCustomizationModal(true);
                                setCustomizationModalType("ADD");
                              }}
                              className="w-full text-center repeatCustomizationModalAddCustomizationButton p-4 rounded-md"
                            >
                              + Add New Customization
                            </button>
                          </div>
                        )
                      ) : (
                        formattedAPIData?.length === 1 && (
                          <div className="pt-4">
                            <button
                              onClick={() => {
                                setCustomizationModal(true);
                                setCustomizationModalType("EDIT");
                                setEditData(
                                  handleModifierEditData(formattedAPIData[0])
                                );
                              }}
                              className="w-full text-center repeatCustomizationModalEditCustomizationButton  p-4 rounded-md"
                            >
                              Edit
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
            {renderToast()}
          </div>
          {customizationModal && (
            <MenuItemCustomizationModal
              editData={editData?.helperData}
              type={customizationModalType}
              productMasterId={productMasterId}
              onClose={() => {
                setCustomizationModal(false);
                handleCloseModal();
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RepeatCustomizationModal;
