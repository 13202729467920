import { ReactComponent as ErrorIcon } from 'assets/images/error.svg';
import QRCodeGenerator from 'components/QRCodeGenerator';
import QRCodeModal from 'components/QRCodeModal';
import { useOrder } from 'context/order/OrderContext';
import { useTableVerificationPusherChannel } from 'context/pusher/table-verification';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/customHooks';

const TableVerification: React.FC = () => {
    const { pathname } = useLocation();
    const { orderIsSuccess, orderData } = useOrder();
    const pusherTableVerificationData = useTableVerificationPusherChannel();

    const [isQRCodeModalOpen, setQRCodeModalOpen] = useState(false);
    const [tableVerification, setTableVerification] = useState(false);

    const tableInfo = useAppSelector((state) => state.tableStatusAndOrder.tableInfo);
    const customerDetails = useAppSelector((state) => state.tableStatusAndOrder.customerDetails);

    useEffect(() => {
        if (
            pusherTableVerificationData &&
            Object.keys(pusherTableVerificationData ?? {})?.length > 0 &&
            (pusherTableVerificationData?.tableCode === (tableInfo?.tableCode ?? customerDetails?.tableCode))
        ) {
            sessionStorage.setItem("TableVerification", "VERIFIED");
            setTableVerification(true);
            setQRCodeModalOpen(false);
        }
    }, [customerDetails?.tableCode, pusherTableVerificationData, tableInfo?.tableCode]);

    useEffect(() => {
        const ssTableVerification = sessionStorage.getItem("TableVerification");
        ssTableVerification === "NOT_VERIFIED" && setTableVerification(false);
        if (ssTableVerification === "VERIFIED") {
            setTableVerification(true);
            setQRCodeModalOpen(false);
        }
    }, []);

    useEffect(() => {
        if (orderData && orderIsSuccess && Object.keys(orderData)?.length) {
            sessionStorage.setItem("TableVerification", orderData?.isVerified ? "VERIFIED" : "NOT_VERIFIED");
            !orderData?.isVerified && setTableVerification(false);
            if (orderData?.isVerified) {
                setTableVerification(true);
                setQRCodeModalOpen(false);
            }
        }
    }, [orderData, orderIsSuccess]);

    if (tableVerification) {
        return null;
    } else {
        return (
            <>
                <div
                    onClick={() => setQRCodeModalOpen(true)}
                    className={`${pathname !== "/order" && "rounded-[5px] mt-[20px]"} tableVerification p-[10px] flex justify-between items-center`}
                >
                    <div className='tableInfo'>
                        <div className='tableStatus flex items-center'>
                            <ErrorIcon className='errorIcon' />
                            <span className='ml-[10px]'> Your Table is not yet verified</span>
                        </div>
                        <div className='tableDescription mt-[7px]'>
                            Please ask any of our customer associates to validate your table to place an order
                        </div>
                    </div>
                    {
                        orderIsSuccess && <div className='qrCodeContainer flex flex-col justify-center items-center'>
                            <QRCodeGenerator
                                qrCodeSize={32}
                                valueToBeEncoded={orderData?.id}
                                bgColor='rgba(255, 255, 255, 0.15)'
                                fgColor='#F0F0F0'
                            />
                            <div className='tapToOpenText mt-[8px]'>
                                Tap to Open
                            </div>
                        </div>
                    }
                </div>
                {
                    orderIsSuccess &&
                    isQRCodeModalOpen &&
                    <QRCodeModal
                        toggleModal={() => setQRCodeModalOpen(!isQRCodeModalOpen)}
                    />
                }
            </>
        )
    }
}

export default TableVerification