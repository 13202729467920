import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLoginMutation } from "store/auth/authApiSlice";
import {
  handleBranchData,
  handleBranchFetched,
  handleGlobalLoader,
  handleInitialAPICallSuccess,
  handleInitialization,
  handleLogInCredentials,
  handleLogOut,
  handleTableStatusFetched,
  handleTenantData,
  handleTenantFetched,
} from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  useGetBranchByIdQuery,
  useGetTableStatusByBranchIdAndTableIdQuery,
  useGetTenantByIdQuery,
} from "store/initialAPI/initialAPISlice";

interface Props {
  children?: ReactNode;
}

const USERNAME = process.env.REACT_APP_USERNAME as string;
const PASSWORD = process.env.REACT_APP_PASSWORD as string;

const InitialDataFetcher: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const brandId = queryParams.get("brandId");
  const branchId = queryParams.get("branchId");
  const tableId = queryParams.get("tableId");

  const parsedIds = JSON.parse(sessionStorage.getItem("Ids") || "{}");

  useEffect(() => {
    if (branchId && brandId && tableId) {
      sessionStorage.setItem(
        "Ids",
        JSON.stringify({
          branchId: Number(branchId),
          brandId: Number(brandId),
          tableId: Number(tableId),
        })
      );
    } else {
      dispatch(handleGlobalLoader(false));
    }
  }, [brandId, branchId, tableId, dispatch]);

  const initialize = useAppSelector((state) => state.auth.initialize);
  const tenantFetched = useAppSelector((state) => state.auth.tenantFetched);
  const branchFetched = useAppSelector((state) => state.auth.branchFetched);
  const tableStatusFetched = useAppSelector(
    (state) => state.auth.tableStatusFetched
  );
  const logOutState = useAppSelector((state) => state.auth.logOutState);

  const [loginSuccess, setLoginSuccess] = useState(false);
  const [login, { isLoading: logInIsLoading, isError: logInIsError }] =
    useLoginMutation();

  useEffect(() => {
    const performLogin = async () => {
      try {
        const res: any = await login({
          userName: USERNAME,
          password: PASSWORD,
        }).unwrap();

        if (res) {
          dispatch(handleLogInCredentials({ data: res }));
          dispatch(handleInitialization());
          setLoginSuccess(true);
        }
      } catch (err) {
        console.error("Login failed. Please try again", err);
        dispatch(handleLogOut());
        setLoginSuccess(false);
      }
    };

    if (!initialize && !logOutState) {
      location.pathname !== "/payment-status" && performLogin();
    }
  }, [initialize, login, dispatch, logOutState, location.pathname]);

  const {
    data: tenantData,
    isLoading: tenantDataIsLoading,
    isError: tenantDataIsError,
    isSuccess: tenantDataIsSuccess,
    isFetching: tenantDataIsFetching,
  } = useGetTenantByIdQuery(
    { brandId: Number(brandId ?? parsedIds?.brandId) },
    {
      skip:
        !loginSuccess ||
        tenantFetched ||
        ["/payment-confirmation", "/payment-status"].includes(
          location.pathname
        ),
    }
  );

  useEffect(() => {
    if (tenantDataIsSuccess && tenantData) {
      sessionStorage.setItem(
        "CustomerApp-Tenant",
        JSON.stringify({
          tenantName: tenantData.tenantName,
          id: tenantData.id,
          databaseName: tenantData.databaseName,
          shortName: tenantData?.shortName,
        })
      );
      dispatch(
        handleTenantData({
          tenantName: tenantData.tenantName,
          id: tenantData.id,
          databaseName: tenantData.databaseName,
          shortName: tenantData?.shortName,
          helperData: tenantData,
        })
      );
      dispatch(handleTenantFetched(true));
    }
  }, [tenantData, tenantDataIsSuccess, dispatch]);

  useEffect(() => {
    const tenant = JSON.parse(
      sessionStorage.getItem("CustomerApp-Tenant") || "{}"
    );

    if (tenant.databaseName) {
      dispatch(handleTenantFetched(true));
    }
  }, [dispatch]);

  const {
    data: branchData,
    isLoading: branchIsLoading,
    isError: branchIsError,
    isSuccess: branchIsSuccess,
    isFetching: branchIsFetching,
  } = useGetBranchByIdQuery(
    {
      branchId: Number(branchId ?? parsedIds?.branchId),
    },
    {
      skip:
        !loginSuccess ||
        !tenantFetched ||
        branchFetched ||
        ["/payment-confirmation", "/payment-status"].includes(
          location.pathname
        ),
    }
  );

  useEffect(() => {
    if (branchIsSuccess && branchData) {
      sessionStorage.setItem(
        "Branch",
        JSON.stringify({
          branchId: branchData?.branchId,
          id: branchData?.id,
          branchName: branchData?.branchName,
          shortName: branchData?.shortName,
          displayName: branchData?.displayName,
        })
      );

      dispatch(handleBranchData(branchData));
    }
  }, [branchData, branchIsSuccess, dispatch]);

  useEffect(() => {
    const branch = JSON.parse(sessionStorage.getItem("Branch") || "{}");

    if (branch.shortName) {
      dispatch(handleBranchFetched(true));
    }
  }, [dispatch]);

  const {
    data: tableStatusData,
    isLoading: tableStatusIsLoading,
    isError: tableStatusIsError,
    isSuccess: tableStatusIsSuccess,
    isFetching: tableStatusIsFetching,
  } = useGetTableStatusByBranchIdAndTableIdQuery(
    {
      branchId: Number(branchId ?? parsedIds?.branchId),
      tableId: Number(tableId ?? parsedIds?.tableId),
    },
    {
      skip:
        !loginSuccess ||
        !tenantFetched ||
        tableStatusFetched ||
        ["/payment-confirmation", "/payment-status"].includes(
          location.pathname
        ),
    }
  );

  useEffect(() => {
    if (tableStatusIsSuccess && tableStatusData) {
      const lsCheckIn = sessionStorage.getItem("CheckIn");
      if (!lsCheckIn) {
        if (
          tableStatusData &&
          Object.hasOwn(tableStatusData, "isCustomerAssociateNotAssigned") &&
          tableStatusData?.isCustomerAssociateNotAssigned

        ) {
          sessionStorage.setItem("CheckIn", "CUST_ASSOC_NOT_ASSIGNED");
        } else if (
          tableStatusData &&
          Object.hasOwn(tableStatusData, "isOrderCheckOut") &&
          tableStatusData?.isOrderCheckOut

        ) {
          sessionStorage.setItem("CheckIn", "CHECKOUT");
        } else if (
          tableStatusData &&
          Object.hasOwn(tableStatusData, "isOpenOrder") &&
          tableStatusData?.isOpenOrder
        ) {
          sessionStorage.setItem(
            "CheckIn",
            "JOIN"
          );
        } else if (
          tableStatusData &&
          Object.hasOwn(tableStatusData, "isOpenOrder") &&
          !tableStatusData?.isOpenOrder
        ) {
          sessionStorage.setItem("CheckIn", "NEW");
        }
        window.dispatchEvent(new Event("storage"));
      }
      dispatch(handleTableStatusFetched(true));
    }
  }, [tableStatusIsSuccess, tableStatusData, dispatch]);

  useEffect(() => {
    const isFetching =
      tenantDataIsFetching || tableStatusIsFetching || branchIsFetching;
    const isLoading =
      logInIsLoading ||
      tableStatusIsLoading ||
      tenantDataIsLoading ||
      branchIsLoading;

    dispatch(handleGlobalLoader(isFetching));
    dispatch(handleGlobalLoader(isLoading));

    if (tenantDataIsFetching) {
      console.log("Tenant - Fetching....");
    }
    if (branchIsFetching) {
      console.log("Branch - Fetching....");
    }
    if (tableStatusIsFetching) {
      console.log("Table Status - Fetching....");
    }
    if (logInIsLoading) {
      console.info("Customer App User - Loading....");
    }
    if (branchIsLoading) {
      console.info("Branch - Loading....");
    }
    if (tableStatusIsLoading) {
      console.info("Table Status - Loading....");
    }
    if (tenantDataIsLoading) {
      console.info("Tenant Status - Loading....");
    }
  }, [
    logInIsLoading,
    tableStatusIsLoading,
    tenantDataIsLoading,
    tenantDataIsFetching,
    tableStatusIsFetching,
    dispatch,
    branchIsFetching,
    branchIsLoading,
  ]);

  useEffect(() => {
    if (logInIsError) {
      console.log("Customer App User - Error during login. Please try again.");
    }
    if (tableStatusIsError) {
      console.log("Table Status - Error during fetch. Please try again.");
    }
    if (tenantDataIsError) {
      console.log("Tenant - Error during fetch. Please try again.");
    }
    if (branchIsError) {
      console.log("Branch - Error during fetch. Please try again.");
    }
  }, [
    logInIsError,
    tableStatusIsError,
    tenantDataIsError,
    dispatch,
    branchIsError,
  ]);

  useEffect(() => {
    const tenant = JSON.parse(
      sessionStorage.getItem("CustomerApp-Tenant") || "{}"
    );
    const branch = JSON.parse(sessionStorage.getItem("Branch") || "{}");
    const tableStatus =
      tableStatusData && Object.keys(tableStatusData).length > 0;
    const allSuccess = tableStatus && !logInIsError && !tableStatusIsError;
    console.log(
      "allSuccess && tenant?.databaseName && branch?.shortName :>> ",
      allSuccess,
      tenant?.databaseName,
      branch?.shortName
    );
    if (allSuccess && tenant?.databaseName && branch?.shortName) {
      console.log("All 3 API Success");
      dispatch(handleInitialAPICallSuccess(true));
    }
  }, [
    dispatch,
    logInIsError,
    tableStatusData,
    tableStatusIsError,
    branchData,
    branchIsSuccess,
  ]);

  return <div>{children}</div>;
};

export default InitialDataFetcher;