import { apiSlice } from "store/api/apiSlice";

export const menuCustomizationAPISlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategorizedMenuItems: builder.query({
      query: ({
        branchId,
        vegFilter = false,
        nonVegFilter = false,
        eggFilter = false,
        alcoholicFilter = false,
        nonAlcoholicFilter = false,
      }) => {
        let filters: string[] = [];
        if (vegFilter) {
          filters.push(`&foodTypes=VEGETARIAN`);
        } else if (nonVegFilter) {
          filters.push(`&foodTypes=NON_VEGETARIAN`);
        } else if (eggFilter) {
          filters.push(`&foodTypes=EGGETARIAN`);
        } else if (alcoholicFilter) {
          filters.push(`&foodTypes=ALCOHOL`);
        } else if (nonAlcoholicFilter) {
          filters.push(`&foodTypes=BEVERAGES`);
        }

        const url =
          filters?.length > 0
            ? `/menuItemMaster/menu-item-grouped-category?branchId=${branchId}${filters}`
            : `/menuItemMaster/menu-item-grouped-category?branchId=${branchId}`;

        return {
          url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),

    getMenuItemByProductIdAndBranchId: builder.query({
      query: ({ productMasterId, branchId }) => ({
        url: `/menuItemMaster/getCustomizationResponseForProductMaster?productMasterId=${productMasterId}&branchId=${branchId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetCategorizedMenuItemsQuery,
  useGetMenuItemByProductIdAndBranchIdQuery,
} = menuCustomizationAPISlice;
