/**
 * Validates a channel name by ensuring it only contains alphanumeric characters,
 * hyphens, and underscores. Any character that doesn't match the allowed pattern is
 * replaced with a hyphen ("-").
 */

export function validateChannelName(channelName: string): string {
  // Define the regex pattern to match allowed characters
  const validCharacters = /[a-zA-Z0-9\-_]/g;

  // Replace any character not matching the valid pattern with a hyphen
  return channelName
    .split("")
    .map((char: string) => (char.match(validCharacters) ? char : "-"))
    .join("");
}
