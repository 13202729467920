import React from "react";

interface CustomCheckBoxProps {
  type?: "RADIO" | "CHECKBOX";
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
}

const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
  checked,
  onChange,
  type = "CHECKBOX",
  disabled = false
}) => {
  if (type === "RADIO") {
    return (
      <label className="inline-flex items-center cursor-pointer radioButton">
        <input
          disabled={disabled}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="hidden"
        />
        <span
          className={`flex items-center justify-center radioSpan`}
        >
          {checked ? (
            <span className={`radioChecked ${disabled ? "radioDisabled" : ""} `} />
          ) : (
            null
          )}
        </span>
      </label>
    );
  } else {
    return (
      <label className="inline-flex items-center cursor-pointer checkboxButton">
        <input
          disabled={disabled}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="hidden"
        />
        <span
          className={`flex items-center justify-center ${disabled ? "checkboxDisabled" : ""} checkboxSpan`}
        >
          {checked ? (
            <svg
              className="checkboxSvg"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path
                d="M5 13l4 4L19 7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : null}
        </span>
      </label>
    );
  }
};

export default CustomCheckBox;
