import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IObject } from "types";

interface CheckoutState {
  billDetails: IObject;
}

const initialState: CheckoutState = {
  billDetails: {},
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    handleBillDetails: (state, action: PayloadAction<IObject>) => {
      state.billDetails = action.payload;
    },
  },
});

export const { handleBillDetails } = checkoutSlice.actions;

export default checkoutSlice.reducer;
