import { ReactComponent as ProfileIcon } from "assets/images/profile.svg";
import { ReactComponent as SearchIcon } from "assets/images/search.svg";
import UserProfileModal from "components/header/UserProfileModal";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/customHooks";

const DefaultHeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const branchData = useAppSelector((state) => state.auth.branch);
  const tableInfo = useAppSelector(
    (state) => state.tableStatusAndOrder.tableInfo
  );
  const customerDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.customerDetails
  );

  const checkIn = sessionStorage.getItem("CheckIn");
  const branchSessionStorageData = JSON.parse(
    sessionStorage.getItem("Branch") || "{}"
  );

  const { defaultHeaderLogo, altName } = useAppSelector(
    (state) => state.header
  );

  const [showCustomerDetails, setShowCustomerDetails] = useState(false);

  useEffect(() => {
    if (!checkIn) {
      window.location.href = "/";
    }
  }, [checkIn]);

  useEffect(() => {
    setShowCustomerDetails(false);
  }, [location]);

  return (
    <div className="defaultHeader">
      <div className="flex justify-between relative items-center headerContainer">
        <div className="flex">
          <img
            alt={altName || ""}
            src={defaultHeaderLogo}
            className="mx-[20px]"
            onClick={() => navigate("/home")}
          />
          <div>
            <div className="branchInfo">
              <span>
                {branchData?.displayName ||
                  branchSessionStorageData?.displayName}
                :
              </span>
              <span className="ml-[5px]">
                TABLE {tableInfo?.tableNames?.join(", ")}
              </span>
            </div>
            <button className="pinInfo p-[2px]">
              <span>PIN </span>
              <span>{tableInfo?.tableCode ?? customerDetails?.tableCode}</span>
            </button>
          </div>
        </div>
        <div className="flex">
          <SearchIcon
            className="mr-[33px] headerIconColor"
            onClick={() => navigate("/search")}
          />
          <ProfileIcon
            className={`mr-[27px] headerIconColor ${
              showCustomerDetails && "z-[9999]"
            }`}
            onClick={() => setShowCustomerDetails(!showCustomerDetails)}
          />
        </div>
      </div>
      {showCustomerDetails && (
        <UserProfileModal handleClose={() => setShowCustomerDetails(false)} />
      )}
    </div>
  );
};

export default DefaultHeaderContent;
