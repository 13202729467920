import { usePusher } from 'context/pusher';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IObject, PusherConnectionState } from 'types';

interface Props {
    children: ReactNode;
}

const WaiterContext = createContext<IObject | null>(null);

const WaiterChannelProvider: React.FC<Props> = ({ children }) => {
    const location = useLocation();

    const ids = sessionStorage.getItem("Ids");
    const parsedIds: { brandId: string, branchId: string, tableId: string } | null = ids ? JSON.parse(ids) : null;

    const pusher = usePusher();
    const [waiterData, setWaiterData] = useState<IObject | null>(null);

    useEffect(() => {
        if (pusher && parsedIds) {
            const channelName = `tenant-${parsedIds.brandId}-branch-${parsedIds.branchId}-table-${parsedIds.tableId}-customerPusherChannel`;
            const channel = pusher.subscribe(channelName);

            console.log('[waiter] pusher - customerCallWaiterPusherEvent is the event - channelName :>> ', channelName);

            // Check if Pusher is connected
            const logConnectionState = () => {
                console.log(`[waiter] Pusher connection state: ${pusher.connection.state}`);
            };

            // Log initial connection state
            logConnectionState();

            // Bind connection state change events to log with proper typing
            pusher.connection.bind('state_change', ({ previous, current }: { previous: PusherConnectionState; current: PusherConnectionState }) => {
                console.log(`[waiter] Pusher connection state changed from ${previous} to ${current}`);
            });

            // Bind to the 'connected' event for successful connection
            pusher.connection.bind('connected', () => {
                console.log('[waiter] Pusher connected successfully');
            });

            // Bind to the 'disconnected' event for connection lost
            pusher.connection.bind('disconnected', () => {
                console.log('[waiter] Pusher disconnected');
            });

            channel.bind('customerCallWaiterPusherEvent', (data: any) => {
                try {
                    console.log('[call waiter] without parsed log :>> ', data);
                    const parsedWaiterData = JSON.parse(data);
                    console.log(`${new Date()} - [waiter] parsedWaiterData pusher data :>> `, parsedWaiterData);
                    if (parsedWaiterData && Object.keys(parsedWaiterData)?.length > 0) {
                        setWaiterData(parsedWaiterData);
                    }
                } catch (error) {
                    console.error('[waiter] Error parsing cart data:', error, 'Raw data:', data);
                }
            });

            channel.bind('pusher:subscription_error', (status: any) => {
                console.error(`[waiter] Failed to subscribe to channel ${channelName}:`, status);
            });

            return () => {
                // console.log(`[waiter] Unsubscribing from Pusher channel: ${channelName}`);
                // channel.unbind_all();
                // pusher.unsubscribe(channelName);
            };
        }
    }, [pusher, parsedIds]);

    useEffect(() => {
        setTimeout(() => {
            setWaiterData(null);
        }, 2000);
    }, [location]);

    return (
        <WaiterContext.Provider value={waiterData}>
            {children}
        </WaiterContext.Provider>
    );
};

const useWaiterPusherChannel = (): IObject | null => {
    const context = useContext(WaiterContext);
    // if (context === null) {
    //     throw new Error('useWaiterPusherChannel must be used within a WaiterChannelProvider');
    // }
    return context;
};

export default WaiterChannelProvider;
export { useWaiterPusherChannel };