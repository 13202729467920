import React from "react";

interface Props {
  active: boolean;
  target: string;
  onToggle: () => void;
}

const CustomToggle: React.FC<Props> = ({ active, target, onToggle }) => {
  const handleClick = () => {
    onToggle(); // Always toggle when clicked
  };

  return (
    <div className="customToggleWrapper flex">
      <label className={`switch ${active ? 'active' : ''}`} aria-checked={active}>
        <input
          type="checkbox"
          checked={active}
          onChange={handleClick}
        // Keeping the `input` interactive with `onChange` for accessibility
        />
        <span className={`slider round ${target}`} />
      </label>
    </div>
  );
};

export default CustomToggle;
