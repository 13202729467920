import { usePusher } from 'context/pusher';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PusherConnectionState } from 'types';

interface Props {
    children: ReactNode;
}

interface IPricePusherData {
    isPriceUpdateFlag: boolean;
    message: string;
}

const PriceContext = createContext<IPricePusherData | null>(null);

const PriceChannelProvider: React.FC<Props> = ({ children }) => {
    const location = useLocation();

    const ids = sessionStorage.getItem("Ids");
    const parsedIds: { brandId: string, branchId: string, tableId: string } | null = ids ? JSON.parse(ids) : null;

    const pusher = usePusher();
    const [priceData, setPriceData] = useState<IPricePusherData | null>(null);

    useEffect(() => {
        if (pusher && parsedIds) {
            const channelName = `tenant-${parsedIds?.brandId}-branch-${parsedIds?.branchId}-updatePriceChannel`
            const channel = pusher.subscribe(channelName);

            console.log('[price] pusher - updatePrice is the event - channelName :>> ', channelName);

            // Check if Pusher is connected
            const logConnectionState = () => {
                console.log(`[price] Pusher connection state: ${pusher.connection.state}`);
            };

            // Log initial connection state
            logConnectionState();

            // Bind connection state change events to log with proper typing
            pusher.connection.bind('state_change', ({ previous, current }: { previous: PusherConnectionState; current: PusherConnectionState }) => {
                console.log(`[price] Pusher connection state changed from ${previous} to ${current}`);
            });

            // Bind to the 'connected' event for successful connection
            pusher.connection.bind('connected', () => {
                console.log('[price] Pusher connected successfully');
            });

            // Bind to the 'disconnected' event for connection lost
            pusher.connection.bind('disconnected', () => {
                console.log('[price] Pusher disconnected');
            });

            channel.bind('updatePrice', (data: any) => {
                try {
                    const parsedPriceData = JSON.parse(data);
                    console.log(`${new Date()} - [price] pusher parsedPriceData :>> `, parsedPriceData);
                    setPriceData(parsedPriceData);
                } catch (error) {
                    console.error('[price] Error parsing cart data:', error, 'Raw data:', data);
                }
            });

            channel.bind('pusher:subscription_error', (status: any) => {
                console.error(`[price] Failed to subscribe to channel ${channelName}:`, status);
            });

            return () => {
                // console.log(`[price] Unsubscribing from Pusher channel: ${channelName}`);
                // channel.unbind_all();
                // pusher.unsubscribe(channelName);
            };
        }
    }, [pusher, parsedIds]);

    useEffect(() => {
        setTimeout(() => {
            setPriceData(null);
        }, 2000);
    }, [location]);

    return (
        <PriceContext.Provider value={priceData}>
            {children}
        </PriceContext.Provider>
    );
};

const usePriceChannel = (): IPricePusherData | null => {
    const context = useContext(PriceContext);
    // if (context === null) {
    //     throw new Error('usePriceChannel must be used within a PriceChannelProvider');
    // }
    return context;
};

export default PriceChannelProvider;
export { usePriceChannel };
