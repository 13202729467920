import { ReactComponent as Cached } from "assets/images/cached.svg";
import { ReactComponent as ChangeNo } from "assets/images/change-no.svg";
import otpIcon from "assets/images/otp-icon.svg";
import FieldErrorMessage from "components/common/FieldErrorMessage";
import CustomToast from "components/CustomToast";
import usePreventBackNavigation from "hooks/usePreventBackNavigation";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { useCreateNewCustomerOrderMutation } from "store/orders/ordersAPISlice";
import {
  handleCustomerDetails,
  handleOrderDetails,
} from "store/tableStatusAndOrder/tableStatusAndOrderSlice";
interface FormValues {
  otpPin: string;
}

const OtpVerificationPage = () => {
  usePreventBackNavigation("/verify-otp");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCustomer] = useCreateNewCustomerOrderMutation();
  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const [resendTime, setResendTime] = useState(59);
  const [invalidOtpError, setInvalidOtpError] = useState({
    isActive: false,
    errMessage: "",
  });
  const [isOtpResent, setIsOtpResent] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const mobile =
    useAppSelector(
      (state) => state.tableStatusAndOrder.customerDetails?.mobile
    ) || JSON.parse(sessionStorage.getItem("CustomerDetails") || "{}").mobile;
  const { guests, tableCode } = useAppSelector(
    (state) => state.tableStatusAndOrder.customerDetails
  );

  const ids = sessionStorage.getItem("Ids");
  const parsedIds = ids && JSON.parse(ids);
  const branchDetails = sessionStorage.getItem("Branch");
  const parsedBranchDetails = branchDetails && JSON.parse(branchDetails);
  const orderDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.orderDetails
  );

  const validateForm = (): boolean => {
    const newErrors: Partial<FormValues> = {};
    console.log("otpPin", otp?.join(""));
    if (!/^[0-9]/.test(otp?.join(""))) {
      newErrors.otpPin = "The OTP entered is invalid. Please try again";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setResendTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Reset success toast after 3 seconds
  useEffect(() => {
    if (isOtpResent) {
      const timer = setTimeout(() => {
        setIsOtpResent(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isOtpResent]);

  // Reset error toast after 3 seconds
  useEffect(() => {
    if (invalidOtpError.isActive) {
      const timer = setTimeout(() => {
        setInvalidOtpError({ isActive: false, errMessage: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [invalidOtpError.isActive]);

  // const handleChange = (element: HTMLInputElement, index: number) => {
  //   if (isNaN(Number(element.value))) return false;

  //   setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

  //   if (element.nextSibling && element.value !== "") {
  //     (element.nextSibling as HTMLInputElement).focus();
  //   }
  // };
  const handleChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move to next input if value is entered
    if (element.value !== "" && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    // Handle backspace
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent default backspace behavior
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Move to previous input if current input is empty and we're not at the first input
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };
  const renderError = () => {
    if (invalidOtpError?.isActive) {
      return <CustomToast message={invalidOtpError?.errMessage} />;
    }
    return null;
  };
  const renderSuccess = () => {
    if (isOtpResent) {
      return <CustomToast type="success" message="OTP sent successfully!" />;
    }
    return null;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(handleGlobalLoader(true));
      let otpPin = otp.join("");
      console.log("Submitting OTP:", otpPin, tableCode);
      let payload: any = {
        phoneNo: mobile,
        noOfGuest: guests || 0,
        tableId: parsedIds?.tableId,
        branchId: parsedIds?.branchId ?? parsedBranchDetails?.branchId,
        otpPin: otpPin,
        // "name":"riddhi"
      };

      if (
        localStorage?.getItem("deviceId") !== undefined &&
        localStorage?.getItem("deviceId") !== null &&
        localStorage?.getItem("deviceId") !== "undefined" &&
        localStorage?.getItem("deviceId") !== "null"
      ) {
        payload = {
          ...payload,
          deviceId: localStorage?.getItem("deviceId"),
        };
      }
      // if (checkIn === "NEW") {

      createCustomer(payload).then((res: any) => {
        if (res?.data) {
          console.log("[order] res?.data ::>>", res?.data);

          if (res?.data?.orderId) {
            dispatch(handleOrderDetails({ id: res?.data?.orderId }));
            sessionStorage.setItem("OrderId", res?.data?.orderId);
          }
          sessionStorage.setItem(
            "TableVerification",
            res?.data?.isVerified ? "VERIFIED" : "NOT_VERIFIED"
          );
          if (res?.data?.primaryCustomerName || res?.data?.customerDetails) {
            dispatch(
              handleCustomerDetails({
                // ...payload,
                tableCode: payload?.tableId,
                guests: payload?.noOfGuest,
                mobile: payload?.phoneNo,
                primaryName: res?.data?.primaryCustomerName || "",
                name: res?.data?.customerDetails
                  ? res?.data?.customerDetails?.name
                  : "",
              })
            );
            if (res?.data?.customerDetails) {
              const customerDetailsPayload = {
                id: res?.data?.customerDetails?.id,
                name: res?.data?.customerDetails?.name,
                mobile: res?.data?.customerDetails?.phoneNo,
                primaryName: res?.data?.primaryCustomerName || "",
              };
              sessionStorage.setItem(
                "CustomerDetails",
                JSON.stringify(customerDetailsPayload)
              );
              res?.data?.customerDetails?.deviceId &&
                localStorage.setItem(
                  "deviceId",
                  res?.data?.customerDetails?.deviceId
                );
            }
          } else {
            dispatch(
              handleCustomerDetails({
                tableCode: payload?.tableId,
                guests: payload?.noOfGuest,
                mobile: payload?.phoneNo,
              })
            );
          }
          //   dispatch(handleOrderDetails(res?.data));
          if (
            res?.data?.customerAlreadyMapped &&
            res?.data?.orderId &&
            Object.keys(res?.data?.customerDetails || {})?.length
          ) {
            navigate("/home", { state: { preventBackwardNavigation: true } });
          } else {
            res?.data?.isNewNumber
              ? //   res?.data?.isCustomerNameNeeded &&
                navigate("/enter-name", {
                  state: { preventBackwardNavigation: true },
                })
              : res?.data?.isTablePinNeeded
              ? navigate("/verify-pin", {
                  state: { preventBackwardNavigation: true },
                })
              : res?.data?.orderId
              ? navigate("/home", {
                  state: { preventBackwardNavigation: true },
                })
              : setInvalidOtpError({
                  isActive: true,
                  errMessage:
                    "Oops, We were not able to associate the number with the table. Please scan again.",
                });
          }
          dispatch(handleGlobalLoader(false));
        } else {
          const errMessage =
            res?.error?.data?.message ??
            res?.error?.error ??
            res?.error?.data?.error ??
            "Something went wrong. Please try again!";
          setInvalidOtpError({ isActive: true, errMessage: errMessage });
          dispatch(handleGlobalLoader(false));
        }
      });
    }
  };

  const resendOtp = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(handleGlobalLoader(true));

    let payload: any = {
      phoneNo: mobile,
      deviceId: localStorage?.getItem("deviceId") || "",
      noOfGuest: guests || 0,
      tableId: parsedIds?.tableId,
      branchId: parsedIds?.branchId ?? parsedBranchDetails?.branchId,
    };

    createCustomer(payload).then((res: any) => {
      if (res?.data) {
        // if (res?.data?.isOtpNeeded) {
        setIsOtpResent(true);
        setResendTime(59); // Reset the timer after successful resend
        // }
        dispatch(handleGlobalLoader(false));
      } else {
        const errMessage =
          res?.error?.data?.message ??
          res?.error?.error ??
          res?.error?.data?.error ??
          "Something went wrong. Please try again!";
        if (errMessage === "This customer is already added to this order") {
          navigate("/home", { state: { preventBackwardNavigation: true } });
        } else {
          <CustomToast type="error" message={errMessage} />;
          // toast.error(errMessage);
        }
        dispatch(handleGlobalLoader(false));
      }
    });
  };

  const renderErrorMessage = (field: keyof FormValues) => {
    return errors[field] ? (
      <FieldErrorMessage message={errors[field] as string} />
    ) : null;
  };

  return (
    <div className="h-full initialLoginPage">
      <div className="flex flex-col items-center py-6 px-4 h-1/2 justify-around">
        <div className="flex flex-col gap-3 items-center">
          {renderErrorMessage("otpPin")}
          <div className="flex flex-row gap-2 items-center">
            <img src={otpIcon} alt="otpIcon" />
            <span className="text-sm font-[500]">
              Please Enter OTP sent by SMS to{" "}
              <span className="font-bold">{mobile}</span>
            </span>
          </div>
          <div className="flex flex-row gap-2 mb-4">
            {otp.map((data, index) => (
              <input
                key={index}
                type="tel"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={1}
                name="otpPin"
                value={data}
                placeholder="0"
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => {
                  // Only allow numeric values
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  const newEvent = {
                    target: {
                      ...e.target,
                      value: numericValue,
                    },
                  };
                  handleChange(newEvent.target, index);
                }}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onKeyPress={(event) => {
                  // Prevent non-numeric key presses
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className="w-full h-12 border-2 rounded text-center text-md otpInput"
              />
            ))}
          </div>
        </div>
        <div className="flex justify-between w-full mb-4">
          <button
            className={`defaultTextColor flex flex-row gap-2 items-center ${
              resendTime === 0
                ? "font-[500] cursor-pointer"
                : "cursor-not-allowed opacity-70"
            }`}
            onClick={resendTime === 0 ? resendOtp : undefined}
            disabled={resendTime > 0}
          >
            <Cached className="w-5 h-5 defaultTextColor" />
            {resendTime > 0 ? `Resend OTP in ${resendTime}s` : "Resend OTP"}
          </button>
          <button
            onClick={() =>
              navigate("/", {
                state: { preventBackwardNavigation: true },
              })
            }
            className="defaultTextColor flex flex-row gap-2 items-center"
          >
            <ChangeNo className="w-4 h-4 defaultTextColor" />
            Change Mobile No.?
          </button>
        </div>
        <button
          onClick={handleSubmit}
          disabled={otp?.join("")?.length < 4}
          className="customerFormSubmitButton"
        >
          Submit
        </button>
      </div>
      {renderError()}
      {renderSuccess()}
    </div>
  );
};

export default OtpVerificationPage;
