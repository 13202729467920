import { orderTabItems } from "central-utils/tabUtils";
import CustomToast from "components/CustomToast";
import { useOrder } from "context/order/OrderContext";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleFooterHeight } from "store/footer/footerSlice";
import { useConfirmOrderGroupByOrderGroupIdMutation } from "store/orders/ordersAPISlice";
import { handleTabChange } from "store/tab/tabSlice";
import { IObject } from "types";
interface Props {
  orderGroupData: IObject;
}

const ConfirmOrderSlider: React.FC<Props> = ({ orderGroupData }) => {
  const { refetchOrderData, orderIsUninitialized } = useOrder();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [confirmOrder] = useConfirmOrderGroupByOrderGroupIdMutation();

  const [isSliding, setIsSliding] = useState(false);
  const [slideProgress, setSlideProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [callConfirmOrderAPI, setCallConfirmOrderAPI] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [tableVerification, setTableVerification] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  useEffect(() => {
    const lsTableVerification = sessionStorage.getItem("TableVerification");
    lsTableVerification === "NOT_VERIFIED" && setTableVerification(false);
    lsTableVerification === "VERIFIED" && setTableVerification(true);
  }, []);

  const initialAPICallSuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );

  const [confirmOrderErrorData, setConfirmOrderErrorData] =
    useState<IObject | null>(null);

  const handleConfirmOrderGroupAPI = useCallback(async () => {
    if (
      !(
        initialAPICallSuccess &&
        callConfirmOrderAPI &&
        tableVerification &&
        orderGroupData?.status === "NEW"
      )
    ) {
      console.error("Something went wrong in the Call Condition");
      return;
    }

    dispatch(handleGlobalLoader(true));

    confirmOrder(orderGroupData?.id)
      .then((res: any) => {
        dispatch(handleGlobalLoader(false));
        if (res?.data) {
          setShowConfirmationMessage(true);
          sessionStorage.setItem("activeOrderHeaderTab", JSON.stringify(orderTabItems[1].id));
          dispatch(
            handleTabChange({
              orderPageHeaderTabs: {
                ...orderTabItems[1],
                icon: undefined,
                disabled: false,
              },
            })
          );
        } else {
          const errMessage =
            res?.error?.data?.message ??
            res?.error?.error ??
            res?.error?.data?.error ??
            "Something went wrong while updating the quantity. Please try again!";
          console.error("Fetch error: ", errMessage);
          setConfirmOrderErrorData({ error: errMessage });
          setToastMessage("Oops! There was an error. Please try again.");
        }
      })
  }, [callConfirmOrderAPI, confirmOrder, dispatch, initialAPICallSuccess, orderGroupData?.id, orderGroupData?.status, tableVerification]);


  useEffect(() => {
    if (callConfirmOrderAPI) {
      handleConfirmOrderGroupAPI();
    }
  }, [callConfirmOrderAPI, handleConfirmOrderGroupAPI]);

  useEffect(() => {
    if (confirmOrderErrorData) {
      setSlideProgress(0);
      setCompleted(false);
      setCallConfirmOrderAPI(false);
      dispatch(handleGlobalLoader(false));
      !orderIsUninitialized && refetchOrderData();
      const errMessage =
        confirmOrderErrorData?.data?.message ??
        confirmOrderErrorData?.error?.data?.message ??
        confirmOrderErrorData?.error?.error ??
        confirmOrderErrorData?.error?.data?.error ??
        "Something went wrong while confirming order. Please try again!";
      dispatch(handleFooterHeight(160));
      setToastMessage(errMessage);
      errMessage ===
        "Order is not in NEW status. Cannot add or update items." &&
        navigate("/payment-in-progress-block");
      errMessage === `OrderGroup with ID ${orderGroupData?.id} is already in progress.` &&
        window.location.reload();
    }
  }, [orderIsUninitialized, dispatch, refetchOrderData, confirmOrderErrorData, navigate]);

  const handleStart = () => {
    setIsSliding(true);
  };

  const handleMove = (
    clientX: number,
    sliderWidth: number,
    offsetLeft: number
  ) => {
    if (isSliding && !completed) {
      const newSlideProgress = Math.min(
        Math.max(0, clientX - offsetLeft),
        sliderWidth
      );
      setSlideProgress(newSlideProgress);
    }
  };

  const handleEnd = async () => {
    setIsSliding(false);
    const sliderWidth =
      document.querySelector(".slider-container")?.clientWidth || 0;
    const threshold = sliderWidth * 0.35; // 35% of the slider width

    if (slideProgress >= threshold) {
      // Auto-complete the slide to the end
      setSlideProgress(sliderWidth - 62); // 50px for the button size
      setCompleted(true);
      setCallConfirmOrderAPI(true);
    } else {
      // Reset if the slide is not fully completed
      setSlideProgress(0);
    }
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  if (tableVerification) {
    return (
      <div className="confirmOrderSlider">
        <div className="relative mainWrapper flex items-center">
          <div
            className="slider-container p-2 flex items-center justify-between sliderContainer relative cursor-pointer overflow-hidden w-full mx-[15px]"
            onMouseMove={(e) =>
              handleMove(
                e.clientX,
                e.currentTarget.clientWidth,
                e.currentTarget.getBoundingClientRect().left
              )
            }
            onMouseUp={handleEnd}
            onTouchMove={(e) =>
              handleMove(
                e.touches[0].clientX,
                e.currentTarget.clientWidth,
                e.currentTarget.getBoundingClientRect().left
              )
            }
            onTouchEnd={handleEnd}
          >
            <div
              className="slider-progress absolute sliderInProgress"
              style={{ width: `${slideProgress + 62}px` }}
            />
            <span className="w-full text-center relative z-10 pl-5">
              {showConfirmationMessage
                ? "Order Confirmed!"
                : "Slide to Confirm & Place Your Order"}
            </span>
            <div
              className="sliderHandle flex items-center justify-center cursor-pointer absolute z-20"
              style={{ transform: `translateX(${slideProgress}px)` }}
              onMouseDown={handleStart}
              onTouchStart={handleStart}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="rightCaretIcon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
        </div>
        {renderToast()}
      </div>
    );
  } else {
    return null;
  }
};

export default ConfirmOrderSlider;
