import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IObject } from "types";

interface FloatingButtonState {
  menuItemClicked: IObject;
}

const initialState: FloatingButtonState = {
  menuItemClicked: {},
};

const floatingButtonSlice = createSlice({
  name: "floatingButton",
  initialState,
  reducers: {
    handleMenuItemClicked: (state, action: PayloadAction<IObject>) => {
      state.menuItemClicked = action.payload;
    },
    resetFloatingMenuClick: (state) => {
      state.menuItemClicked = {};
    }
  },
});

export const { handleMenuItemClicked, resetFloatingMenuClick } = floatingButtonSlice.actions;
export default floatingButtonSlice.reducer;
