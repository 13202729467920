import QRCode from 'qrcode.react';
import React from 'react';

interface QRCodeGeneratorProps {
    valueToBeEncoded: any;
    qrCodeSize?: number;
    fgColor?: string; // QR Code color (foreground)
    bgColor?: string; // Background color of QR Code container
    borderRadius?: number; // Border radius for rounded corners
    padding?: number; // Padding around the QR Code
    renderAs?: 'canvas' | 'svg'; // Option to render as SVG or canvas
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({
    valueToBeEncoded,
    qrCodeSize = 150,
    fgColor = "black", // Default QR Code color (Black)
    bgColor = "white", // Default background color (White)
    borderRadius = 0, // Default border radius
    padding = 0, // Default padding
    renderAs = "canvas" // Default rendering method as Canvas
}) => {
    const valueString = String(valueToBeEncoded); // Ensure the value is a string for QRCode
    return (
        <div
            style={{
                backgroundColor: bgColor,
                padding: padding,
                display: 'flex', // Use flex for centering
                justifyContent: 'center', // Align QR code in center horizontally
                alignItems: 'center', // Align QR code in center vertically
                borderRadius: borderRadius,
            }}
        >
            <QRCode
                value={valueString}
                size={qrCodeSize}
                fgColor={fgColor}
                bgColor={bgColor}
                renderAs={renderAs} // Can render as 'canvas' or 'svg'
                style={{ borderRadius: borderRadius }}
            />
        </div>
    );
};

export default QRCodeGenerator;