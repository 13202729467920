import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "store/api/apiSlice";
import authReducer from "store/auth/authSlice";
import checkoutSlice from "store/checkout/checkoutSlice";
import floatingButtonSlice from "store/floatingButton/floatingButtonSlice";
import footerSlice from "store/footer/footerSlice";
import globalSearchAndFiltersSlice from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";
import headerSlice from "store/header/headerSlice";
import menuCustomizationSlice from "store/menuCustomization/menuCustomizationSlice";
import tabSlice from "store/tab/tabSlice";
import tableStatusAndOrderSlice from "store/tableStatusAndOrder/tableStatusAndOrderSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    checkout: checkoutSlice,
    floatingButton: floatingButtonSlice,
    footer: footerSlice,
    globalSearchAndFilters: globalSearchAndFiltersSlice,
    header: headerSlice,
    menuCustomization: menuCustomizationSlice,
    tab: tabSlice,
    tableStatusAndOrder: tableStatusAndOrderSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
