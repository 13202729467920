import { apiSlice } from "store/api/apiSlice";

export const homePageAPISlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupPromotionByBranchId: builder.query({
      query: ({ branchId }) => ({
        url: `/groupedPromotion/all?page=0&size=100&filters=branches.id:${branchId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getSearchResultsByBranchId: builder.query({
      query: ({
        branchId,
        searchText,
      }: {
        branchId: number;
        searchText: string;
      }) => ({
        url: `/menuItemMaster/menuItemMaster/fuzzySearch?branchId=${branchId}&searchText=${searchText}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetSearchResultsByBranchIdQuery,
  useGetGroupPromotionByBranchIdQuery,
} = homePageAPISlice;
