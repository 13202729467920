import React from "react";
import { ReactComponent as ErrIcon } from "assets/images/field-error.svg";
interface Props {
  message: string;
}

const FieldErrorMessage: React.FC<Props> = ({ message }) => {
  return (
    <div className="flex justify-start items-center mt-[3px] fieldErrorMessage">
      <ErrIcon className="mr-[5px] fieldErrIcon" />
      <div className="fieldErrMessage">{message}</div>
    </div>
  );
};

export default FieldErrorMessage;
