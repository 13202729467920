import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import downArrow from "assets/images/down-arrow.svg";
import upArrow from "assets/images/up-arrow.svg";
import { rupeeFormatter } from 'central-utils/currencyUtils';
import { handleFoodTypeImage } from 'central-utils/foodTypeImageUtils';
import { convertToCapitalizedString } from 'central-utils/stringUtils';
import CustomToast from 'components/CustomToast';
import React, { useEffect, useState } from 'react';
import { IObject } from 'types';

interface Props {
    confirmedOrderGroupData: {
        tabContent: IObject[] | null,
        orderGroupHelperData: IObject[] | null
    };
}

const CompletedTabContent: React.FC<Props> = ({ confirmedOrderGroupData }) => {
    const { tabContent } = confirmedOrderGroupData;

    const [updatedTabItems, setUpdatedTabItems] = useState<IObject[]>([]);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [accordionStates, setAccordionStates] = useState<Record<number, boolean>>({});

    // Initialize the accordion states based on the `isAccordionOpen` flag
    useEffect(() => {
        if (tabContent) {
            // Update tab items with the new content
            setUpdatedTabItems(tabContent);

            // Initialize or update accordion states
            setAccordionStates((prevState) => {
                const newAccordionStates = { ...prevState };

                tabContent.forEach((item: any) => {
                    // If the accordion's `isAccordionOpen` is true in the new data, open it in state
                    if (item.isAccordionOpen && !prevState[item.id]) {
                        newAccordionStates[item.id] = true;
                    } else if (prevState[item.id] === undefined) {
                        // Otherwise, initialize it based on `isAccordionOpen` or false by default
                        newAccordionStates[item.id] = item.isAccordionOpen || false;
                    }
                });

                return newAccordionStates;
            });
        }
    }, [tabContent]);

    // NEW, IN_PROGRESS, DELIVERED,PREPARED,PICKED_UP,CANCELLED
    const handleOrderItemStatusIcon = (status: "IN_PROGRESS" | "PICKED_UP" | "PREPARED" | "DELIVERED") => {
        switch (status) {
            case "DELIVERED":
                return <CheckCircleOutlined className="orderItemStatusIcon delivered" />;

            default:
                return <ClockCircleOutlined className="orderItemStatusIcon default" />;;
        }
    }

    const handleAccordionToggle = (id: number) => {
        setAccordionStates((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleOnToastDismiss = () => {
        setToastMessage(null);
    };

    const renderToast = () => {
        if (toastMessage) {
            return (
                <CustomToast
                    message={toastMessage}
                    handleOnToastDismiss={handleOnToastDismiss}
                />
            );
        }
        return null;
    };

    if (!updatedTabItems?.length) {
        return null;
    }

    return (
        <div className='mt-[10px] completedTab'>
            {updatedTabItems?.map((accordion: any) => {
                const isOpen = accordionStates[accordion?.id];
                const { orderItems } = accordion;

                return (
                    <div key={accordion?.id} className='secondLevelCategoryAccordion w-full mb-[10px]'>
                        <div
                            onClick={() => handleAccordionToggle(accordion?.id)}
                            className='flex justify-between items-center py-2 px-4 w-full mainAccordionHeader gap-3 cursor-pointer'
                        >
                            <div className='flex'>
                                <img src={isOpen ? upArrow : downArrow} alt={isOpen ? "upArrow" : "downArrow"} />
                                <div className={`pl-2 mainAccordionTitle ${isOpen ? "accordionOpen" : "accordionClose"}`}>
                                    {accordion?.accordionHeader}
                                </div>
                            </div>
                            <div className='statusTag'>
                                <span className={`p-[3px] ${accordion?.orderGroupStatus === "IN_PROGRESS"
                                    ? 'statusProgress'
                                    : 'statusDelivered'
                                    }`}>
                                    {accordion?.orderGroupStatus === "IN_PROGRESS" ? 'IN PROGRESS' : 'DELIVERED'}
                                </span>
                            </div>
                        </div>
                        {isOpen && (
                            <div className="mt-1 accordionContent">
                                {orderItems?.map((orderItem: any) => (
                                    <div key={orderItem?.id} className='orderItem'>
                                        <div className='orderCategory py-[10px] ml-[10px]'>
                                            {convertToCapitalizedString(orderItem?.categoryName)}
                                        </div>
                                        <div className='orderDetails'>
                                            {orderItem?.orderItems?.map((item: any) => {
                                                const { id, foodType, displayName, servingSize, description, status, totalPrice, quantity } = item;
                                                const foodTypeImage = handleFoodTypeImage(foodType);

                                                return (
                                                    <div key={id} className='flex justify-between items-center py-[15px] itemRow'>
                                                        <div className='w-1/2 ml-[10px] flex items-center'>
                                                            {status ? (
                                                                <div className='mr-[10px]'>
                                                                    {handleOrderItemStatusIcon(status)}
                                                                </div>
                                                            ) : null}
                                                            <div className='flex flex-col items-start'>
                                                                <div className='flex items-center'>
                                                                    {foodTypeImage ? (
                                                                        <img
                                                                            className='foodTypeIcon mr-[10px]'
                                                                            src={foodTypeImage}
                                                                            alt={foodType || 'Food type image'}
                                                                        />
                                                                    ) : null}
                                                                    <span>
                                                                        {convertToCapitalizedString(displayName)}
                                                                        <span className='whitespace-nowrap'>{` (${servingSize})`}</span>
                                                                    </span>
                                                                </div>
                                                                {description && (
                                                                    <p className={`itemDescription break-words mt-1 ${foodTypeImage ? "ml-[20px]" : ""
                                                                        }`}>
                                                                        {convertToCapitalizedString(description)}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center'>
                                                            <div>{quantity}</div>
                                                            <div className='itemPrice ml-[10px] text-left break-words'>
                                                                {rupeeFormatter(totalPrice)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
            {renderToast()}
        </div>
    );
};

export default CompletedTabContent;