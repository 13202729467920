import { useOrder } from 'context/order/OrderContext';
import { ReactComponent as DishIcon } from 'assets/images/bill-pending.svg'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePreventBackNavigation from 'hooks/usePreventBackNavigation';

const BillPendingPage: React.FC = () => {
    usePreventBackNavigation("/bill-pending");

    const navigate = useNavigate();
    const { orderData } = useOrder();

    return (
        <div className="flex flex-col items-center justify-center h-full pageInfoMessage text-center mx-10">
            <DishIcon className='dishIcon' />
            <div className='py-[30px] pageInfoHeader'>
                Bill Pending
            </div>
            <div className='pb-[30px]'>
                {`Hold onto your wallet until ${(orderData && orderData.orderGroups && orderData.orderGroups.length > 0) ? "you place the order and" : ""} all your items hit the table—then it’s time to settle up!`}
            </div>
            <button
                onClick={() => navigate("/order")}
                className="downloadInvoiceButton text-center mt-[10px]"
            >
                Back
            </button>
        </div>
    )
}

export default BillPendingPage;