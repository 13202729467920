import { usePusher } from 'context/pusher';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IObject, PusherConnectionState } from 'types';

interface Props {
    children: ReactNode;
}

const TableVerificationContext = createContext<IObject | null>(null);

const TableVerificationChannelProvider: React.FC<Props> = ({ children }) => {
    const location = useLocation();

    const ids = sessionStorage.getItem("Ids");
    const parsedIds: { brandId: string, branchId: string, tableId: string } | null = ids ? JSON.parse(ids) : null;

    const pusher = usePusher();
    const [verificationData, setVerificationData] = useState<IObject | null>(null);

    useEffect(() => {
        if (pusher && parsedIds) {
            const channelName = `tenant-${parsedIds.brandId}-branch-${parsedIds.branchId}-table-${parsedIds.tableId}-customerPusherChannel`;
            const channel = pusher.subscribe(channelName);

            console.log('[table verification] pusher - customerPusherEvent is the event - channelName :>> ', channelName);

            // Check if Pusher is connected
            const logConnectionState = () => {
                console.log(`[table verification] Pusher connection state: ${pusher.connection.state}`);
            };

            // Log initial connection state
            logConnectionState();

            // Bind connection state change events to log with proper typing
            pusher.connection.bind('state_change', ({ previous, current }: { previous: PusherConnectionState; current: PusherConnectionState }) => {
                console.log(`[table verification] Pusher connection state changed from ${previous} to ${current}`);
            });

            // Bind to the 'connected' event for successful connection
            pusher.connection.bind('connected', () => {
                console.log('[table verification] Pusher connected successfully');
            });

            // Bind to the 'disconnected' event for connection lost
            pusher.connection.bind('disconnected', () => {
                console.log('[table verification] Pusher disconnected');
            });

            channel.bind('customerPusherEvent', (data: any) => {
                try {
                    const parsedVerificationData = JSON.parse(data);
                    console.log(`${new Date()} - [table verification] parsedVerificationData pusher data :>> `, data, parsedVerificationData);
                    if (parsedVerificationData && Object.keys(parsedVerificationData)?.length > 0) {
                        setVerificationData(parsedVerificationData);
                    }
                } catch (error) {
                    console.error('[table verification] Error parsing cart data:', error, 'Raw data:', data);
                }
            });

            channel.bind('pusher:subscription_error', (status: any) => {
                console.error(`[table verification] Failed to subscribe to channel ${channelName}:`, status);
            });

            return () => {
                // console.log(`[table verification] Unsubscribing from Pusher channel: ${channelName}`);
                // channel.unbind_all();
                // pusher.unsubscribe(channelName);
            };
        }
    }, [pusher, parsedIds]);

    useEffect(() => {
        if (verificationData && Object.keys(verificationData)?.length > 0) {
            sessionStorage.setItem("TableVerification", "VERIFIED");
        }
    }, [verificationData]);

    useEffect(() => {
        setTimeout(() => {
            setVerificationData(null);
        }, 2000);
    }, [location]);


    return (
        <TableVerificationContext.Provider value={verificationData}>
            {children}
        </TableVerificationContext.Provider>
    );
};

const useTableVerificationPusherChannel = (): IObject | null => {
    const context = useContext(TableVerificationContext);
    // if (context === null) {
    //     throw new Error('useTableVerificationPusherChannel must be used within a TableVerificationChannelProvider');
    // }
    return context;
};

export default TableVerificationChannelProvider;
export { useTableVerificationPusherChannel };
