import { apiSlice } from "store/api/apiSlice";

export const checkoutAPISlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderBillingByOrderId: builder.query({
      query: ({ orderId, customerId }) => ({
        url: `/order/orderBilling?orderId=${orderId}&customerId=${customerId}&sendEvent=true`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    createPaymentSession: builder.mutation({
      query: (orderDetailsBody) => ({
        url: "/order/payment/payment-session",
        method: "POST",
        body: orderDetailsBody,
      }),
    }),
    getPaymentStatusByOrderId: builder.query({
      query: ({ orderId }) => ({
        url: `/order/payment/payment-status?orderId=${orderId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    saveBillingResponse: builder.mutation({
      query: ({ billDetailsBody }) => ({
        url: `/order/saveBillingResponse`,
        method: "PUT",
        body: billDetailsBody,
      }),
    }),
    requestBill: builder.mutation({
      query: ({ orderId }) => ({
        url: `/order/update-order-request-bill?orderId=${orderId}&orderStatus=REQUEST_BILL`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetOrderBillingByOrderIdQuery,
  useCreatePaymentSessionMutation,
  useGetPaymentStatusByOrderIdQuery,
  useSaveBillingResponseMutation,
  useRequestBillMutation,
} = checkoutAPISlice;
