import { apiSlice } from "store/api/apiSlice";

export const ordersAPISlice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addOrder: builder.mutation({
      query: (orderBody) => ({
        url: "/order",
        method: "POST",
        body: orderBody,
      }),
    }),
    addCustomerToOrderByOrderId: builder.mutation({
      query: ({ orderId, customerDetailsBody }) => ({
        url: `/order/${orderId}/add-customer`,
        method: "POST",
        body: customerDetailsBody,
      }),
    }),
    createNewCustomerOrder: builder.mutation({
      query: (createCustomerBody) => ({
        url: `/customer-app/new-customer-order`,
        method: "POST",
        body: createCustomerBody,
      }),
    }),
    verifyTable: builder.mutation({
      query: ({ orderId, tableCode }) => ({
        url: `/customer-app/verify-table?orderId=${orderId}`,
        method: "POST",
        body: tableCode,
      }),
    }),
    updateOrderItemQuantityByProductId: builder.mutation({
      query: ({ orderItemId, price, quantity }) => {
        const url =
          price !== null && price !== undefined && price !== 0
            ? `/order-items/update-quantity?id=${orderItemId}&price=${price}&quantity=${quantity}`
            : `/order-items/update-quantity?id=${orderItemId}&price=0&quantity=${quantity}`;

        return {
          url,
          method: "PUT",
        };
      },
    }),
    getPreviousCustomizationsByProductMasterIdAndOrderId: builder.query({
      query: ({ orderId, productMasterId }) => ({
        url: `/order-items/productMaster-orderItems?orderId=${orderId}&productMasterId=${productMasterId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    addMenuItemToOrderByOrderId: builder.mutation({
      query: ({ orderId, menuItemsBody }) => ({
        url: `/order/addOrderItems?orderId=${orderId}`,
        method: "POST",
        body: menuItemsBody,
      }),
    }),
    updateOrderItemInOrderByOrderItemId: builder.mutation({
      query: ({ orderItemId, orderItemBody }) => ({
        url: `/order/addOrderItems?orderItemId=${orderItemId}`,
        method: "POST",
        body: orderItemBody,
      }),
    }),
    confirmOrderGroupByOrderGroupId: builder.mutation({
      query: (orderGroupId) => ({
        url: `/order/confirmation?orderGroupId=${orderGroupId}`,
        method: "PUT",
      }),
    }),
    getOrderById: builder.query({
      query: ({ id }) => ({
        url: `/order?id=${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getMenuItemsForReorderByOrderId: builder.query({
      query: ({ orderId }) => ({
        url: `/order/reOrder?orderId=${orderId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useAddOrderMutation,
  useAddCustomerToOrderByOrderIdMutation,
  useCreateNewCustomerOrderMutation,
  useVerifyTableMutation,
  useUpdateOrderItemQuantityByProductIdMutation,
  useGetPreviousCustomizationsByProductMasterIdAndOrderIdQuery,
  useAddMenuItemToOrderByOrderIdMutation,
  useUpdateOrderItemInOrderByOrderItemIdMutation,
  useConfirmOrderGroupByOrderGroupIdMutation,
  useGetOrderByIdQuery,
  useGetMenuItemsForReorderByOrderIdQuery,
} = ordersAPISlice;
