import { IObject } from "types";

export const handleOrderGroupStatus = (
  pusherCartData: IObject[] | null,
  pusherOrderGroupStatusData: IObject
) => {
  console.log("pusherCartData [order group status func]:>> ", pusherCartData);
  console.log(
    "pusherConfirmOrderGroupData [order group status func]:>> ",
    pusherOrderGroupStatusData
  );

  if (pusherCartData && pusherCartData?.length) {
    const updatedOrderGroupData = pusherCartData.map((order) =>
      order.id === pusherOrderGroupStatusData?.orderGroupId
        ? {
            ...order,
            status: "COMPLETED", // update the main order status
          }
        : order
    );

    return updatedOrderGroupData;
  }

  return [];
};
