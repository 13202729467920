import { ReactComponent as ReceiptIcon } from "assets/images/receipt.svg";
import { rupeeFormatter } from "central-utils/currencyUtils";
import {
  convertToCapitalizedString,
  taxChargesFormat,
} from "central-utils/stringUtils";
import CustomLoader from "components/common/CustomLoader";
import CustomToast from "components/CustomToast";
import usePreventBackNavigation from "hooks/usePreventBackNavigation";
import React, { useEffect, useState } from "react";
import {
  useGetOrderBillingByOrderIdQuery,
  useRequestBillMutation,
} from "store/checkout/checkoutAPISlice";
import { handleBillDetails } from "store/checkout/checkoutSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { IObject } from "types";

const CheckoutPage: React.FC = () => {
  const initialAPICallSuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );
  const initialOrderDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.orderDetails
  );

  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const orderId = sessionStorage.getItem("OrderId");
  const customerDetails = JSON.parse(
    sessionStorage.getItem("CustomerDetails") || "{}"
  );
  usePreventBackNavigation("/check-out");

  const dispatch = useAppDispatch();

  const [requestBill] = useRequestBillMutation();

  const {
    data: orderBillData,
    isLoading: orderBillIsLoading,
    isError: orderBillIsError,
    isSuccess: orderBillIsSuccess,
    isFetching: orderBillIsFetching,
    isUninitialized: orderBillIsUninitialized,
  } = useGetOrderBillingByOrderIdQuery(
    {
      orderId: initialOrderDetails?.id ?? orderId,
      customerId: customerDetails?.id,
    },
    { skip: !initialAPICallSuccess }
  );

  useEffect(() => {
    if (orderBillIsError) {
      setToastMessage(
        "Something went wrong while fetching bill data. Please try again!"
      );
    }
  }, [orderBillIsError]);

  useEffect(() => {
    if (
      (orderId || initialOrderDetails?.id) &&
      customerDetails?.id &&
      orderBillData &&
      orderBillIsSuccess
    ) {
      requestBill({ orderId: orderId || initialOrderDetails?.id }).then(
        (res: any) => {
          if (res?.data) {
            console.log("[req bill] res?.data :>> ", res?.data);
          } else {
            const errMessage =
              res?.error?.data?.message ??
              res?.error?.error ??
              res?.error?.data?.error ??
              "Something went wrong. Please try again!";
            setToastMessage(errMessage);
          }
        }
      );
    }
  }, [
    customerDetails?.id,
    initialOrderDetails?.id,
    orderBillData,
    orderBillIsSuccess,
    orderId,
    requestBill,
  ]);

  const handleCheckoutTableData = (data: any) => {
    return data?.map((item: any) => {
      const servingSize = item?.servingSizeName?.length
        ? `${" "}(${item?.servingSizeName})`
        : "";
      return {
        name: convertToCapitalizedString(item?.itemName) + servingSize,
        quantity: item?.quantity,
        amount: item?.price,
      };
    });
  };
  const transformOrderBillAPIAddData = (data: any) => {
    let charges =
      data?.charges?.map((obj: any) => ({
        name: convertToCapitalizedString(obj?.charges?.name),
        percentage: obj?.rate,
        amount: obj?.amount,
      })) ?? [];

    let tax =
      data?.taxSummary?.map((obj: any) => ({
        name: taxChargesFormat(obj?.tax?.name),
        percentage: obj?.rate,
        amount: obj?.amount,
      })) ?? [];

    const finalData =
      data?.roundOff > 0
        ? [
            ...charges,
            ...tax,
            { name: "Round Off", percentage: 0, amount: data?.roundOff },
          ]
        : [...charges, ...tax];

    return finalData;
  };

  const [convertedAPIData, setConvertedAPIData] = useState<IObject[] | null>(
    null
  );
  const [chargesAdded, setChargesAdded] = useState<IObject[] | null>(null);
  const [chargesNegated, setChargesNegated] = useState<IObject[] | null>(null);

  useEffect(() => {
    setChargesAdded([]);
    setChargesNegated([]);
    if (orderBillData && Object.keys(orderBillData).length > 0) {
      const storeBillInfo = {
        billNo: orderBillData?.orderId,
        charges: orderBillData?.charges,
        payableAmount: orderBillData?.payableAmount,
        roundoff: orderBillData?.roundoff,
        subtotal: orderBillData?.subtotal,
        taxSummary: orderBillData?.taxSummary,
        totalTax: orderBillData?.totalTax,
        totalCharge: orderBillData?.totalCharge,
      };
      sessionStorage.setItem("billInfo", JSON.stringify(storeBillInfo));
      dispatch(handleBillDetails(orderBillData));
      const convertedData = handleCheckoutTableData(orderBillData?.orderItems);
      setConvertedAPIData(convertedData);
      const addedData = transformOrderBillAPIAddData(orderBillData);
      setChargesAdded(addedData);
    }
  }, [dispatch, orderBillData]);

  useEffect(() => {
    if (
      !(
        orderBillIsLoading &&
        orderBillIsFetching &&
        !orderBillIsUninitialized
      ) &&
      orderBillData &&
      convertedAPIData &&
      orderBillIsSuccess &&
      convertedAPIData &&
      convertedAPIData?.length === 0
    ) {
      setToastMessage("No orders placed !");
    }
  }, [
    convertedAPIData,
    orderBillData,
    orderBillIsFetching,
    orderBillIsLoading,
    orderBillIsSuccess,
    orderBillIsUninitialized,
  ]);

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  return (
    <>
      {(orderBillIsLoading || orderBillIsFetching) && (
        <CustomLoader isLoading={orderBillIsLoading || orderBillIsFetching} />
      )}
      {orderBillIsSuccess && orderBillData ? (
        <div className="checkoutBillDetails">
          <div className="paymentContainer mt-[10px] p-4">
            <div className="receiptIcon">
              <ReceiptIcon className="iconColor" />
            </div>
            <div className="waitMessageTitle my-[5px]">Please Wait ...</div>
            <div className="waitMessageText my-[5px]">
              The waiter will arrive shortly and process your payment
            </div>
            <div className="flex justify-between items-center my-[15px] totalPayableSummary">
              <div className="text-left">Total Payable</div>
              <div className="text-right">
                {rupeeFormatter(orderBillData?.payableAmount)}
              </div>
            </div>
          </div>
          <div className="billDetailsTitle my-[20px]">
            Bill Details & Summary
          </div>
          <div className="max-w-4xl billTableContainer">
            <table className="billTable">
              <thead>
                <tr className="headerRow text-left px-4">
                  <th className="py-2">Item</th>
                  <th className="py-2 text-center">Qty</th>
                  <th className="py-2 text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {/** Main Data Rows */}
                {convertedAPIData && convertedAPIData?.length
                  ? convertedAPIData?.map((row: any, index: number) => (
                      <tr
                        key={index}
                        className={`dataRow ${
                          index % 2 === 0 ? "oddRow" : "evenRow"
                        }`}
                      >
                        <td className="py-2">{row.name}</td>
                        <td className="py-2 text-center">{row.quantity}</td>
                        <td className="py-2 text-right">
                          {rupeeFormatter(row.amount)}
                        </td>
                      </tr>
                    ))
                  : null}

                {orderBillData?.discountAmount !== null &&
                  orderBillData?.discountAmount > 0 && (
                    <tr
                      key={orderBillData?.discountAmount}
                      className="discountRow"
                    >
                      <td className="py-2" colSpan={2}>
                        Discount
                      </td>
                      <td className="py-2  text-right">
                        {rupeeFormatter(orderBillData?.discountAmount)}
                      </td>
                    </tr>
                  )}
                {orderBillData?.subTotal !== null &&
                  orderBillData?.subTotal > 0 && (
                    <tr
                      key={orderBillData?.subTotal}
                      className={`subtotalRow ${
                        orderBillData?.discountAmount ? "" : "noDiscountRow"
                      }`}
                    >
                      <td className="py-2" colSpan={2}>
                        Sub Total
                      </td>
                      <td className="py-2  text-right">
                        {rupeeFormatter(orderBillData?.subTotal)}
                      </td>
                    </tr>
                  )}

                {/* Charges Added Rows */}
                {chargesAdded && chargesAdded?.length
                  ? chargesAdded?.map((row: any, index: number) => (
                      <tr
                        key={index}
                        className={`chargesAddedRow
                                ${
                                  index === 0 ? "chargesAddedRowFirstChild" : ""
                                }`}
                      >
                        <td className="py-2 text-left" colSpan={2}>
                          {row?.name}{" "}
                          {row?.percentage !== 0 ? `(${row?.percentage}%)` : ""}
                        </td>
                        <td className="py-2 text-right">
                          {rupeeFormatter(row?.amount)}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
              <tfoot>
                <tr className="totalRow">
                  <td className="py-2" colSpan={2}>
                    Total Payable
                  </td>
                  <td className="py-2 text-right">
                    {rupeeFormatter(orderBillData?.payableAmount)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      ) : null}
      {renderToast()}
    </>
  );
};

export default CheckoutPage;
