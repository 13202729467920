import { usePusher } from 'context/pusher';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/customHooks';
import { IObject } from 'types';

interface Props {
    children: ReactNode;
}

const VoidOrderContext = createContext<IObject | null>(null);

const VoidOrderChannelProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { orderDetails } = useAppSelector((state) => state.tableStatusAndOrder);

    const orderId = sessionStorage.getItem("OrderId");
    const ids = sessionStorage.getItem("Ids");
    const parsedIds: { brandId: string, branchId: string, tableId: string } | null = ids ? JSON.parse(ids) : null;

    const pusher = usePusher();
    const [voidOrderData, setVoidOrderData] = useState<IObject | null>(null);

    useEffect(() => {
        const orderIdCheck = orderId || orderDetails?.id;

        if (pusher && parsedIds && orderIdCheck) {
            const channelName = `tenant-${parsedIds.brandId}-branch-${parsedIds.branchId}-table-${parsedIds.tableId}-customerPusherChannel`;
            const channel = pusher.subscribe(channelName);

            console.log('[void order] pusher - customerCheckoutPusherEvent is the event - channelName :>> ', channelName);

            channel.bind('voidEventName', (data: any) => {
                try {
                    const parsedVoidOrderData = JSON.parse(data);
                    console.log(`${new Date()} - [void order] parsedVoidOrderData pusher data :>> `, parsedVoidOrderData);
                    if (parsedVoidOrderData && Object.keys(parsedVoidOrderData)?.length > 0 && String(parsedVoidOrderData?.currentOrderId) === String(orderIdCheck)) {
                        setVoidOrderData(parsedVoidOrderData);
                    }
                } catch (error) {
                    console.error('[void order] Error parsing void order data:', error, 'Raw data:', data);
                }
            });

            channel.bind('pusher:subscription_error', (status: any) => {
                console.error(`[void order] Failed to subscribe to channel ${channelName}:`, status);
            });

            return () => {
                // console.log(`[void order] Unsubscribing from Pusher channel: ${channelName}`);
                // channel.unbind_all();
                // pusher.unsubscribe(channelName);
            };
        }
    }, [pusher, navigate, parsedIds, orderId, orderDetails?.id]);

    useEffect(() => {
        if (voidOrderData) {
            navigate("/payment-in-progress-block", { state: { message: "You cannot access this order anymore!" } });
        }
    }, [voidOrderData, navigate]);

    useEffect(() => {
        setTimeout(() => {
            setVoidOrderData(null);
        }, 2000);
    }, [location]);

    return (
        <VoidOrderContext.Provider value={voidOrderData}>
            {children}
        </VoidOrderContext.Provider>
    );
};

const useVoidOrderPusherChannel = (): IObject | null => {
    const context = useContext(VoidOrderContext);
    // if (context === null) {
    //     throw new Error('useVoidOrderPusherChannel must be used within a VoidOrderChannelProvider');
    // }
    return context;
};

export default VoidOrderChannelProvider;
export { useVoidOrderPusherChannel };

