import { IObject } from "types";

export const handleDeliveredOrderItem = (
  pusherConfirmOrderGroupData: IObject[] | null,
  pusherDeliveredOrderItemData: IObject
) => {
  console.log(
    "pusherCartData [delivered func]:>> ",
    pusherDeliveredOrderItemData
  );
  console.log(
    "pusherConfirmOrderGroupData [delivered func]:>> ",
    pusherConfirmOrderGroupData
  );

  if (pusherConfirmOrderGroupData && pusherConfirmOrderGroupData?.length) {
    const updatedOrderGroupData = pusherConfirmOrderGroupData.map((order) =>
      order.id === pusherDeliveredOrderItemData?.orderGroupId
        ? {
            ...order,
            orderItems: order.orderItems.map((item: any) =>
              item.id === pusherDeliveredOrderItemData?.orderItemId
                ? { ...item, status: "DELIVERED" }
                : item
            ),
          }
        : order
    );

    return updatedOrderGroupData;
  }

  return [];
};
