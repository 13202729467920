import Pusher from 'pusher-js';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import pusherConfig from 'store/pusherConfig';

interface Props {
    children: ReactNode;
}

const PusherContext = createContext<Pusher | null>(null);

const PusherProvider: React.FC<Props> = ({ children }) => {
    const [pusher, setPusher] = useState<Pusher | null>(null);

    useEffect(() => {
        // Pusher.logToConsole = true;

        const pusherInstance = new Pusher(pusherConfig.key, {
            cluster: pusherConfig.cluster,
            forceTLS: true
        });

        setPusher(pusherInstance);

        return () => {
            pusherInstance.disconnect();
        };
    }, []);

    return (
        <PusherContext.Provider value={pusher}>
            {children}
        </PusherContext.Provider>
    );
};

const usePusher = (): Pusher | null => {
    const context = useContext(PusherContext);
    // if (context === null) {
    //     throw new Error('usePusher must be used within a PusherProvider');
    // }
    return context;
};

export default PusherProvider;
export { usePusher };
