import { orderTabItems } from 'central-utils/tabUtils';
import { usePusher } from 'context/pusher';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/customHooks';
import { IObject } from 'types';

interface Props {
    children: ReactNode;
}

const VoidItemContext = createContext<IObject | null>(null);

const VoidItemChannelProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { orderDetails } = useAppSelector((state) => state.tableStatusAndOrder);

    const orderId = sessionStorage.getItem("OrderId");
    const ids = sessionStorage.getItem("Ids");
    const parsedIds: { brandId: string, branchId: string, tableId: string } | null = ids ? JSON.parse(ids) : null;

    const pusher = usePusher();
    const [voidItemData, setVoidItemData] = useState<IObject | null>(null);

    useEffect(() => {
        const orderIdCheck = orderId || orderDetails?.id;
        const activeOrderTab = sessionStorage.getItem("activeOrderHeaderTab") || orderTabItems[0].id;
        if (pusher && parsedIds && orderIdCheck) {
            const channelName = `tenant-${parsedIds.brandId}-branch-${parsedIds.branchId}-table-${parsedIds.tableId}-customerPusherChannel`;
            const channel = pusher.subscribe(channelName);

            console.log('[void item] pusher - customerCheckoutPusherEvent is the event - channelName :>> ', channelName);

            channel.bind('refreshCart', (data: any) => {
                try {
                    const parsedVoidItemData = JSON.parse(data);
                    console.log(`${new Date()} - [void item] parsedVoidItemData pusher data :>> `, parsedVoidItemData);
                    if (parsedVoidItemData && Object.keys(parsedVoidItemData)?.length > 0 && String(parsedVoidItemData?.orderId) === String(orderIdCheck)) {
                        setVoidItemData(parsedVoidItemData);
                        if (location.pathname === "/order" && (Number(activeOrderTab) === orderTabItems[1].id)) {
                            window.location.reload();
                        }
                    }
                } catch (error) {
                    console.error('[void item] Error parsing void item data:', error, 'Raw data:', data);
                }
            });

            channel.bind('pusher:subscription_error', (status: any) => {
                console.error(`[void item] Failed to subscribe to channel ${channelName}:`, status);
            });

            return () => {
                // console.log(`[void item] Unsubscribing from Pusher channel: ${channelName}`);
                // channel.unbind_all();
                // pusher.unsubscribe(channelName);
            };
        }
    }, [pusher, navigate, parsedIds, orderId, orderDetails?.id, location.pathname]);

    useEffect(() => {
        setTimeout(() => {
            setVoidItemData(null);
        }, 2000);
    }, [location]);

    return (
        <VoidItemContext.Provider value={voidItemData}>
            {children}
        </VoidItemContext.Provider>
    );
};

const useVoidItemPusherChannel = (): IObject | null => {
    const context = useContext(VoidItemContext);
    // if (context === null) {
    //     throw new Error('useVoidItemPusherChannel must be used within a VoidItemChannelProvider');
    // }
    return context;
};

export default VoidItemChannelProvider;
export { useVoidItemPusherChannel };

