import { Modal } from "antd";
import errorIcon from "assets/images/error-icon.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/customHooks";

const CheckoutModal = ({ onClose }: any) => {
  const navigate = useNavigate();
  const { isOrderGroupNewStatusAvailable, disableCheckoutButton } = useAppSelector((state) => state.footer);

  const handleCheckout = () => {
    if (disableCheckoutButton) {
      navigate("/bill-pending");
      onClose();
    }
    else {
      if (isOrderGroupNewStatusAvailable) {
        navigate("/payment-in-progress-block",
          {
            state: {
              message: "Please wait, We are clearing your pending items in cart!",
              shouldRunClearHook: true
            }
          })
      } else {
        navigate("/check-out")
      }
    }
  }

  return (
    <Modal
      width={"100vw"}
      closable={false}
      open={true}
      onCancel={onClose}
      footer={null}
      className="repeatCustomizationModal"
      style={{
        padding: 0,
        margin: 0,
        borderRadius: "999px",
        maxWidth: "100vw",
      }}
    >
      <div className="repeatCustomizationModal">
        <div className="repeatCustomizationModalContent">
          <div className="p-5 ">
            <div className="text-2xl font-light">
              Are you sure you’d like to pay the bill and close your table?
            </div>
          </div>
          <div className="w-full repeatCustomizationBodyContent px-5 flex flex-row items-center py-1">
            <img src={errorIcon} alt="errorIcon" className="mr-2" />
            This action cannot be undone.
            {
              (isOrderGroupNewStatusAvailable && !disableCheckoutButton) ?
                " There are unconfirmed items in the cart, do you want to clear pending items?" :
                null
            }
          </div>
          <div className="px-5 flex flex-row items-center gap-3 py-5">
            <button
              onClick={onClose}
              className="w-full text-center repeatCustomizationModalEditCustomizationButton  p-4 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={handleCheckout}
              className="w-full text-center repeatCustomizationModalAddCustomizationButton p-4 rounded-md"
            >
              Yes, Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutModal;
