import { apiSlice } from "store/api/apiSlice";

export const promotionsPageAPISLice: any = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupedPromotions: builder.query({
        query: ({branchId, id }) => ({
          url: `/menuItemMaster/items-with-keywords?branchId=${branchId}&keywords=PROMO-${id}`,
          method: "GET",
        }),
        keepUnusedDataFor: 0,
      }),

  })});

  export const {
    useGetGroupedPromotionsQuery
  } = promotionsPageAPISLice