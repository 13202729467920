import { usePusher } from 'context/pusher';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IObject, PusherConnectionState } from 'types';

interface Props {
    children: ReactNode;
}

const CustomerLogoutContext = createContext<IObject | null>(null);

const CustomerLogoutChannelProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pusher = usePusher();

    const [customerLogoutData, setCustomerLogoutData] = useState<IObject | null>(null);
    const [navigateToLastPage, setNavigateToLastPage] = useState(false);

    const ids = sessionStorage.getItem("Ids");
    const parsedIds: { brandId: string, branchId: string, tableId: string } | null = ids ? JSON.parse(ids) : null;

    useEffect(() => {
        if (pusher && parsedIds) {
            const channelName = `tenant-${parsedIds.brandId}-branch-${parsedIds.branchId}-table-${parsedIds.tableId}-customerPusherChannel`;
            const channel = pusher.subscribe(channelName);

            console.log('[customer logout] pusher - customerLogOutPusherEvent is the event - channelName :>> ', channelName);

            // Check if Pusher is connected
            const logConnectionState = () => {
                console.log(`[customer logout] Pusher connection state: ${pusher.connection.state}`);
            };

            // Log initial connection state
            logConnectionState();

            // Bind connection state change events to log with proper typing
            pusher.connection.bind('state_change', ({ previous, current }: { previous: PusherConnectionState; current: PusherConnectionState }) => {
                console.log(`[customer logout] Pusher connection state changed from ${previous} to ${current}`);
            });

            // Bind to the 'connected' event for successful connection
            pusher.connection.bind('connected', () => {
                console.log('[customer logout] Pusher connected successfully');
            });

            // Bind to the 'disconnected' event for connection lost
            pusher.connection.bind('disconnected', () => {
                console.log('[customer logout] Pusher disconnected');
            });

            channel.bind('customerLogOutPusherEvent', (data: any) => {
                try {
                    console.log('not parsed data [customer logout] log :>> ', data);
                    const parsedCustomerLogoutData = JSON.parse(data);
                    console.log(`${new Date()} - [customer logout] parsedCustomerLogoutData pusher data :>> `, data, parsedCustomerLogoutData);
                    if (parsedCustomerLogoutData && Object.keys(parsedCustomerLogoutData)?.length > 0) {
                        setCustomerLogoutData(parsedCustomerLogoutData);
                    }
                } catch (error) {
                    console.error('[customer logout] Error parsing cart data:', error, 'Raw data:', data);
                }
            });

            channel.bind('pusher:subscription_error', (status: any) => {
                console.error(`[customer logout] Failed to subscribe to channel ${channelName}:`, status);
            });

            return () => {
                // console.log(`[customer logout] Unsubscribing from Pusher channel: ${channelName}`);
                // channel.unbind_all();
                // pusher.unsubscribe(channelName);
            };
        }
    }, [pusher, parsedIds]);

    useEffect(() => {
        console.log('customerLogoutData :>> ', customerLogoutData);
        if (customerLogoutData && Object.keys(customerLogoutData)?.length > 0 && customerLogoutData?.orderStatus === "COMPLETED") {
            setNavigateToLastPage(true);
        }
    }, [customerLogoutData]);

    useEffect(() => {
        if (navigateToLastPage) {
            const timer = setTimeout(() => {
                navigate("/payment-status");
            }, 4000);

            return () => clearTimeout(timer); // Cleanup timeout on component unmount or change
        }
    }, [navigateToLastPage, navigate]);

    useEffect(() => {
        setTimeout(() => {
            setCustomerLogoutData(null);
        }, 2000);
    }, [location]);

    return (
        <CustomerLogoutContext.Provider value={customerLogoutData}>
            {children}
        </CustomerLogoutContext.Provider>
    );
};

const useCustomerLogoutPusherChannel = (): IObject | null => {
    const context = useContext(CustomerLogoutContext);
    // if (context === null) {
    //     throw new Error('useCustomerLogoutPusherChannel must be used within a CustomerLogoutChannelProvider');
    // }
    return context;
};

export default CustomerLogoutChannelProvider;
export { useCustomerLogoutPusherChannel };