import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const usePreventBackNavigation = (path: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Don't apply navigation if the path is an empty string
    if (!path) return;

    // Function to push the current state into the history stack
    const pushHistory = () => {
      window.history.pushState(null, "", window.location.href);
    };

    // Function to handle the back navigation attempt
    const handlePopState = () => {
      navigate(path, { replace: true });
    };

    // Push the initial state to the history stack
    pushHistory();

    // Listen to the popstate event (triggered by back/forward navigation)
    window.addEventListener("popstate", handlePopState);

    // Continuously push to the history stack to prevent going back
    const interval = setInterval(pushHistory, 500);

    // Cleanup: remove event listener and clear interval on unmount
    return () => {
      clearInterval(interval);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, path]);

  // Ensure the current state is replaced on component mount
  useEffect(() => {
    // Don't apply navigation if the path is an empty string
    if (!path) return;

    navigate(path, { replace: true });
  }, [navigate, path]);
};

export default usePreventBackNavigation;
