import { ReactComponent as WarningIcon } from "assets/images/field-error.svg";
import { rupeeFormatter } from "central-utils/currencyUtils";
import { handleFoodTypeImage } from "central-utils/foodTypeImageUtils";
import { orderTabItems } from "central-utils/tabUtils";
import ConfirmOrderSlider from "components/ConfirmOrderSlider";
import CounterButton from "components/CounterButton";
import CustomToast from "components/CustomToast";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleFooterHeight } from "store/footer/footerSlice";
import { useUpdateOrderItemQuantityByProductIdMutation } from "store/orders/ordersAPISlice";
import { handleCounterLoader, handleTabChange } from "store/tab/tabSlice";
import { IObject } from "types";

interface Props {
  pendingOrderGroupData: {
    tabContent: IObject[] | null;
    orderGroupHelperData: IObject | null;
  };
}

const PendingTabContent: React.FC<Props> = ({ pendingOrderGroupData }) => {
  const { tabContent, orderGroupHelperData } = pendingOrderGroupData;
  const { isOrderTimeClosed } = useAppSelector(
    (state) => state.tableStatusAndOrder
  );

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [updateQuantity] = useUpdateOrderItemQuantityByProductIdMutation();

  // Initialize state with tabContent or an empty array
  const [updatedOrderItems, setUpdatedOrderItems] = useState<IObject[]>([]);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  // Populate state with initial data on mount or when tabContent changes
  useEffect(() => {
    if (tabContent) {
      setUpdatedOrderItems(tabContent);
    }
  }, [tabContent]);

  useEffect(() => {
    if (isOrderTimeClosed) {
      sessionStorage.setItem(
        "activeOrderHeaderTab",
        JSON.stringify(orderTabItems[1]?.id)
      );
      dispatch(
        handleTabChange({
          orderPageHeaderTabs: {
            ...orderTabItems[1],
            icon: undefined,
            disabled: false,
          },
        })
      );
    }
  }, [dispatch, isOrderTimeClosed]);

  // Handle quantity change, call updateQuantity API via RTK hook, and handle response
  const handleOnQuantityChange = async (
    updatedQuantity: number,
    orderItem: IObject,
    categoryId: string
  ) => {
    const updatedTotalPrice = updatedQuantity * orderItem.unitPrice; // Calculate the updated total price

    // Show global loader
    dispatch(
      handleCounterLoader({
        itemId: orderItem?.id,
        isLoading: true,
      })
    );

    try {
      // Call the RTK hook
      const res = await updateQuantity({
        orderItemId: orderItem?.id,
        price: updatedTotalPrice || 0,
        quantity: updatedQuantity,
      });

      dispatch(
        handleCounterLoader({
          itemId: orderItem?.id,
          isLoading: false,
        })
      );

      if (
        res?.data ||
        res?.success ||
        res?.status === "success" ||
        (res?.data === null && updatedQuantity === 0)
      ) {
        // Update the local state with the new quantity and price
        const updatedItems = updatedOrderItems
          ?.map((category) => {
            if (category?.id === categoryId) {
              // Filter out items with a quantity of 0
              const updatedOrderItems = category?.orderItems
                ?.map((item: IObject) =>
                  item?.id === orderItem?.id
                    ? { ...item, quantity: updatedQuantity }
                    : item
                )
                ?.filter((item: any) => item?.quantity > 0); // Remove items with quantity 0

              return {
                ...category,
                orderItems: updatedOrderItems,
              };
            }
            return category;
          })
          ?.filter((category) => category?.orderItems?.length > 0);

        setUpdatedOrderItems(updatedItems);
      } else {
        const errMessage =
          res?.error?.data?.message ??
          res?.error?.error ??
          res?.error?.data?.error ??
          "Something went wrong while updating the quantity. Please try again!";

        if (
          errMessage ===
          "Order is not in NEW status. Cannot add or update items."
        ) {
          navigate("/payment-in-progress-block");
        } else {
          [
            "Order Item already confirmed",
            "Order group is currently in progress and cannot be modified.",
          ].includes(errMessage.trim()) && window.location.reload();
          dispatch(handleFooterHeight(160));
          setToastMessage(errMessage);
        }
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
      dispatch(
        handleCounterLoader({
          itemId: orderItem?.id,
          isLoading: false,
        })
      );
      setToastMessage("Something went wrong. Please try again later.");
    }
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  if (updatedOrderItems && updatedOrderItems.length && orderGroupHelperData) {
    return (
      <div className="pendingTab">
        <div className="mb-[73px]">
          {updatedOrderItems.map((categorizedOrderItem: any) => (
            <div
              key={categorizedOrderItem?.id}
              className="last:mb-[65px] categorizedOrderItem"
            >
              <div className="categoryName first:mt-[10px] py-[10px]">
                {categorizedOrderItem?.categoryName}
              </div>
              <div className="orderItemContainer">
                {categorizedOrderItem?.orderItems?.map((orderItem: any) => {
                  const {
                    id,
                    foodType,
                    displayName,
                    servingSize,
                    quantity,
                    description,
                    unitPrice,
                  } = orderItem;
                  const foodTypeImage = handleFoodTypeImage(foodType);
                  const orderItemPrice = quantity * unitPrice;

                  return (
                    <div
                      key={id}
                      className="w-full flex justify-between items-center py-[15px] border-[1px] orderItem"
                    >
                      <div className="w-1/2">
                        <div className="ml-[10px] flex items-center">
                          {foodTypeImage && (
                            <img
                              className="foodTypeIcon mr-[10px]"
                              src={foodTypeImage}
                              alt={foodType || "Food type image"}
                            />
                          )}
                          <span>
                            {displayName}
                            <span className="whitespace-nowrap">{` (${servingSize})`}</span>
                          </span>
                        </div>
                        {description && (
                          <p
                            className={`itemDescription break-words mt-1 ${foodTypeImage ? "ml-[30px]" : "ml-[10px]"
                              }`}
                          >
                            {description}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col items-center mr-[10px]">
                        <CounterButton
                          itemId={id}
                          initialQuantity={quantity}
                          buttonType="SMALL"
                          onQuantityChange={(quantity) =>
                            handleOnQuantityChange(
                              quantity,
                              orderItem,
                              categorizedOrderItem.id
                            )
                          }
                        />
                        <div className="itemPrice mt-[10px] text-center break-words">
                          {rupeeFormatter(orderItemPrice)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center">
          <button className="fixed confirmOrderButton">
            <ConfirmOrderSlider orderGroupData={orderGroupHelperData} />
          </button>
        </div>
        {renderToast()}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-full pageInfoMessage">
        <div className="warningIcon iconColor">
          <WarningIcon className="mb-[10px]" />
        </div>
        <div>Please place your orders!</div>
      </div>
    );
  }
};

export default PendingTabContent;
