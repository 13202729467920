import { handleModifierDescription } from "central-utils/modifierStringUtils";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import CustomLoader from "components/common/CustomLoader";
import CustomToast from "components/CustomToast";
import CompletedTabContent from "components/order-tabs/CompletedTabContent";
import PendingTabContent from "components/order-tabs/PendingTabContent";
import OrderItemsBanner from "components/OrderItemsBanner";
import { useOrder } from "context/order/OrderContext";
import { useCartChannel } from "context/pusher/cart";
import { usePriceChannel } from "context/pusher/price";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleConfirmedTabDisableState } from "store/header/headerSlice";
import { IObject } from "types";

type pendingTabContentType = {
  tabContent: IObject[] | null;
  orderGroupHelperData: IObject | null;
};

type confirmedTabContentType = {
  tabContent: IObject[] | null;
  orderGroupHelperData: IObject[] | null;
};

const OrdersPage: React.FC = () => {
  const {
    orderData,
    orderIsLoading,
    orderIsError,
    orderIsSuccess,
    orderIsFetching,
    orderIsUninitialized,
    refetchOrderData,
  } = useOrder();

  useEffect(() => {
    if (orderIsError) {
      setToastMessage("Oops! There was an error. Please try again.");
    }
  }, [orderIsError]);

  const pusherCartDataProvider = useCartChannel();
  const pusherPriceDataProvider = usePriceChannel();

  const dispatch = useAppDispatch();

  const tabContent = useAppSelector(
    (state) => state.tab.selectedTabDetails.orderPageHeaderTabs
  );

  useEffect(() => {
    !orderIsUninitialized && refetchOrderData();
  }, [tabContent]);

  const [pusherCartData, setPusherCartData] = useState<IObject[] | null>(null);
  const [updateMenuItemPrice, setUpdateMenuItemPrice] =
    useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [pendingTabContent, setPendingTabContent] =
    useState<pendingTabContentType>({
      orderGroupHelperData: null,
      tabContent: null,
    });
  const [confirmedTabContent, setConfirmedTabContent] =
    useState<confirmedTabContentType>({
      orderGroupHelperData: null,
      tabContent: null,
    });

  useEffect(() => {
    if (pusherCartDataProvider) {
      setPusherCartData(pusherCartDataProvider);
    } else {
      setPusherCartData([]);
    }
  }, [pusherCartDataProvider]);

  // Price Update Pusher
  useEffect(() => {
    if (
      pusherPriceDataProvider &&
      Object.keys(pusherPriceDataProvider ?? {})?.length &&
      Object.hasOwn(pusherPriceDataProvider, "isPriceUpdateFlag")
    ) {
      setUpdateMenuItemPrice(pusherPriceDataProvider?.isPriceUpdateFlag);
    }

    // Set a timer to reset the updateMenuItemPrice after 3 seconds
    const timer = setTimeout(() => {
      setUpdateMenuItemPrice(false);
    }, 3000);

    // Clean up the timer when the component unmounts or when pusherPriceData changes
    return () => clearTimeout(timer);
  }, [pusherPriceDataProvider]);

  const handleCategorySegregation = (
    orderItemsData: IObject[]
  ) => {
    if (!orderItemsData || orderItemsData.length === 0) {
      return null; // Return null if the input data is empty or undefined
    }

    const formattedData: Record<
      string,
      { id: number; categoryName: string; orderItems: IObject[] }
    > = {};

    orderItemsData.forEach((orderItem) => {
      const { topMostCategory } = orderItem;

      // Check if the category already exists in the formattedData
      if (!formattedData[topMostCategory]) {
        formattedData[topMostCategory] = {
          id: Object.keys(formattedData).length, // Unique ID based on number of categories
          categoryName: convertToCapitalizedString(topMostCategory),
          orderItems: [],
        };
      }

      // Build description based on instructions and modifiers
      const instructions = orderItem?.instructions?.join(", ") || "";
      const modifiers = handleModifierDescription(orderItem);

      const descriptionParts = [];
      if (instructions) descriptionParts.push(instructions);
      if (modifiers) descriptionParts.push(modifiers);

      const description = descriptionParts.join(", ");

      let updatedQuantity = orderItem?.quantity;
      if (orderItem?.cancelledQuantity > 0) {
        updatedQuantity = orderItem?.quantity - orderItem?.cancelledQuantity;
      }
      // Transform order item
      let transformedOrderItem: any = {
        id: orderItem?.id,
        displayName: orderItem?.menuItemMaster?.displayName,
        foodType: orderItem?.menuItemMaster?.foodType,
        servingSize: orderItem?.menuItemMaster?.productVariant?.size?.size,
        unitPrice: orderItem?.price / orderItem?.quantity, // Calculate unit price per item
        totalPrice: orderItem?.price,
        quantity: updatedQuantity,
        description: description,
        status: orderItem?.status,
        helperData: orderItem, // Retain original order item data if needed
      };

      // Push the transformed order item into the corresponding category
      formattedData[topMostCategory].orderItems.push(transformedOrderItem);
    });

    const sortedData = Object.values(formattedData)?.sort((a, b) =>
      a.categoryName.localeCompare(b.categoryName)
    );

    return Object.values(sortedData); // Return the array of categorized items
  };

  const handleOrderGroupAccordionData = (
    orderGroupsData: IObject[],
    filteredOrderIds: number[]
  ) => {
    if (orderGroupsData && orderGroupsData.length) {
      const accordionData = orderGroupsData?.map(
        (orderGroup: any, index: number) => {
          return {
            id: orderGroup?.id,
            accordionHeader: `Order  #${index < 9
              ? `0${filteredOrderIds.indexOf(orderGroup?.id) + 1}`
              : filteredOrderIds.indexOf(orderGroup?.id) + 1
              }`,
            isAccordionOpen: true,
            orderGroupStatus: orderGroup?.status,
            orderItems: handleCategorySegregation(
              orderGroup?.orderItems
            ),
          };
        }
      );

      return accordionData;
    }
    return null;
  };

  const handleOrderGroupData = (orderGroupsData: IObject[]) => {
    if (orderGroupsData && orderGroupsData?.length) {
      const filteredOrders =
        orderGroupsData
          ?.filter((order: any) => order?.status !== "CANCELLED")
          ?.map((order) => ({
            ...order,
            orderItems: order?.orderItems?.filter(
              (item: any) => item?.status !== "CANCELLED" && item?.quantity > 0
            ),
          })) || null;
      const filteredOrderIds = filteredOrders?.map((order: any) => order.id);

      // Segregating NEW and other Order group Status
      const [newOrders] =
        filteredOrders.filter((order: any) => order.status === "NEW") || null;
      const otherOrders =
        filteredOrders
          .filter((order: any) => order.status !== "NEW")
          .sort((a: any, b: any) => b.id - a.id) || null;

      // Handle Confirm Header Tab Disable State
      if (otherOrders && otherOrders?.length) {
        dispatch(handleConfirmedTabDisableState(false));
      } else {
        dispatch(handleConfirmedTabDisableState(true));
      }
      setPendingTabContent({
        tabContent: handleCategorySegregation(newOrders?.orderItems),
        orderGroupHelperData: newOrders,
      });

      setConfirmedTabContent({
        tabContent: handleOrderGroupAccordionData(
          otherOrders,
          filteredOrderIds
        ),
        orderGroupHelperData: otherOrders,
      });
    } else {
      setPendingTabContent({
        orderGroupHelperData: null,
        tabContent: null,
      });
    }
  };

  useEffect(() => {
    if (pusherCartData) {
      handleOrderGroupData(pusherCartData);
    }
  }, [pusherCartData]);

  useEffect(() => {
    if (orderIsSuccess && orderData && Object.keys(orderData).length > 0) {
      handleOrderGroupData(orderData.orderGroups);
    }
  }, [orderIsSuccess, orderData]);

  // Tab Contents
  const renderTabContents = () => {
    if (tabContent) {
      switch (tabContent.label) {
        case "Completed":
          return (
            <CompletedTabContent
              confirmedOrderGroupData={confirmedTabContent || null}
            />
          );

        case "Pending":
          return (
            <PendingTabContent
              pendingOrderGroupData={pendingTabContent || null}
            />
          );

        default:
          break;
      }
    }
    return null;
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  return (
    <>
      {(orderIsLoading || orderIsFetching) && (
        <CustomLoader isLoading={orderIsLoading || orderIsFetching} />
      )}
      {renderTabContents()}
      {updateMenuItemPrice && <OrderItemsBanner bannerType="PRICE_ALERT" />}
      {renderToast()}
    </>
  );
};

export default OrdersPage;
