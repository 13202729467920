import { ReactComponent as BackArrow } from "assets/images/back-arrow.svg";
import CustomLoader from "components/common/CustomLoader";
import { useOrder } from "context/order/OrderContext";
import { handleDirectCardLevelData } from "components/category/accordionUtils";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/customHooks";
import { useGetGroupedPromotionsQuery } from "store/promotionBanner/promotionsPageAPIAlice";

const PromotionsDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ids = sessionStorage.getItem("Ids");
  const parsedIds = ids && JSON.parse(ids);
  const promoId =
    location.state.promoItemId || sessionStorage.getItem("SelectedPromoId");
  const title = location.state.title;
  const description = location.state.description;
  const imgUrl = location.state.url;
  const initialAPISuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );

  const {
    data: groupedPromotionData,
    isLoading: groupedPromotionIsLoading,
    isError: groupedPromotionIsError,
    isSuccess: groupedPromotionIsSuccess,
    isFetching: groupedPromotionIsFetching,
  } = useGetGroupedPromotionsQuery(
    {
      id: promoId,
      branchId: parsedIds?.branchId,
    },
    { skip: !initialAPISuccess }
  );

  const {
    orderData,
    orderIsLoading,
    orderIsUninitialized,
    orderIsError,
    orderIsSuccess,
    orderIsFetching,
    refetchOrderData,
  } = useOrder();

  const [addItemQuantity, setAddItemQuantity] = useState<any>();

  return (
    <div className="promotionDetailsPage">
      <div className="p-3 flex flex-row items-center">
        <BackArrow
          className="mr-4 iconColor"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className=" font-[500] promotionTitle">{title}</span>
      </div>
      <div>
        <img
          src={imgUrl}
          className="object-fill w-full h-56"
          alt="bannerImage"
        />
        {description && (
          <div
            className="mx-3 px-2 mt-3 promotionDescription"
            style={{ lineHeight: "normal" }}
          >
            {description}
          </div>
        )}
      </div>
      {groupedPromotionIsLoading ||
      groupedPromotionIsFetching ||
      groupedPromotionIsLoading ||
      orderIsLoading ||
      orderIsFetching ? (
        <CustomLoader isLoading />
      ) : (
        <div className="mx-3 my-5">
          {handleDirectCardLevelData(
            groupedPromotionData,
            orderData,
            setAddItemQuantity
          )}
        </div>
      )}
    </div>
  );
};

export default PromotionsDetailsPage;
