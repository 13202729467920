import { rupeeFormatter } from "central-utils/currencyUtils";
import { convertToCamelCase } from "central-utils/stringUtils";
import CounterButton from "components/CounterButton";
import CustomCheckBox from "components/CustomCheckBox";
import CustomToast from "components/CustomToast";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "store/customHooks";
import { handleMenuCustomizations } from "store/menuCustomization/menuCustomizationSlice";
import { IObject } from "types";

interface Props {
  mainHeader: string;
  isRequired?: boolean;
  bodyHeader?: string;
  checkBoxType: "MULTI_SELECT" | "SINGLE_SELECT";
  options: Option[];
  editData: IObject;
  minValue: number | null;
  maxValue: number | null;
}

interface Option {
  id?: number | string;
  label: string;
  price?: number;
  helperData?: { [key: string]: any };
  autoSelected: boolean;
  hideOption: boolean;
  initialQuantity?: number;
  quantity?: number;
}

const MenuItemCustomizationCheckbox: React.FC<Props> = ({
  checkBoxType,
  options,
  bodyHeader,
  isRequired = false,
  mainHeader,
  editData,
  maxValue,
  minValue,
}) => {
  const dispatch = useAppDispatch();

  const initialMultiCheckedStates = useMemo(() => {
    const selectedOptions = Array.isArray(editData) ? editData : [];
    return options.map((option) => {
      if (mainHeader === "Recommendations") {
        return false;
      }
      if (mainHeader === "Extras & Add-ons") {
        const idMatch = selectedOptions.some(
          (selectedOption) =>
            selectedOption?.helperData?.orderItemCustomerModifierMappingId ===
            option?.id
        );
        return idMatch;
      } else if (mainHeader !== "Instructions") {
        const idMatch = selectedOptions.some(
          (selectedOption) => selectedOption.id === option.id
        );
        return idMatch;
      } else {
        const helperDataMatch = selectedOptions.some(
          (selectedOption) =>
            selectedOption.label === option.label &&
            selectedOption.helperData === option.helperData
        );
        return helperDataMatch;
      }
    });
  }, [options, mainHeader, editData]);

  const initialSelectedSingleIndex = useMemo(
    () =>
      options.findIndex((option) =>
        editData?.some((opt: Option) => opt.id === option.id)
      ),
    [options, editData]
  );

  const initialQuantityState = useMemo(() => {
    // Ensure editData is an array to iterate over
    const editItems = Array.isArray(editData) ? editData : [];

    // Map through the options to set the initial quantity
    const updatedQuantities = options.map((option) => {
      const matchedItem = editItems.find(
        (item) =>
          item?.helperData?.orderItemCustomerModifierMappingId === option?.id
      );

      // If a matching item is found in editData, use its quantity
      // Otherwise, default to option.initialQuantity or 0
      return matchedItem?.quantity ?? option.initialQuantity ?? 0;
    });
    return updatedQuantities;
  }, [options, editData]);

  const [multiCheckedStates, setMultiCheckedStates] = useState<boolean[]>(
    initialMultiCheckedStates
  );
  const [selectedSingleIndex, setSelectedSingleIndex] = useState<number | null>(
    initialSelectedSingleIndex === -1 ? null : initialSelectedSingleIndex
  );
  const [quantities, setQuantities] = useState<number[]>(initialQuantityState);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  useEffect(() => {
    if (
      mainHeader === "Serving Size" &&
      checkBoxType === "SINGLE_SELECT" &&
      editData?.length === 0
    ) {
      if (selectedSingleIndex === null && options.length > 0) {
        setSelectedSingleIndex(0);
        const updatedOptions = options.map((option, i) => ({
          ...option,
          checked: i === 0,
        }));
        dispatch(
          handleMenuCustomizations({
            fieldName: convertToCamelCase(mainHeader),
            options: updatedOptions,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (mainHeader === "Extras & Add-ons" && !editData?.length) {
      const updatedMultiCheckedStates = options.map(
        (option) => option.autoSelected
      );
      setMultiCheckedStates(updatedMultiCheckedStates);

      const updatedOptions = options.map((option, i) => ({
        ...option,
        checked: updatedMultiCheckedStates[i],
      }));
      dispatch(
        handleMenuCustomizations({
          fieldName: convertToCamelCase(mainHeader),
          options: updatedOptions,
        })
      );
    }
  }, [multiCheckedStates, editData]);

  useEffect(() => {
    if (checkBoxType === "MULTI_SELECT") {
      const updatedOptions = options.map((option, i) => ({
        ...option,
        checked: multiCheckedStates[i],
        quantity: quantities[i],
      }));
      dispatch(
        handleMenuCustomizations({
          fieldName: convertToCamelCase(mainHeader),
          options: updatedOptions,
        })
      );
    } else if (
      checkBoxType === "SINGLE_SELECT" &&
      selectedSingleIndex !== null
    ) {
      const updatedOptions = options.map((option, i) => ({
        ...option,
        checked: i === selectedSingleIndex,
        quantity: quantities[i],
      }));
      dispatch(
        handleMenuCustomizations({
          fieldName: convertToCamelCase(mainHeader),
          options: updatedOptions,
        })
      );
    }
  }, [multiCheckedStates, selectedSingleIndex, quantities]);

  const handleMultiSelectChange = (index: number) => {
    const selectedCount = multiCheckedStates.filter(Boolean).length;

    if (
      !multiCheckedStates[index] &&
      maxValue !== null &&
      selectedCount >= maxValue
    ) {
      setToastMessage(`Please select up to ${maxValue} add ons only.`);
      return;
    }

    setToastMessage(null);

    const updatedStates = multiCheckedStates.map((checked, i) =>
      i === index ? !checked : checked
    );
    setMultiCheckedStates(updatedStates);

    // Reset quantity when unchecking
    if (!updatedStates[index]) {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] = 0;
      setQuantities(updatedQuantities);
    }
  };

  const handleSingleSelectChange = (index: number) => {
    setSelectedSingleIndex(index);

    // Reset all quantities except the selected one
    const updatedQuantities = quantities.map((q, i) => (i === index ? q : 0));
    setQuantities(updatedQuantities);
  };

  const handleQuantityChange = (index: number, newQuantity: number) => {
    // Clear any existing toast message when making a valid selection
    setToastMessage(null);

    // Check total quantities for all items
    const currentTotal = quantities.reduce(
      (sum, qty, i) => (i !== index ? sum + qty : sum),
      0
    );
    const newTotal = currentTotal + newQuantity;

    if (maxValue !== null && newTotal > maxValue) {
      // setToastMessage(`Please select up to ${maxValue} items in total.`);
      // return;
    }

    const updatedQuantities = [...quantities];
    updatedQuantities[index] = newQuantity;
    setQuantities(updatedQuantities);

    // If quantity is changed to more than 0, ensure the item is selected
    if (newQuantity > 0) {
      if (checkBoxType === "MULTI_SELECT") {
        const updatedStates = [...multiCheckedStates];
        updatedStates[index] = true;
        setMultiCheckedStates(updatedStates);
      } else {
        setSelectedSingleIndex(index);
      }
    } else {
      // If quantity is set to 0, unselect the item
      if (checkBoxType === "MULTI_SELECT") {
        const updatedStates = [...multiCheckedStates];
        updatedStates[index] = false;
        setMultiCheckedStates(updatedStates);
      }
    }
  };

  const renderCheckbox = (
    disabled: boolean,
    checked: boolean,
    onChange: () => void,
    checkBoxType: "CHECKBOX" | "RADIO"
  ) => {
    return (
      <CustomCheckBox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        type={checkBoxType}
      />
    );
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className="menuItemCustomizationCheckbox flex flex-col gap-2">
        {mainHeader && (
          <div className="menuItemCustomizationMainHeader">{mainHeader}</div>
        )}
        <div className="menuItemOptionsContainer flex flex-col gap-[1.5px]">
          {bodyHeader && (
            <h3 className="menuItemCustomizationSubHeader pb-[10px]">
              {bodyHeader}
            </h3>
          )}
          {options.map((option, index) => (
            <div
              key={index}
              className="flex justify-between items-center pb-[5px] menuItemOptionItem gap-2"
            >
              <div
                className={`menuItemOptionLabel capitalize ${
                  (checkBoxType === "MULTI_SELECT" &&
                    multiCheckedStates[index]) ||
                  (checkBoxType === "SINGLE_SELECT" &&
                    selectedSingleIndex === index)
                    ? "font-bold"
                    : "normal"
                }`}
              >
                {option.label}
              </div>
              <div className="flex justify-end items-center">
                {option?.price ? (
                  <div className="menuItemOptionPrice pr-[10px]">
                    {rupeeFormatter(option.price)}
                  </div>
                ) : (
                  ""
                )}
                {option.autoSelected ? (
                  renderCheckbox(
                    true,
                    multiCheckedStates[index],
                    () => handleMultiSelectChange(index),
                    checkBoxType === "MULTI_SELECT" ? "CHECKBOX" : "RADIO"
                  )
                ) : minValue !== null || maxValue !== null ? (
                  <CounterButton
                    buttonType="SMALL"
                    itemId={Number(option.id) || index}
                    onQuantityChange={(quantity) =>
                      handleQuantityChange(index, quantity)
                    }
                    initialQuantity={quantities[index]}
                  />
                ) : (
                  renderCheckbox(
                    false,
                    checkBoxType === "MULTI_SELECT"
                      ? multiCheckedStates[index]
                      : selectedSingleIndex === index,
                    () =>
                      checkBoxType === "MULTI_SELECT"
                        ? handleMultiSelectChange(index)
                        : handleSingleSelectChange(index),
                    checkBoxType === "MULTI_SELECT" ? "CHECKBOX" : "RADIO"
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {renderToast()}
    </>
  );
};

export default MenuItemCustomizationCheckbox;
