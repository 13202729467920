import React, { useState, useRef, useEffect } from "react";
import downArrow from "assets/images/down-arrow.svg";

interface Country {
  code: string;
  name: string;
  numberLength: number;
  flag?: string; // This will store the path to flag icon
  startDigits?: string;
}

interface CountryDropdownProps {
  selectedCountry: Country;
  onCountryChange: (country: Country) => void;
  error?: boolean;
}

const countries: Country[] = [
  {
    code: "+91",
    name: "India",
    numberLength: 10,
    flag: "IN",
    startDigits: "6-9",
  },
  {
    code: "+1",
    name: "United States",
    numberLength: 10,
    flag: "US",
    startDigits: "2-9",
  },
  {
    code: "+1",
    name: "Canada",
    numberLength: 10,
    flag: "CA",
    startDigits: "2-9",
  },
  {
    code: "+44",
    name: "United Kingdom",
    numberLength: 10,
    flag: "GB",
    startDigits: "7",
  },
  {
    code: "+61",
    name: "Australia",
    numberLength: 9,
    flag: "AU",
    startDigits: "4",
  },
  {
    code: "+86",
    name: "China",
    numberLength: 11,
    flag: "CN",
    startDigits: "1",
  },
  {
    code: "+49",
    name: "Germany",
    numberLength: 11,
    flag: "DE",
    startDigits: "15-17",
  },
  {
    code: "+33",
    name: "France",
    numberLength: 9,
    flag: "FR",
    startDigits: "6-7",
  },
  {
    code: "+81",
    name: "Japan",
    numberLength: 10,
    flag: "JP",
    startDigits: "70-90",
  },
  {
    code: "+82",
    name: "South Korea",
    numberLength: 10,
    flag: "KR",
    startDigits: "1",
  },
  {
    code: "+7",
    name: "Russia",
    numberLength: 10,
    flag: "RU",
    startDigits: "9",
  },
  {
    code: "+39",
    name: "Italy",
    numberLength: 10,
    flag: "IT",
    startDigits: "3",
  },
  {
    code: "+34",
    name: "Spain",
    numberLength: 9,
    flag: "ES",
    startDigits: "6-7",
  },
  {
    code: "+55",
    name: "Brazil",
    numberLength: 11,
    flag: "BR",
    startDigits: "9",
  },
  {
    code: "+52",
    name: "Mexico",
    numberLength: 10,
    flag: "MX",
    startDigits: "1-9",
  },
  { code: "+971", name: "UAE", numberLength: 9, flag: "AE", startDigits: "5" },
  {
    code: "+966",
    name: "Saudi Arabia",
    numberLength: 9,
    flag: "SA",
    startDigits: "5",
  },
  {
    code: "+65",
    name: "Singapore",
    numberLength: 8,
    flag: "SG",
    startDigits: "8-9",
  },
  {
    code: "+60",
    name: "Malaysia",
    numberLength: 9,
    flag: "MY",
    startDigits: "1",
  },
  {
    code: "+64",
    name: "New Zealand",
    numberLength: 9,
    flag: "NZ",
    startDigits: "2",
  },
];

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  selectedCountry,
  onCountryChange,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const filteredCountries = countries.filter((country) =>
    `${country.code} ${country.name}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div ref={dropdownRef} className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center px-3 gap-1 border-r-4 numberPrefix cursor-pointer ${
          error ? "border-red-500" : ""
        }`}
      >
        {/* Replace with actual flag icon once you have them */}
        <img
          src={`/flagIcons/${selectedCountry.flag}.png`}
          //   src={`assets/images/${selectedCountry.flag}.png`}
          alt={selectedCountry.name}
          className="w-5 h-5"
        />
        <span>{selectedCountry.code}</span>
        <img src={downArrow} className="ml-1 w-2 h-2" alt="downArrow" />
      </div>

      {isOpen && (
        <div className="absolute top-full left-0 mt-1 w-64 countryDropdownContainer border rounded-md shadow-lg z-50">
          {/* <input
            type="text"
            placeholder="Search countries..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 border-b border-gray-300 rounded-t-md focus:outline-none"
            onClick={(e) => e.stopPropagation()}
          /> */}
          <div className="max-h-60 overflow-y-auto">
            {filteredCountries.map((country) => (
              <div
                key={`${country.code}-${country.name}`}
                onClick={() => {
                  onCountryChange(country);
                  setIsOpen(false);
                  setSearchQuery("");
                }}
                className={`flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer ${
                  selectedCountry.name.toLowerCase() ===
                    country.name.toLowerCase() && "selectedCountry"
                }`}
              >
                <img
                  src={`/flagIcons/${country.flag}.png`}
                  alt={country.name}
                  className="w-5 h-5"
                />
                <span>{country.code}</span>
                <span className="countryName font-normal">{country.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryDropdown;
