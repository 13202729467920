import { Modal } from 'antd';
import QRCodeGenerator from 'components/QRCodeGenerator';
import { useOrder } from 'context/order/OrderContext';
import React, { useEffect } from 'react';
import { useAppSelector } from 'store/customHooks';

interface Props {
    toggleModal: () => void;
}

const QRCodeModal: React.FC<Props> = ({ toggleModal }) => {

    const { orderData } = useOrder();
    const orderDetails = useAppSelector((state) => state.tableStatusAndOrder.orderDetails);

    const handleBackgroundClick = () => {
        toggleModal();
    };

    const handleModalClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <Modal
            closable={false}
            open={true}
            onCancel={toggleModal}
            footer={null}
            className='qrCodeModal'
            centered
            style={{ padding: 0, margin: 0, borderRadius: "999px" }}
        >
            <div
                style={{ zIndex: 9999 }}
                className="qrCodeModal fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
                onClick={handleBackgroundClick}
            >
                <div
                    className="qrCodeModalContainer relative flex flex-col items-center justify-center"
                    onClick={handleModalClick}
                >
                    <button onClick={toggleModal} className="rounded-full bg-[#EEEEEE] p-2 absolute top-3 right-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.708" height="9.706" viewBox="0 0 9.708 9.706">
                            <g id="Group_5880" data-name="Group 5880" transform="translate(-1352.009 -1354.187)">
                                <g id="Group_5874" data-name="Group 5874" transform="translate(1352.009 1354.187)">
                                    <path id="Path_6425" data-name="Path 6425" d="M1358.8,1359.056c.254.252.5.494.746.739q.877.875,1.752,1.752a1.361,1.361,0,1,1-1.9,1.945q-.753-.749-1.5-1.5l-.94-.94c-.022-.022-.047-.042-.094-.083-.17.177-.334.354-.5.526q-.979.984-1.963,1.965a1.367,1.367,0,0,1-2.361-.741,1.285,1.285,0,0,1,.109-.741,1.74,1.74,0,0,1,.429-.569c.37-.372.747-.737,1.117-1.109.217-.219.424-.448.641-.666.162-.164.334-.318.5-.477.085-.081.091-.126.008-.205s-.2-.17-.3-.255a.237.237,0,0,1-.035-.049.67.67,0,0,0-.071-.1q-.624-.621-1.251-1.24c-.114-.113-.226-.229-.337-.344-.213-.222-.429-.442-.636-.67a.479.479,0,0,1-.1-.214,2.377,2.377,0,0,1-.079-.51,2.235,2.235,0,0,1,.086-.489,1.057,1.057,0,0,1,.427-.586,1.3,1.3,0,0,1,1.149-.258c.128.034.254.073.378.119a.382.382,0,0,1,.11.091c.262.25.526.5.786.753s.537.531.8.8c.13.13.256.263.386.393q.312.314.625.626c.091.09.107.087.2,0,.2-.2.4-.391.594-.59.22-.223.434-.452.655-.675.15-.152.31-.295.46-.447s.316-.346.488-.5a1.965,1.965,0,0,1,.828-.55,1.347,1.347,0,0,1,1.22.26,1.316,1.316,0,0,1,.5,1.042,1.336,1.336,0,0,1-.426,1.018l-2.413,2.409C1358.853,1358.992,1358.832,1359.017,1358.8,1359.056Z" transform="translate(-1352.009 -1354.187)" fill="#1e1e1e" fillRule="evenodd" />
                                </g>
                            </g>
                        </svg>
                    </button>
                    <div className="flex flex-col justify-center items-center">
                        <div className='px-10 py-5 qrCodeButton'>
                            <QRCodeGenerator valueToBeEncoded={orderData?.id || orderDetails?.id} />
                        </div>
                        <p className="mt-8 text-center qrCodeDescription">Display to Customer Associate</p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default QRCodeModal;
