import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  handleMenuItemBannerDisplay,
  handleMenuItemsBannerType,
} from "store/menuCustomization/menuCustomizationSlice";

interface Props {
  bannerType: "ORDER_UPDATION" | "PRICE_ALERT";
  addItemQuantity?: number;
}

const OrderItemsBanner: React.FC<Props> = ({
  addItemQuantity,
  bannerType = "ORDER_UPDATION",
}) => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(
    (state) => state.menuCustomization.menuItemsBannerType
  );
  const showBanner = useAppSelector(
    (state) => state.menuCustomization.menuItemsBannerDisplay
  );
  // const quantities = useAppSelector((state) => state.menuCustomization.menuItemsBannerQuantities);
  const [visible, setVisible] = useState(showBanner);

  useEffect(() => {
    if (addItemQuantity && addItemQuantity > 0) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [addItemQuantity]);

  useEffect(() => {
    if (!visible) {
      dispatch(handleMenuItemsBannerType(null));
      dispatch(handleMenuItemBannerDisplay(false));
    }
  }, [dispatch, visible]);

  // Ensure all paths return a valid React element or null
  if (bannerType === "ORDER_UPDATION") {
    if (addItemQuantity && addItemQuantity > 0) {
      return (
        <div
          className={`fixed left-0 flex items-center justify-center py-2 orderItemBanner
                    ${
                      visible
                        ? "orderItemBannerVisible"
                        : "orderItemBannerHidden"
                    }`}
        >
          {addItemQuantity} {addItemQuantity === 1 ? "Item" : "Items"}{" "}
          {type === "ADD" ? "added!" : "edited!"}
        </div>
      );
    } else {
      return null; // Explicitly return null if addItemQuantity is not valid
    }
  } else if (bannerType === "PRICE_ALERT") {
    return (
      <div
        className={`fixed left-0 flex items-center justify-center py-2 orderItemBanner
                ${
                  visible ? "orderItemBannerVisible" : "orderItemBannerHidden"
                }`}
      >
        Prices have been updated!
      </div>
    );
  }

  return null;
};

export default OrderItemsBanner;
