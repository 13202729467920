// Utility function to compare values deeply
const compareValues = (a: any, b: any): boolean => {
  // If both values are strictly equal, they're considered the same
  if (a === b) return true;

  // Check if either value is null or not an object
  if (
    a === null ||
    b === null ||
    typeof a !== "object" ||
    typeof b !== "object"
  )
    return false;

  // Handle arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!compareValues(a[i], b[i])) return false;
    }
    return true;
  }

  // Handle objects
  if (typeof a === "object" && typeof b === "object") {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (let key of keysA) {
      if (!compareValues(a[key], b[key])) return false;
    }

    return true;
  }

  // For any other types (e.g., primitives) that are not covered above
  return false;
};

// Main function to check if two values are different
export const handleValuesAreDiff = (obj1: any, obj2: any): boolean => {
  return !compareValues(obj1, obj2);
};
