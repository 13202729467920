import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  handleCheckoutButtonDisable,
  handleCheckoutModalClearingOfPendingTab,
  handleNumberOfUnconfirmedOrderItems,
} from "store/footer/footerSlice";
import { useGetOrderByIdQuery } from "store/orders/ordersAPISlice";
import { handleOrderDetails } from "store/tableStatusAndOrder/tableStatusAndOrderSlice";

interface OrderContextProps {
  orderData: any;
  orderIsLoading: boolean;
  orderIsError: boolean;
  orderIsSuccess: boolean;
  orderIsFetching: boolean;
  orderIsUninitialized: boolean;
  refetchOrderData: () => void;
}

const OrderContext = createContext<OrderContextProps | undefined>(undefined);

const OrderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const initialAPICallSuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );
  const initialOrderDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.orderDetails
  );

  const [orderConsolidatedData, setOrderConsolidatedData] = useState<
    OrderContextProps | undefined
  >(undefined);

  const orderId = sessionStorage.getItem("OrderId");

  const pathNames = [
    "/customer-details",
    "/home",
    "/category",
    "/re-order",
    "/order",
  ];

  const {
    data: orderData,
    isLoading: orderIsLoading,
    isUninitialized: orderIsUninitialized,
    isError: orderIsError,
    isSuccess: orderIsSuccess,
    isFetching: orderIsFetching,
    refetch: refetchOrderData,
  } = useGetOrderByIdQuery(
    { id: initialOrderDetails?.id ?? orderId },
    {
      skip:
        !initialAPICallSuccess ||
        !(initialOrderDetails?.id || orderId) ||
        [
          "/payment-status",
          "/customer-details",
          "/verify-otp",
          "/verify-pin",
          "/enter-name",
        ].includes(location.pathname),
    }
  );

  useEffect(() => {
    if (
      pathNames.includes(location.pathname) &&
      orderData?.status === "COMPLETED"
    ) {
      navigate("/payment-status");
    }
  }, [location, navigate, orderData]);

  useEffect(() => {
    setOrderConsolidatedData({
      orderData,
      orderIsLoading,
      orderIsUninitialized,
      orderIsError,
      orderIsSuccess,
      orderIsFetching,
      refetchOrderData,
    });
  }, [
    orderData,
    orderIsLoading,
    orderIsUninitialized,
    orderIsError,
    orderIsSuccess,
    orderIsFetching,
    refetchOrderData,
  ]);

  useEffect(() => {
    if (
      orderIsSuccess &&
      orderData &&
      Object.hasOwn(orderData, "isVoid") &&
      orderData?.isVoid
    ) {
      navigate("/payment-in-progress-block", {
        state: { message: "You cannot access this order anymore!" },
      });
    }
  }, [navigate, orderData, orderIsSuccess]);

  useEffect(() => {
    dispatch(handleNumberOfUnconfirmedOrderItems(0));
    dispatch(handleOrderDetails(orderData));
    const checkIfAllDelivered: boolean =
      !!(
        orderData &&
        orderData?.orderGroup &&
        orderData?.orderGroup.length > 0 &&
        !(
          orderData?.orderGroup.length === 1 &&
          orderData?.orderGroup[0]?.status === "NEW"
        ) &&
        orderData?.orderGroup
          .filter(
            (orderGroup: any) =>
              orderGroup?.status !== "CANCELLED" &&
              orderGroup?.status !== "NEW"
          )
          .every((orderGroup: any) => orderGroup?.status === "COMPLETED")
      );

    const newOrders = (orderData &&
      orderData?.orderGroup?.length &&
      orderData?.orderGroup?.filter((orderGroup: any) => orderGroup?.status === "NEW")) || null;
    const hasNewOrder = (newOrders && newOrders.length > 0) || false;
    dispatch(handleCheckoutModalClearingOfPendingTab(hasNewOrder));
    dispatch(handleCheckoutButtonDisable(!checkIfAllDelivered));

    if (orderData && orderData?.orderGroups && orderData?.orderGroups?.length) {
      const [newStatusOrderGroup] = orderData?.orderGroups?.filter(
        (obj: any) => obj?.status === "NEW"
      );
      const filteredOrderedItems = newStatusOrderGroup?.orderItems?.filter(
        (obj: any) => obj?.status !== "CANCELLED" && obj?.quantity !== 0
      );
      const totalQuantity = filteredOrderedItems?.length
        ? filteredOrderedItems?.reduce(
          (sum: number, item: { quantity: number }) => sum + item.quantity,
          0
        )
        : 0;
      dispatch(handleNumberOfUnconfirmedOrderItems(totalQuantity));
    }
  }, [dispatch, orderData]);

  return (
    <OrderContext.Provider value={orderConsolidatedData}>
      {children}
    </OrderContext.Provider>
  );
};

const useOrder = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    return {
      orderData: null,
      orderIsLoading: false,
      orderIsError: false,
      orderIsSuccess: false,
      orderIsFetching: false,
      orderIsUninitialized: true,
      refetchOrderData: () => { },
    };
  }
  return context;
};

export default OrderProvider;
export { useOrder };

