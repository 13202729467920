import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MenuCustomizationOptions = { [key: string]: any };

interface MenuCustomizationState {
  selectedMenuCustomizationOptions: MenuCustomizationOptions;
  menuCustomizationOptions: MenuCustomizationOptions;
  menuItemsBannerType: "ADD" | "EDIT" | null;
  menuItemsBannerDisplay: boolean;
}

const initialState: MenuCustomizationState = {
  selectedMenuCustomizationOptions: {},
  menuCustomizationOptions: {},
  menuItemsBannerType: null,
  menuItemsBannerDisplay: false
};

const menuCustomizationSlice = createSlice({
  name: "menuCustomization",
  initialState,
  reducers: {
    handleMenuCustomizations: (
      state,
      action: PayloadAction<{
        fieldName: string;
        options: MenuCustomizationOptions[];
      }>
    ) => {
      const { fieldName, options } = action.payload;
      // Ensure fieldName exists in menuCustomizationOptions
      if (!state.menuCustomizationOptions[fieldName]) {
        state.menuCustomizationOptions[fieldName] = [];
      }

      // Update existing options or add new ones
      options.forEach((newOption) => {
        const existingIndex = state.menuCustomizationOptions[
          fieldName
        ].findIndex(
          (existingOption: { id: number }) => existingOption.id === newOption.id
        );

        if (existingIndex !== -1) {
          // Update existing option
          state.menuCustomizationOptions[fieldName][existingIndex] = newOption;
        } else {
          // Add new option
          state.menuCustomizationOptions[fieldName].push(newOption);
        }
      });

      // Filter options with `checked: true` and ensure quantity is properly tracked
      const filterTrueOptions = state.menuCustomizationOptions[
        fieldName
      ].filter(
        (item: { checked?: boolean; quantity?: number }) =>
          item.checked || (item.quantity && item.quantity > 0)
      );
      // Update selectedMenuCustomizationOptions
      state.selectedMenuCustomizationOptions[fieldName] = filterTrueOptions;
    },
    handleClearMenuCustomizations: (state) => {
      state.menuCustomizationOptions = {};
      state.selectedMenuCustomizationOptions = {};
    },
    handleMenuItemsBannerType: (
      state,
      action: PayloadAction<"ADD" | "EDIT" | null>
    ) => {
      state.menuItemsBannerType = action.payload;
    },
    handleMenuItemBannerDisplay: (state, action: PayloadAction<boolean>) => {
      state.menuItemsBannerDisplay = action.payload;
    },
  },
});

export default menuCustomizationSlice.reducer;
export const {
  handleMenuCustomizations,
  handleClearMenuCustomizations,
  handleMenuItemsBannerType,
  handleMenuItemBannerDisplay,
} = menuCustomizationSlice.actions;

export const getMenuCustomizationRelatedStates = (state: {
  menuCustomization: MenuCustomizationOptions;
}) => state.menuCustomization;
