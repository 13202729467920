import { validateChannelName } from "central-utils/validateChannelName";
import { usePusher } from "context/pusher";
import {
  handleUnconfirmedOrder,
  transformOrderItems,
} from "context/pusher/cart/cartFunctions";
import { handleConfirmOrderGroup } from "context/pusher/cart/confirmOrderGroupFunction";
import { handleOrderGroupStatus } from "context/pusher/cart/deliveredOrderGroupFunction";
import { handleDeliveredOrderItem } from "context/pusher/cart/deliveredOrderItemFunction";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  handleCheckoutButtonDisable,
  handleCheckoutModalClearingOfPendingTab,
  handleNumberOfUnconfirmedOrderItems,
} from "store/footer/footerSlice";
import { IObject } from "types";

interface Props {
  children: ReactNode;
}

interface ICartPusherData {
  orderItemId: number;
  orderGroupId: number;
  menuItemMaster: IObject;
  modifiers: IObject[];
  quantity: number;
  instructions: string[];
  status: string;
  actionType: string;
  price: number;
  topMostCategory: string;
  productMasterId: number;
  orderTimeStamp: Date;
}

const CartContext = createContext<IObject[] | null>(null);

const CartChannelProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const orderData = useAppSelector(
    (state) => state.tableStatusAndOrder.orderDetails
  );

  const ids = sessionStorage.getItem("Ids");
  const parsedIds: {
    brandId: string;
    branchId: string;
    tableId: string;
  } | null = ids ? JSON.parse(ids) : null;

  const xTenantDetails = sessionStorage.getItem("CustomerApp-Tenant");
  const parsedTenantDetails: {
    tenantName: string;
    id: number | string;
    databaseName: string;
    shortName: string;
  } | null = xTenantDetails ? JSON.parse(xTenantDetails) : null;

  const branchDetails = sessionStorage.getItem("Branch");
  const parsedBranchDetails: {
    branchName: string;
    id: number | string;
    branchId: string;
    shortName: string;
  } | null = branchDetails ? JSON.parse(branchDetails) : null;

  const pusher = usePusher();
  const [cartData, setCartData] = useState<ICartPusherData | null>(null);
  const [confirmOrderGroupData, setConfirmOrderGroupData] =
    useState<IObject | null>(null);
  const [deliveredOrderItemData, setDeliveredOrderItemData] =
    useState<ICartPusherData | null>(null);
  const [orderGroupStatusData, setOrderGroupStatusData] =
    useState<IObject | null>(null);
  const [cleanCartData, setCleanCartData] = useState<ICartPusherData[] | null>(
    null
  );
  const [formattedCartData, setFormattedCartData] = useState<IObject[] | null>(
    null
  );
  const [finalData, setFinalData] = useState<IObject[] | null>(
    orderData?.orderGroups
  );
  useEffect(() => {
    setFinalData(orderData?.orderGroups);
  }, [orderData?.orderGroups]);

  useEffect(() => {
    if (pusher && parsedIds && parsedTenantDetails && parsedBranchDetails) {
      // Subscribe to Unconfirmed Order Group Channel
      const unconfirmedOrderGroupChannelName = `${validateChannelName(
        parsedTenantDetails.shortName
      )}-${validateChannelName(parsedBranchDetails.shortName)}-Table-${parsedIds.tableId
        }`;
      const unconfirmedOrderGroupChannel = pusher.subscribe(
        unconfirmedOrderGroupChannelName
      );
      console.log(
        "[cart] pusher - update OrderCart is the event - channelName :>> ",
        unconfirmedOrderGroupChannelName
      );

      unconfirmedOrderGroupChannel.bind("update OrderCart", (data: any) => {
        handlePusherData(data, setCartData);
      });

      unconfirmedOrderGroupChannel.bind(
        "pusher:subscription_error",
        (status: any) => {
          handleSubscriptionError(unconfirmedOrderGroupChannelName, status);
        }
      );

      // Subscribe to Confirmed Order Group Channel
      const confirmedOrderGroupChannelName = `${validateChannelName(
        parsedTenantDetails.shortName
      )}-${validateChannelName(parsedBranchDetails.shortName)}-Table-${parsedIds.tableId
        }`;
      const confirmedOrderGroupChannel = pusher.subscribe(
        confirmedOrderGroupChannelName
      );
      console.log(
        "[confirm order group] pusher - confirmedOrderCart is the event - channelName :>> ",
        confirmedOrderGroupChannelName
      );

      confirmedOrderGroupChannel.bind("confirmedOrderCart", (data: any) => {
        handlePusherData(data, setConfirmOrderGroupData);
      });

      confirmedOrderGroupChannel.bind(
        "pusher:subscription_error",
        (status: any) => {
          handleSubscriptionError(confirmedOrderGroupChannelName, status);
        }
      );

      // Subscribe to Delivered Order Items Channel
      const deliveredOrderItemChannelName = `${validateChannelName(
        parsedTenantDetails.shortName
      )}-${validateChannelName(parsedBranchDetails.shortName)}-Table-${parsedIds.tableId
        }`;
      const deliveredOrderItemChannel = pusher.subscribe(
        deliveredOrderItemChannelName
      );
      console.log(
        "[delivered order item] pusher - deliveredOrderItem is the event - channelName :>> ",
        deliveredOrderItemChannelName
      );

      deliveredOrderItemChannel.bind("deliveredOrderItem", (data: any) => {
        handlePusherData(data, setDeliveredOrderItemData);
      });

      deliveredOrderItemChannel.bind(
        "pusher:subscription_error",
        (status: any) => {
          handleSubscriptionError(deliveredOrderItemChannelName, status);
        }
      );

      // Subscribe to Delivered Order Group Status Channel
      const orderGroupStatusChannelName = `tenant-${parsedIds?.brandId}-branch-${parsedIds?.branchId}-table-${parsedIds?.tableId}-customerPusherChannel`;
      const orderGroupStatusChannel = pusher.subscribe(
        orderGroupStatusChannelName
      );
      console.log(
        "[order group status] pusher - orderGroupStatusUpdate is the event - channelName :>> ",
        orderGroupStatusChannelName
      );

      orderGroupStatusChannel.bind("orderGroupStatusUpdate", (data: any) => {
        handlePusherData(data, setOrderGroupStatusData);
      });

      orderGroupStatusChannel.bind(
        "pusher:subscription_error",
        (status: any) => {
          handleSubscriptionError(orderGroupStatusChannelName, status);
        }
      );

      // Cleanup function to unsubscribe from all channels
      return () => {
        // unconfirmedOrderGroupChannel.unbind_all();
        // confirmedOrderGroupChannel.unbind_all();
        // deliveredOrderItemChannel.unbind_all();
        // orderGroupStatusChannel.unbind_all();
        // pusher.unsubscribe(unconfirmedOrderGroupChannelName);
        // pusher.unsubscribe(confirmedOrderGroupChannelName);
        // pusher.unsubscribe(deliveredOrderItemChannelName);
        // pusher.unsubscribe(orderGroupStatusChannelName);
      };
    }
  }, [pusher, parsedIds, parsedTenantDetails, parsedBranchDetails]);

  // Utility function to handle incoming Pusher data
  const handlePusherData = (
    data: any,
    setData: React.Dispatch<React.SetStateAction<any>>
  ) => {
    try {
      const parsedData = JSON.parse(data);
      console.log(`${new Date()} - Pusher event data received:`, parsedData);
      setData({
        ...parsedData,
        orderTimeStamp: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error parsing Pusher data:", error, "Raw data:", data);
    }
  };

  // Utility function to handle subscription errors
  const handleSubscriptionError = (channelName: string, status: any) => {
    console.error(
      `[cart] Failed to subscribe to channel ${channelName}:`,
      status
    );
  };

  // Clean the Duplicates and Replacements
  useEffect(() => {
    if (cartData && Object.keys(cartData)?.length) {
      console.log("cartData", cartData);
      setCleanCartData((prevCartData) => {
        if (!prevCartData) {
          // If no previous data, just set the new data
          return [cartData];
        }

        // Check if the item already exists in the cart by orderItemId
        const existingItemIndex = prevCartData.findIndex(
          (item) => item.orderItemId === cartData.orderItemId
        );

        if (existingItemIndex !== -1) {
          // Item already exists in the cart, update it
          const updatedCartData = [...prevCartData];

          if (cartData.actionType === "DELETE") {
            // Mark the item as deleted but don't remove it
            updatedCartData[existingItemIndex] = {
              ...cartData,
            };
          } else {
            // For CREATE/UPDATE, replace the item with the latest data
            updatedCartData[existingItemIndex] = cartData;
          }

          return updatedCartData;
        } else {
          // If item does not exist, add it (CREATE/UPDATE)
          return [...prevCartData, cartData];
        }
      });
    }
  }, [cartData]);

  useEffect(() => {
    console.log("cleanCartData :>> ", cleanCartData);
    if (cleanCartData && cleanCartData?.length) {
      const cleanFormattedData = transformOrderItems(cleanCartData);
      console.log("cleanFormattedData :>> ", cleanFormattedData);
      setFormattedCartData(cleanFormattedData);
    }
  }, [cleanCartData]);

  // Unconfirmed Order Group Manipulation
  useEffect(() => {
    if (formattedCartData?.length) {
      const convertedData = handleUnconfirmedOrder(
        orderData || { orderGroups: [] },
        formattedCartData
      );
      console.log("[unconfirmed] convertedData :>> ", convertedData);
      setFinalData(convertedData);
    }
  }, [formattedCartData, orderData]);

  useEffect(() => {
    // Unconfirmed Ordered Items - My Cart Number Popup
    dispatch(handleNumberOfUnconfirmedOrderItems(0));
    if (finalData && finalData.length) {
      const updatedQuantity = finalData.reduce((sum, order) => {
        const orderItemSum = order.orderItems
          .filter((item: any) => item?.status === "NEW")
          .reduce(
            (itemSum: any, item: { quantity: any }) => itemSum + item.quantity,
            0
          );
        return sum + orderItemSum;
      }, 0);
      dispatch(handleNumberOfUnconfirmedOrderItems(updatedQuantity));
    }

    // Checking for the Checkout Button Disable Condition
    const checkIfAllDelivered: boolean =
      !!(
        finalData &&
        finalData.length > 0 &&
        !(
          finalData.length === 1 &&
          finalData[0]?.status === "NEW"
        ) &&
        finalData
          .filter(
            (orderGroup: any) =>
              orderGroup?.status !== "CANCELLED" &&
              orderGroup?.status !== "NEW"
          )
          .every((orderGroup: any) => orderGroup?.status === "COMPLETED")
      );

    const newOrders = (finalData &&
      finalData?.length &&
      finalData?.filter((orderGroup: any) => orderGroup?.status === "NEW")) || null;
    const hasNewOrder = (newOrders && newOrders.length > 0) || false;
    dispatch(handleCheckoutModalClearingOfPendingTab(hasNewOrder));
    dispatch(handleCheckoutButtonDisable(!checkIfAllDelivered));
  }, [dispatch, finalData]);

  // Confirm Order Group Manipulation
  useEffect(() => {
    if (
      confirmOrderGroupData &&
      Object.keys(confirmOrderGroupData || {}).length
    ) {
      // Use a functional update to get the latest state of finalData
      setFinalData((prevFinalData) => {
        const convertedConfirmOrderGroupData = handleConfirmOrderGroup(
          prevFinalData,
          confirmOrderGroupData
        );
        dispatch(handleNumberOfUnconfirmedOrderItems(0));
        setCartData(null);
        setCleanCartData(null);
        setFormattedCartData(null);

        console.log(
          "[CONFIRM ORDER FUNC RES] convertedConfirmOrderGroupData",
          convertedConfirmOrderGroupData
        );
        return convertedConfirmOrderGroupData; // Return the new state
      });
    }
  }, [confirmOrderGroupData]);

  // Delivered Order Item Manipulation
  useEffect(() => {
    if (
      deliveredOrderItemData &&
      Object.keys(deliveredOrderItemData || {}).length
    ) {
      // Use a functional update to get the latest state of finalData
      setFinalData((prevFinalData) => {
        const convertedDeliveredItems = handleDeliveredOrderItem(
          prevFinalData,
          deliveredOrderItemData
        );
        return convertedDeliveredItems; // Return the new state
      });
    }
  }, [deliveredOrderItemData]);

  // Delivered Order Group Manipulation
  useEffect(() => {
    if (
      orderGroupStatusData &&
      Object.keys(orderGroupStatusData || {}).length
    ) {
      // Use a functional update to get the latest state of finalData
      setFinalData((prevFinalData) => {
        const convertedDeliveredOrderGroup = handleOrderGroupStatus(
          prevFinalData,
          orderGroupStatusData
        );
        return convertedDeliveredOrderGroup; // Return the new state
      });
    }
  }, [orderGroupStatusData]);

  return (
    <CartContext.Provider value={finalData}>{children}</CartContext.Provider>
  );
};

const useCartChannel = (): IObject[] | null => {
  const context = useContext(CartContext);
  return context;
};

export default CartChannelProvider;
export { useCartChannel };
