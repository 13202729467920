import useClearPendingOrderItems from 'hooks/useClearPendingOrderItems';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/customHooks';
import { handleClosingOrderTimeDisable } from 'store/tableStatusAndOrder/tableStatusAndOrderSlice';

interface OrderTimingsContextValue {
    remainingTime: number | null;
    isVisible: boolean;
}

const OrderTimingsContext = createContext<OrderTimingsContextValue | undefined>(undefined);

const OrderTimingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const [remainingTime, setRemainingTime] = useState<number | null>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [shouldClearItems, setShouldClearItems] = useState(false);

    useClearPendingOrderItems(shouldClearItems);

    useEffect(() => {
        const updateRemainingTime = () => {
            try {
                const now = new Date();
                const closingTimeStr = sessionStorage.getItem('closingTime');
                const openingTimeStr = sessionStorage.getItem('openingTime');

                // console.log('--- [LastCall] Timing Debug ---');
                // console.log('[LastCall] Current Time:', now.toLocaleString());
                // console.log('[LastCall] Closing Time (stored):', closingTimeStr);
                // console.log('[LastCall] Opening Time (stored):', openingTimeStr);

                if (!closingTimeStr || !openingTimeStr) {
                    console.error('[LastCall] Missing closing or opening time in session storage');
                    return;
                }

                // Parse closing time
                const closingTimeParts = closingTimeStr.replace(/"/g, '').split(':');
                let [closingHours, closingMinutes, closingSeconds] = closingTimeParts.map(val => {
                    const parsed = parseInt(val.trim(), 10);
                    return isNaN(parsed) ? null : parsed;
                });

                // Parse opening time
                const openingTimeParts = openingTimeStr.replace(/"/g, '').split(':');
                let [openingHours, openingMinutes, openingSeconds] = openingTimeParts.map(val => {
                    const parsed = parseInt(val.trim(), 10);
                    return isNaN(parsed) ? null : parsed;
                });

                // Create reference dates
                const closingTime = new Date(now);
                closingTime.setHours(closingHours!, closingMinutes!, closingSeconds ?? 0, 0);

                const openingTime = new Date(now);
                openingTime.setHours(openingHours!, openingMinutes!, openingSeconds ?? 0, 0);

                // Adjust dates to handle times crossing midnight
                if (closingTime > openingTime) {
                    // Closing time is on the next day
                    if (now.getTime() < openingTime.getTime()) {
                        closingTime.setDate(closingTime.getDate() - 1);
                    } else {
                        openingTime.setDate(openingTime.getDate() + 1);
                    }
                }

                // console.log('[LastCall] Adjusted Closing Time:', closingTime.toLocaleString());
                // console.log('[LastCall] Adjusted Opening Time:', openingTime.toLocaleString());

                // Determine if current time is between closing and opening
                const isAfterClosingBeforeOpening =
                    now.getTime() > closingTime.getTime() &&
                    now.getTime() < openingTime.getTime();

                // console.log('[LastCall] Is between closing and opening:', isAfterClosingBeforeOpening);

                if (isAfterClosingBeforeOpening) {
                    setShouldClearItems(true);
                    setIsVisible(true);
                    setRemainingTime(0);
                    dispatch(handleClosingOrderTimeDisable(true));
                } else {
                    // Check if within 10 minutes of closing
                    dispatch(handleClosingOrderTimeDisable(false));
                    const timeDiffToClosing = Math.floor((closingTime.getTime() - now.getTime()) / 1000);
                    const isWithin10MinutesOfClosing = timeDiffToClosing <= 600 && timeDiffToClosing >= 0;

                    // console.log('[LastCall] Time to Closing (seconds):', timeDiffToClosing);
                    // console.log('[LastCall] Within 10 mins of closing:', isWithin10MinutesOfClosing);

                    if (isWithin10MinutesOfClosing) {
                        setIsVisible(true);
                        setRemainingTime(timeDiffToClosing);
                    } else {
                        setIsVisible(false);
                        setRemainingTime(null);
                    }
                }
            } catch (error) {
                console.error('[LastCall] Error in updateRemainingTime:', error);
            }
        };

        updateRemainingTime();
        const intervalId = setInterval(updateRemainingTime, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <OrderTimingsContext.Provider value={{ remainingTime, isVisible }}>
            {children}
        </OrderTimingsContext.Provider>
    );
};

const useOrderTimings = () => {
    const context = useContext(OrderTimingsContext);
    if (context === undefined) {
        return {
            remainingTime: null,
            isVisible: false
        }
    }
    return context;
};

export default OrderTimingsProvider;
export { useOrderTimings };