export const convertToCamelCase = (sentence: string): string => {
  // Split the sentence into words by hyphens, underscores, or any non-alphanumeric character
  const words: string[] = sentence.trim().split(/[\s-_]+/);

  // Return an empty string if there are no words
  if (words.length === 0) return "";
  // Convert each word to camelCase and join into a single string
  const camelCasedString: string = words
    .map((word, index) => {
      // Convert the first word to lowercase
      if (index === 0) {
        return word.toLowerCase();
      } else {
        // Capitalize the first letter of each subsequent word and concatenate with the rest
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join("");
  return camelCasedString;
};

export const convertStringToHyphenated = (inputStr: string): string => {
  if (typeof inputStr !== "string") {
    console.error("Invalid input: expected a string");
    return "";
  }

  if (inputStr.length) {
    // Convert the string to lowercase
    let result = inputStr.toLowerCase().trim();

    // Replace spaces with hyphens
    result = result.replace(/\s+/g, "-");

    // Remove special characters
    result = result.replace(/[^a-z0-9-]/g, "");

    return result;
  }

  return inputStr;
};

export const convertToCapitalizedString = (str: string): string => {
  if (typeof str !== "string") {
    console.error("Invalid input: expected a string");
    return "";
  }

  if (str.length) {
    // Convert the string to lowercase first and split by spaces while keeping parentheses
    const words = str
      .toLowerCase()
      .split(/ (?=[^()]*\()| (?=[^()]*$)|\s+/) // Split by spaces but keep parentheses
      .filter(Boolean); // Remove any empty strings from the result

    // Capitalize the first letter of each word and join back into a string
    const capitalized = words
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");

    return capitalized;
  }
  return str;
};

export const taxChargesFormat = (name: string) => {
  // Split the name by spaces or hyphens to separate each part
  const parts = name.split(" - ");

  // Capitalize the first part entirely and capitalize only the first letter of other parts
  const formattedParts = parts.map((part, index) => {
    if (index === 0) {
      return part.toUpperCase();
    }
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });

  // Join the parts back with " - " in between
  return formattedParts.join(" - ");
}
