import { usePusher } from "context/pusher";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { IObject, PusherConnectionState } from "types";

interface Props {
  children: ReactNode;
}

const TableChangeContext = createContext<IObject | null>(null);

const TableChangeChannelProvider: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  const ids = sessionStorage.getItem("Ids");
  const parsedIds: {
    brandId: string;
    branchId: string;
    tableId: string;
  } | null = ids ? JSON.parse(ids) : null;

  const pusher = usePusher();
  const [changeData, setChangeData] = useState<IObject | null>(null);

  useEffect(() => {
    if (changeData && Object.keys(changeData)?.length > 0) {
      const updatedIds = { ...parsedIds, tableId: changeData?.tableId };
      sessionStorage.setItem("Ids", JSON.stringify(updatedIds));
      window.location.reload();
    }
  }, [changeData]);

  useEffect(() => {
    if (pusher && parsedIds) {
      const channelName = `tenant-${parsedIds.brandId}-branch-${parsedIds.branchId}-table-${parsedIds.tableId}-customerPusherChannel`;
      const channel = pusher.subscribe(channelName);

      console.log(
        "[table change] pusher - customerPusherEvent is the event - channelName :>> ",
        channelName
      );

      // Check if Pusher is connected
      const logConnectionState = () => {
        console.log(
          `[table change] Pusher connection state: ${pusher.connection.state}`
        );
      };

      // Log initial connection state
      logConnectionState();

      // Bind connection state change events to log with proper typing
      pusher.connection.bind(
        "state_change",
        ({
          previous,
          current,
        }: {
          previous: PusherConnectionState;
          current: PusherConnectionState;
        }) => {
          console.log(
            `[table change] Pusher connection state changed from ${previous} to ${current}`
          );
        }
      );

      // Bind to the 'connected' event for successful connection
      pusher.connection.bind("connected", () => {
        console.log("[table change] Pusher connected successfully");
      });

      // Bind to the 'disconnected' event for connection lost
      pusher.connection.bind("disconnected", () => {
        console.log("[table change] Pusher disconnected");
      });

      channel.bind("customerRefresh", (data: any) => {
        try {
          const parsedChangeData = JSON.parse(data);
          console.log(
            `${new Date()} - [table change] parsedChangeData pusher data :>> `,
            data,
            parsedChangeData
          );
          if (parsedChangeData && Object.keys(parsedChangeData)?.length > 0) {
            setChangeData(parsedChangeData);
          }
        } catch (error) {
          console.error(
            "[table change] Error parsing cart data:",
            error,
            "Raw data:",
            data
          );
        }
      });

      channel.bind("pusher:subscription_error", (status: any) => {
        console.error(
          `[table change] Failed to subscribe to channel ${channelName}:`,
          status
        );
      });

      return () => {
        // console.log(`[table change] Unsubscribing from Pusher channel: ${channelName}`);
        // channel.unbind_all();
        // pusher.unsubscribe(channelName);
      };
    }
  }, [pusher, parsedIds]);

  useEffect(() => {
    setTimeout(() => {
      setChangeData(null);
    }, 2000);
  }, [location]);

  return (
    <TableChangeContext.Provider value={changeData}>
      {children}
    </TableChangeContext.Provider>
  );
};

const useTableChangePusherChannel = (): IObject | null => {
  const context = useContext(TableChangeContext);
  // if (context === null) {
  //     throw new Error('useTableChangePusherChannel must be used within a TableChangeChannelProvider');
  // }
  return context;
};

export default TableChangeChannelProvider;
export { useTableChangePusherChannel };
