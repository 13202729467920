import { ReactComponent as CollapseAllIcon } from "assets/images/collapse-all-accordion.svg";
import CustomToggle from "components/CustomToggle";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  handleFoodTypeFilters,
  handleFoodTypeFiltersReset,
  handleResetTabAccordionState,
  handleTabCollapseState,
  handleTabLevelActiveFilter,
} from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";

const ToggleItemSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const tabLabel = useAppSelector(
    (state) => state.tab.selectedTabDetails.homePageHeaderTabs.label
  );
  const tabFilters = useAppSelector(
    (state) => state.globalSearchAndFilters.tabLevelActiveFilter
  );
  const { tabAccordionCollapseState } = useAppSelector(
    (state) => state.globalSearchAndFilters
  );
  const { isDrinkTabCollapsed, isEatTabCollapsed } = tabAccordionCollapseState;

  const isEatTab = tabLabel?.toLowerCase() === "eat";
  const isDrinkTab = tabLabel?.toLowerCase() === "drink";
  const isSmokeTab = tabLabel?.toLowerCase() === "smoke";

  const initialToggleCondition = isEatTab
    ? !isEatTabCollapsed
    : isDrinkTab
    ? !isDrinkTabCollapsed
    : false;
  const [activeCollapseButton, setActiveCollapseButton] = useState<boolean>(
    initialToggleCondition
  );

  useEffect(() => {
    setActiveCollapseButton(
      isEatTab ? !isEatTabCollapsed : isDrinkTab ? !isDrinkTabCollapsed : false
    );
  }, [isDrinkTab, isDrinkTabCollapsed, isEatTab, isEatTabCollapsed]);

  // Function to handle toggle and dispatch filter actions
  const handleToggle = (
    filterName:
      | "VEGETARIAN"
      | "NON_VEGETARIAN"
      | "EGGETARIAN"
      | "ALCOHOLIC"
      | "NON_ALCOHOLIC"
  ) => {
    const isEatFilter = ["VEGETARIAN", "NON_VEGETARIAN", "EGGETARIAN"].includes(
      filterName
    );
    const currentTabFilter = isEatFilter
      ? tabFilters.activeEatFilter
      : tabFilters.activeDrinkFilter;

    if (currentTabFilter === filterName) {
      dispatch(handleFoodTypeFiltersReset());
      dispatch(
        handleTabLevelActiveFilter({
          ...tabFilters,
          [isEatFilter ? "activeEatFilter" : "activeDrinkFilter"]: null,
        })
      );
    } else {
      dispatch(handleFoodTypeFilters(filterName, true));
      dispatch(
        handleTabLevelActiveFilter({
          ...tabFilters,
          [isEatFilter ? "activeEatFilter" : "activeDrinkFilter"]:
            filterName as any,
        })
      );
    }
    isEatFilter
      ? dispatch(handleResetTabAccordionState("EAT"))
      : dispatch(handleResetTabAccordionState("DRINK"));
  };

  // Effect to sync filters when tab changes
  useEffect(() => {
    if (isEatTab) {
      // Apply eat tab filter if exists
      if (tabFilters.activeEatFilter) {
        dispatch(handleFoodTypeFilters(tabFilters.activeEatFilter, true));
      } else {
        dispatch(handleFoodTypeFiltersReset());
      }
    } else if (isDrinkTab) {
      // Apply drink tab filter if exists
      if (tabFilters.activeDrinkFilter) {
        dispatch(handleFoodTypeFilters(tabFilters.activeDrinkFilter, true));
      } else {
        dispatch(handleFoodTypeFiltersReset());
      }
    } else if (isSmokeTab) {
      // Reset all filters when switching to the "smoke" tab
      dispatch(handleFoodTypeFiltersReset());
      // dispatch(handleResetTabAccordionState("SMOKE"));
    }
  }, [isEatTab, isDrinkTab, isSmokeTab, dispatch, tabFilters]);

  const renderEatFilters = () => (
    <>
      <div className="flex flex-row items-center gap-2">
        <CustomToggle
          active={tabFilters.activeEatFilter === "VEGETARIAN"}
          onToggle={() => handleToggle("VEGETARIAN")}
          target="greenFilter"
        />
        <span
          className={`${
            tabFilters.activeEatFilter === "VEGETARIAN"
              ? "activeVeg font-[800]"
              : "font-[500]"
          }`}
        >
          VEG
        </span>
      </div>
      <div className="flex flex-row items-center gap-2">
        <CustomToggle
          active={tabFilters.activeEatFilter === "NON_VEGETARIAN"}
          onToggle={() => handleToggle("NON_VEGETARIAN")}
          target="redFilter"
        />
        <span
          className={` ${
            tabFilters.activeEatFilter === "NON_VEGETARIAN"
              ? "activeNonVeg font-[800]"
              : "font-[500]"
          }`}
        >
          NONVEG
        </span>
      </div>
      <div className="flex flex-row items-center gap-2">
        <CustomToggle
          active={tabFilters.activeEatFilter === "EGGETARIAN"}
          onToggle={() => handleToggle("EGGETARIAN")}
          target="yellowFilter"
        />
        <span
          className={`${
            tabFilters.activeEatFilter === "EGGETARIAN"
              ? "activeEgg font-[800]"
              : "font-[500]"
          }`}
        >
          EGG
        </span>
      </div>
    </>
  );

  const renderDrinkFilters = () => (
    <>
      <div className="flex flex-row items-center gap-2">
        <CustomToggle
          active={tabFilters.activeDrinkFilter === "ALCOHOLIC"}
          onToggle={() => handleToggle("ALCOHOLIC")}
          target="drinksFilter"
        />
        <span
          className={` ${
            tabFilters.activeDrinkFilter === "ALCOHOLIC"
              ? "font-[800] drinksFilter"
              : "font-[500]"
          }`}
        >
          ALCOHOLIC
        </span>
      </div>
      <div className="flex flex-row items-center gap-2">
        <CustomToggle
          active={tabFilters.activeDrinkFilter === "NON_ALCOHOLIC"}
          onToggle={() => handleToggle("NON_ALCOHOLIC")}
          target="drinksFilter"
        />
        <span
          className={` ${
            tabFilters.activeDrinkFilter === "NON_ALCOHOLIC"
              ? "font-[800] drinksFilter"
              : "font-[500] "
          }`}
        >
          NON-ALCOHOLIC
        </span>
      </div>
    </>
  );

  // Only render the component for eat and drink tabs
  if (!isEatTab && !isDrinkTab) return null;

  return (
    <div className="toggleSectionContainer mt-[20px] flex py-4 items-center justify-around rounded-lg">
      {isEatTab ? renderEatFilters() : isDrinkTab ? renderDrinkFilters() : null}
      <div className="toggleSectionItemSeparator"></div>
      <div
        onClick={() => {
          dispatch(
            handleTabCollapseState({
              collapseState: true,
              tabType: isEatTab ? "EAT" : "DRINK",
            })
          );
        }}
        className="pr-2"
      >
        <CollapseAllIcon
          height={19}
          width={11}
          className={
            activeCollapseButton
              ? "activeCollapseIconColor"
              : "inactiveCollapseIconColor"
          }
        />
      </div>
    </div>
  );
};

export default ToggleItemSection;
