//  Returns the appropriate image based on the food type.

import nonVegImage from "assets/images/non-veg-food-type.svg";
import vegImage from "assets/images/veg-food-type.svg";
import eggImage from "assets/images/egg-food-type.svg";

export const handleFoodTypeImage = (foodType: string) => {
  switch (foodType) {
    case "NON_VEGETARIAN":
      return nonVegImage;
    case "VEGETARIAN":
      return vegImage;
    case "EGGETARIAN":
      return eggImage;
    default:
      return null;
  }
};
