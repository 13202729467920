import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IObject } from "types";

interface TabState {
  selectedTabDetails: IObject;
  showCallWaiterModal: boolean;
  counterLoader: Record<string | number, boolean>;
  changeDiffTabDetails: IObject;
}

const initialState: TabState = {
  selectedTabDetails: {},
  showCallWaiterModal: false,
  counterLoader: {},
  changeDiffTabDetails: {},
};

const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    handleSelectedTabDetails: (state, action: PayloadAction<IObject>) => {
      state.selectedTabDetails = {
        ...state.selectedTabDetails, // Spread the existing data
        ...action.payload, // Append new data from the payload
      };
    },
    handleShowCallWaiterModal: (state, action) => {
      state.showCallWaiterModal = action.payload;
    },
    handleCounterLoader: (
      state,
      action: PayloadAction<{ itemId: string | number; isLoading: boolean }>
    ) => {
      const { itemId, isLoading } = action.payload;
      state.counterLoader[itemId] = isLoading;
    },
    handleTabChange: (state, action: PayloadAction<IObject>) => {
      state.changeDiffTabDetails = {
        ...action.payload,
      };
    },
  },
});

export const {
  handleSelectedTabDetails,
  handleShowCallWaiterModal,
  handleCounterLoader,
  handleTabChange,
} = tabSlice.actions;

export default tabSlice.reducer;
