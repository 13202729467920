import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/customHooks";
import { useUpdateOrderItemQuantityByProductIdMutation } from "store/orders/ordersAPISlice";

const useClearPendingOrderItems = (
  shouldRun: boolean = false,
  onComplete?: () => void
) => {
  const orderGroupsData = useAppSelector(
    (state) => state.tableStatusAndOrder.orderDetails?.orderGroups
  );

  const [updateQuantity] = useUpdateOrderItemQuantityByProductIdMutation();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const clearPendingItems = async () => {
      if (shouldRun && orderGroupsData && orderGroupsData.length > 0) {
        const filteredOrders =
          orderGroupsData
            ?.filter((order: any) => order?.status !== "CANCELLED")
            ?.map((order: any) => ({
              ...order,
              orderItems: order?.orderItems?.filter(
                (item: any) =>
                  item?.status !== "CANCELLED" && item?.quantity > 0
              ),
            }))
            ?.filter((order: any) => order.status === "NEW") || null;

        if (filteredOrders) {
          let allSuccess = true; // Track the success of all API calls

          for (const order of filteredOrders) {
            for (const item of order.orderItems) {
              try {
                await updateQuantity({
                  orderItemId: item.id,
                  price: 0,
                  quantity: 0,
                });
                console.log(
                  `Cleared item ${item.id} from order group ${order.id}`
                );
              } catch (error: any) {
                const res = error;
                const errMessage =
                  res?.error?.data?.message ??
                  res?.error?.error ??
                  res?.error?.data?.error ??
                  "Something went wrong while updating the quantity. Please try again!";

                if (
                  errMessage ===
                  "Order is not in NEW status. Cannot add or update items."
                ) {
                  navigate("/payment-in-progress-block");
                } else {
                  if (
                    [
                      "Order Item already confirmed",
                      "Order group is currently in progress and cannot be modified.",
                    ].includes(errMessage.trim())
                  ) {
                    window.location.reload();
                  }

                  if (isMounted) {
                    console.error(errMessage);
                  }
                  allSuccess = false; // Mark as failed if any API call fails
                }
              }
            }
          }

          // If all API calls were successful, call the onComplete callback
          if (allSuccess && onComplete) {
            onComplete?.();
          }
        }
      }
    };

    if (shouldRun) {
      clearPendingItems();
    }

    return () => {
      isMounted = false;
    };
  }, [shouldRun]);
};

export default useClearPendingOrderItems;
